import React from "react";
import bg from '../image/About-us.webp'
import { Link } from 'react-router-dom';

const AboutUs = () => {
    return (
        <>
            <div className="bg-size-mobile" style={{background: 'url('+bg+') center top no-repeat', backgroundSize: '100%', height: '460px', paddingTop: '20px'}}>
                <h3 style={{color: 'white', fontSize: '40px', textDecoration: 'underline', fontWeight: '300', textAlign: 'center'}}>About Us</h3>
                <div className="container">
                    <p style={{color: 'white', marginTop: '10px', textAlign: 'center', marginBottom: '5px'}}>Divine Bricks Real Estate is Crafting Dreams into Reality. Our mission is to create exceptional living spaces that inspire and elevate the quality of life.</p>
                    <div style={{textAlign: 'center'}}>
                        <Link to='/about-us'><button style={{background: '#FFD88D', border: 'none', borderRadius: '25px', padding: '5px 10px', fontWeight: '500', fontSize: '12px'}}>READ MORE</button></Link>
                    </div>
                    <div className="row mt-5 only-mobile-top-margin">
                        <div className="col-md-6" style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <p style={{boxShadow: '2px 6px 0px -2px rgba(0, 0, 0, 0.25)', background: '#BE9951', color: 'white', borderRadius: '25px', maxWidth: '250px', padding: '10px', textAlign: 'center', width: '200px', marginBottom: '80px', marginLeft: '20px'}}>Trusted Company</p>
                                <p style={{boxShadow: '2px 6px 0px -2px rgba(0, 0, 0, 0.25)', background: '#BE9951', color: 'white', borderRadius: '25px', maxWidth: '250px', padding: '10px', textAlign: 'center', width: '200px'}}>24/7 Support</p>
                            </div>
                        </div>
                        <div className="col-md-6" style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <p style={{boxShadow: '2px 6px 0px -2px rgba(0, 0, 0, 0.25)', background: '#BE9951', color: 'white', borderRadius: '25px', maxWidth: '250px', padding: '10px', textAlign: 'center', width: '200px', marginBottom: '80px'}}>Expert Team</p>
                                <p style={{boxShadow: '2px 6px 0px -2px rgba(0, 0, 0, 0.25)', background: '#BE9951', color: 'white', borderRadius: '25px', maxWidth: '250px', padding: '10px', textAlign: 'center', width: '200px', marginLeft: '20px'}}>Home Finding Made Easy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs

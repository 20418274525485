import React, { useState, useEffect } from "react";
import ReactStars from 'react-stars'
import UserHeader from "./UserHeader";
import DataTable from "react-data-table-component";
import moment from "moment";
import axios from '../axiosInstance'

const columns = [
    {
        name: "User ID",
        selector: (row) => row.id,
    },
    {
        name: "Quarter",
        selector: (row) => row.quarter,
    },
    {
        name: "Amount",
        selector: (row) => "$" + row.Amount,
    },
    {
        name: "Date",
        selector: (row) => moment(row.createdon).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
        name: "Remarks",
        selector: (row) => (row.remarks == null ? null : row.remarks),
    },
];

const RewardIncome = () => {
    const [data, setData] = useState()

    useEffect(() => {
        const token = sessionStorage.getItem("token")
        axios
            .post(
                `api/users/reports`,
                {
                    "type": "2"
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result?.rows)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])
    return (
        <>
            <UserHeader />
            <div className="container">
                <div className="row mt-5 mb-2" style={{ justifyContent: 'space-evenly' }}>
                    <div className="col-md-3 stars-div" style={{ textAlign: 'center', background: 'green', color: 'white' }}>
                        <div>
                            <h3>Reward 1</h3>
                            <ReactStars
                                className='stars'
                                count={1}
                                size={24}
                                color1={'#ffd700'} />
                            <h5 className="mt-3">Achieved</h5>
                        </div>
                    </div>
                    <div className="col-md-3 stars-div" style={{ textAlign: 'center', background: 'red', color: 'white' }}>
                        <div>
                            <h3>Reward 2</h3>
                            <ReactStars
                                className='stars'
                                count={2}
                                size={24}
                                color1={'#ffd700'} />
                            <h5 className="mt-3">Achieved</h5>
                        </div>
                    </div>
                    <div className="col-md-3 stars-div" style={{ textAlign: 'center', background: 'blue', color: 'white' }}>
                        <div>
                            <h3>Reward 3</h3>
                            <ReactStars
                                className='stars'
                                count={3}
                                size={24}
                                color1={'#ffd700'} />
                            <h5 className="mt-3">Pending</h5>
                        </div>
                    </div>
                    <div className="col-md-3 stars-div" style={{ textAlign: 'center', background: 'purple', color: 'white' }}>
                        <div>
                            <h3>Reward 4</h3>
                            <ReactStars
                                className='stars'
                                count={4}
                                size={24}
                                color1={'#ffd700'} />
                            <h5 className="mt-3">Pending</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title" style={{ textAlign: 'left' }}>Reward Income Report</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive export-button-table">
                                    <DataTable
                                        pagination={true}
                                        columns={columns}
                                        data={data}
                                        paginationServer={true}
                                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RewardIncome
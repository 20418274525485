import React, { useState, useEffect } from "react";
import UserHeader from "./UserHeader";
import { Circle } from "rc-progress";
import axios from "../axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import twitter from "../image/twitter.png";
import mail from "../image/mail.jpg";
import whatsapp from "../image/whatsapp.png";
import facebook from "../image/facebook.png";
import $ from "jquery";
import PropertiesList from "./PropertiesList";

// import user_img from ''
const columns = [
  {
    name: "Property Name",
    selector: (row) => row.propertyData.name,
  },
  {
    name: "Amount",
    selector: (row) => "$" + row.amount,
  },
  {
    name: "EMI Date",
    selector: (row) => row.emi_date,
  },
  {
    name: "Status",
    selector: (row) => (
      <div
        className={
          row.is_status == "Pending"
            ? "badge badge-warning p-2"
            : row.is_status == "Ontime"
            ? "badge badge-success p-2"
            : "badge badge-danger p-2"
        }
      >
        {row.is_status}
      </div>
    ),
    width: "auto",
  },
];

const columns1 = [
  {
    name: "User ID",
    selector: (row) => row.id,
    width: "70px",
  },
  {
    name: "Amount",
    selector: (row) => "$" + row.Amount,
  },
  {
    name: "Date",
    selector: (row) => moment(row.createdon).format("DD-MM-YYYY  HH:mm:ss"),
  },
  {
    name: "Remarks",
    selector: (row) => (row.remarks == null ? null : row.remarks),
    width: "auto",
  },
];
const UserDashboard = () => {
  function copyReferral(e) {
    e.preventDefault();
    // Get the text field
    var copyText = document.getElementById("myInput");
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }
  const [data, setData] = useState();
  const [propertyList, setPropertyList] = useState();
  const [emi, setEmi] = useState();
  const [directIncome, setDirectIncome] = useState();
  const [hideMe, setHideMe] = useState("none");
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token == null || token == "undefined") {
      navigate("/");
    }
    axios
      .get(`api/users/get-user-details`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `api/users/get-emi-list?page=1&size=5`,
        {
          propertyId: null,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setEmi(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        `api/users/reports`,
        {
          type: "1",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setDirectIncome(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-buy-property-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setPropertyList(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const hideCard = () => {
    if (hideMe == "none") {
      setHideMe("block");
    } else {
      setHideMe("none");
    }
  };
  const percentage = 64;
  return (
    <>
      <UserHeader />

      <div className="container pb-5">
        <div class="row mb-4 mt-4">
          <div class="col-12">
            <div class="card tt-page-header" style={{ borderRadius: "0" }}>
              <div
                class="card-body d-lg-flex align-items-center justify-content-lg-between"
                style={{ background: "white" }}
              >
                <div class="tt-page-title">
                  <h2 class="h5 mb-lg-0">Dashboard</h2>
                </div>
                <div class="tt-action">
                  <Link to="/profile" class="btn btn-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="feather feather-shopping-cart me-2"
                    >
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    Manage Profile
                  </Link>

                  <Link to="/properties" class="btn btn-primary mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="feather feather-plus me-2"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    View Properties
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3" style={{ textAlign: "left" }}>
                <div className="card">
                  <div className="card-body">
                    <p
                      style={{
                        color: "#919eab",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      My Properties
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "28px",
                      }}
                    >
                      {propertyList?.length}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3" style={{ textAlign: "left" }}>
                <div className="card">
                  <div className="card-body">
                    <p
                      style={{
                        color: "#919eab",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      Next EMI
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "28px",
                      }}
                    >
                      01 Jan
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3" style={{ textAlign: "left" }}>
                <div className="card">
                  <div className="card-body">
                    <p
                      style={{
                        color: "#919eab",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      Direct Income
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "28px",
                      }}
                    >
                      $10
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3" style={{ textAlign: "left" }}>
                <div className="card">
                  <div className="card-body">
                    <p
                      style={{
                        color: "#919eab",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      Reward Income
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "28px",
                      }}
                    >
                      $20
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title" style={{ textAlign: "left" }}>
                      Latest EMI Report
                    </h4>
                  </div>
                  <div className="card-body" style={{ overflowY: "auto" }}>
                    <div className="table-responsive export-button-table">
                      <DataTable
                        pagination={false}
                        columns={columns}
                        data={emi}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div
                className="card-body"
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  height: "auto",
                }}
              >
                <p
                  style={{
                    color: "#919eab",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "20px",
                  }}
                >
                  My Properties
                </p>
                {propertyList?.length > 0
                  ? propertyList?.map((data) => {
                      return (
                        <div
                          className="d-flex mb-3 align-items-center"
                          style={{
                            border: "2px solid #315745",
                            padding: "10px",
                            borderRadius: "4px",
                          }}
                        >
                          <Link
                            to="/my-properties"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <img
                              src={"https://api.divinebricks.com" + data?.image}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "5px",
                              }}
                            />
                            <p
                              style={{
                                paddingLeft: "10px",
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "black",
                              }}
                            >
                              {data?.name}
                            </p>
                          </Link>
                        </div>
                      );
                    })
                  : "No Properties"}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
                    <div className="col-md-3  d-flex align-items-stretch">
                        <Link to='emi-transactions'>
                            <div className="card first-cards p-4" style={{ border: '1px solid rgb(227 227 227)', justifyContent: 'center' }}>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p style={{ textAlign: 'left', color: 'black' }} className="mb-1"><strong>Amount to be paid this Month</strong></p>
                                        <h4 style={{ textAlign: 'left', color: 'black' }}>$0</h4>
                                        <h6 style={{ textAlign: 'left' }}><span style={{ color: 'green' }}>8%</span> <span style={{ fontWeight: '300', color: 'black' }}>this month</span></h6>
                                        <p style={{ textAlign: 'left' }}><span style={{ color: 'green' }}>Next Payment On 2nd Jan 2024</span></p>
                                    </div>
                                    <div style={{ height: 100, width: 140 }}>
                                        <Circle percent={70} strokeWidth={4} strokeColor="green" />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3 d-flex align-items-stretch">
                        <div className="card second-cards p-4" style={{ border: '1px solid rgb(227 227 227)', justifyContent: 'center' }}>
                            <Link to='/emi-transactions'>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p style={{ textAlign: 'left', color: 'black' }} className="mb-1"><strong>Amount paid till now</strong></p>
                                        <h4 style={{ textAlign: 'left', color: 'black' }}>$0</h4>
                                        <h6 style={{ textAlign: 'left' }}><span style={{ color: 'red' }}>0/8 Paid</span></h6>
                                    </div>
                                    <div style={{ height: 100, width: 140 }}>
                                        <Circle percent={10} strokeWidth={4} strokeColor="red" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-stretch">
                        <div className="card third-cards p-4" style={{ border: '1px solid rgb(227 227 227)', justifyContent: 'center' }}>
                            <Link to='/my-properties'>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p style={{ textAlign: 'left', color: 'black' }} className="mb-1"><strong>My Properties</strong></p>
                                        <h4 style={{ textAlign: 'left', color: 'black' }}>0</h4>
                                        <h6 style={{ textAlign: 'left', lineHeight: '25px' }}><span style={{ color: 'blue' }}>Amount Spent: </span> <span style={{ fontWeight: '300', marginTop: '10px', color: 'black' }}><strong>$0</strong></span></h6>
                                    </div>
                                    <div style={{ height: 100, width: 140 }}>
                                        <Circle percent={50} strokeWidth={4} strokeColor="blue" />
                                    </div>
                                </div>

                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-stretch">
                        <div className="card fourth-cards p-4" style={{ border: '1px solid rgb(227 227 227)', justifyContent: 'center' }}>
                            <Link to='/direct-income'>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p style={{ textAlign: 'left', color: 'black' }} className="mb-1"><strong>Direct Income</strong></p>
                                        <p style={{ textAlign: 'left', color: 'black' }} className="mb-1"><strong>My Referrals:</strong></p>
                                        <h4 style={{ textAlign: 'left', color: 'black' }}>0</h4>
                                        <h6 style={{ textAlign: 'left', lineHeight: '25px' }}><span style={{ color: 'purple' }}>Income Recieved: </span> <span style={{ fontWeight: '300', marginTop: '10px', color: 'black' }}><strong>$0</strong></span></h6>
                                    </div>
                                    <div style={{ height: 100, width: 140 }}>
                                        <Circle percent={50} strokeWidth={4} strokeColor="purple" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div> */}

        <div className="row mt-5">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ textAlign: "left" }}>
                  Direct Income Report
                </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive export-button-table">
                  <DataTable pagination={false} columns={columns} data={data} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ textAlign: "left" }}>
                  Reward Income Report
                </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive export-button-table">
                  <DataTable
                    pagination={true}
                    columns={columns1}
                    data={directIncome}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row mt-4">
                    <div className="card notification-tab">
                        <div className="card-header" style={{ display: 'flex' }}>
                            Notifications
                            <button className="btn btn-danger" style={{ padding: '5px 10px', height: '28px', display: 'flex', alignItems: 'center', marginLeft: 'auto' }} onClick={hideCard}>-</button>
                        </div>
                        <div className="card-body" style={{ display: hideMe }}>
                            <p>Hi sajdjsad hasuhd saudgsaud sads dgsadshdsad skhdsahd sakjdsahd skjd </p>
                            <p>Hi</p>
                            <p>Hi</p>
                            <p>Hi</p>
                            <p>Hi</p>
                            <p>Hi</p>
                            <p>Hi</p>
                        </div>
                    </div>
                </div> */}

        <div className="row mt-5">
          <div className="col-md-12">
            <div
              className="card"
              style={{ padding: "1.25rem", textAlign: "left", paddingTop: "0" }}
            >
              <h6 style={{ textAlign: "left", marginTop: "20px" }}>
                My Referral Link
              </h6>
              <div className="d-flex">
                <input
                  className="form-control"
                  id="myInput"
                  style={{ border: "none", maxWidth: "400px" }}
                  type="text"
                  value={
                    "https://divinebricks.ae/register?referralcode=" +
                    data?.referralCode
                  }
                  readOnly
                />
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={copyReferral}
                >
                  Copy
                </button>
                <div className="mx-4">
                  <a
                    href={
                      "https://wa.me?text=https://divinebricks.ae/register?referralcode=" +
                      data?.referralCode
                    }
                    target="_blank"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  >
                    <img
                      src={whatsapp}
                      style={{ width: "40px", cursor: "pointer" }}
                    />
                  </a>
                  <a
                    href={
                      "http://twitter.com/share?text=https://divinebricks.ae/register?referralcode=" +
                      data?.referralCode
                    }
                    target="_blank"
                    style={{ marginRight: "10px" }}
                  >
                    <img
                      src={twitter}
                      style={{
                        width: "40px",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                    />
                  </a>
                  <a
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=https://divinebricks.ae/register?referralcode=" +
                      data?.referralCode
                    }
                    target="_blank"
                    style={{ marginRight: "10px" }}
                  >
                    <img
                      src={facebook}
                      style={{
                        width: "40px",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                    />
                  </a>
                  <a
                    href={
                      "mailto:?subject=Join us on Saffron Meta&body=https://divinebricks.ae/register?referralcode=" +
                      data?.referralCode
                    }
                    title="Saffron Meta"
                  >
                    <img
                      src={mail}
                      style={{ width: "40px", cursor: "pointer" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDashboard;

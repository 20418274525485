import React, { useState, useEffect } from "react";
import HomePageHeader from "../HomePageHeader";
import TopHeader from "../TopHeader";
import HomeFooter from "../HomeFooter";
import axios from "../../axiosInstance";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";

const JoinEvent = () => {
  const [captchaSuccess, setCapchaSuccess] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [defaultImg, setdefaultImg] = useState("/images/abc.png");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_no: "",
    image: defaultImg,
    referralName: "",
    location: "",
  });
  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  function onChange(value) {
    if (value) {
      setCapchaSuccess(true);
    }
  }
  const imageUpload4 = async (event) => {
    const formData_1 = new FormData();
    formData_1.append("files", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      axios
        .post(`api/users/upload-a-join-document`, formData_1, {})
        .then((resp) => {
          setFormData({ ...formData, image: resp.data.result?.image_url });
        })
        .catch((err) => {
          console.log("eerr", err);
        });
    } catch (err) {
      console.error("Error uploading image", err);
    }
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    if (captchaSuccess) {
      try {
        setSubmitButton(true);
        axios
          .post(`api/users/join-form-submit`, formData)
          .then((resp) => {
            console.log(resp?.data?.success);
            if (resp?.data?.success) {
              console.log("if", resp?.data?.msg);
              toast.success(resp?.data?.msg);
              setTimeout(() => {
                setFormData({
                  ...formData,
                  name: "",
                  email: "",
                  mobile_no: "",
                  image: defaultImg,
                  referralName: "",
                  location: "",
                });
              }, 100);
            } else {
              console.log("else", resp?.data?.msg);
              toast.error(resp?.data?.msg);
            }
            setSubmitButton(false);
          })
          .catch((err) => {
            console.log("eerr", err);
            setSubmitButton(false);
          });
      } catch (err) {
        toast.error(err.message);
        console.error("Error uploading image", err);
      }
    } else {
      toast.error("Capcha invalid");
    }
  };

  return (
    <>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <section
            className="headings about-page-heading cImg"
            title="divine-bricks-about-us"
            alt="divine-bricks-about-us"
            style={{
              background: "url('/join-dubai-event.jpg') center top no-repeat",
              backgroundSize: "100%",
              height: "220px",
            }}
          ></section>
          <section className="contact-us mt-5">
            <div className="container">
              <div className="property-location mb-5 mt-md-0 mt-3">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h3 className="mb-4">Participant in Event</h3>
                    <form className="contact-form" onSubmit={handelSubmit}>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name">
                              Name<sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              required="required"
                              id="name"
                              className="form-control input-custom input-full"
                              name="name"
                              value={formData.name}
                              onChange={handelChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="email">
                              Email<sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              required="required"
                              id="email"
                              className="form-control input-custom input-full"
                              name="email"
                              value={formData.email}
                              onChange={handelChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="mobile_no">
                              Mobile No.<sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="number"
                              required="required"
                              id="mobile_no"
                              className="form-control input-custom input-full"
                              name="mobile_no"
                              value={formData.mobile_no}
                              onChange={handelChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="image">
                              Image ( Optional)
                            </label>
                            <input
                              type="file"
                              id="image"
                              className="form-control d-flex"
                              name="image"
                              onChange={imageUpload4}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="mobile_no">
                              Referral Name (Optional){" "}
                            </label>
                            <input
                              type="text"
                              id="referralName"
                              className="form-control input-custom input-full"
                              name="referralName"
                              value={formData.referralName}
                              onChange={handelChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="mobile_no">
                              Location<sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              required="required"
                              id="location"
                              className="form-control input-custom input-full"
                              name="location"
                              value={formData.location}
                              onChange={handelChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <ReCAPTCHA
                            sitekey="6LeDIP4pAAAAABWt1Bhh0viKYBBfR_aU10yW4K5b"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <button
                        id="submit-contact"
                        className="btn btn-primary btn-lg"
                        style={{ border: "none" }}
                        type="submit"
                        disabled={submitButton}
                      >
                        Join Event
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default JoinEvent;

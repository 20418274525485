import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Register from "./Components/Register";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Components/Profile";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Properties from "./Components/Properties";
import PropertyDetails from "./Components/PropertyDetails";
import UserHeader from "./Components/UserHeader";
import UserDashboard from "./Components/UserDashboard";
import DirectIncome from "./Components/DirectIncome";
import EmiTransactions from "./Components/EmiTransactions";
import MyProperties from "./Components/MyProperties";
import Payment from "./Components/Payment";
import RewardIncome from "./Components/RewardIncome";
import AdminDashboard from "./Components/AdminDashboard";
import UsersList from "./Components/UsersList";
import PropertiesList from "./Components/PropertiesList";
import EmiList from "./Components/EmiList";
import DirectIncomeUsers from "./Components/DirectIncomeUsers";
import PeriodRewardUsers from "./Components/PeriodRewardUsers";
import ApproveDeposit from "./Components/ApproveDeposit";
import React, { useEffect, useState } from "react";
import PaymentData from "./Components/PaymentData";
import TypeManager from "./Components/TypeManager";
import CategoryManager from "./Components/CategoryManager";
import GalleryManager from "./Components/Event/GalleryManager";
import NewsManager from "./Components/News/NewsManager";
import EventManager from "./Components/Event/EventManager";
import AmenitiesManager from "./Components/AmenitiesManager";
import IconsManager from "./Components/IconsManager";
import HomePropertyDetails from "./Components/HomePropertyDetails";
import HomePropertyListing from "./Components/HomePropertyListing";
import ContactUs from "./Components/ContactUs";
import SellerRegister from "./Components/SellerRegister";
import SellerLogin from "./Components/SellerLogin";
import SellerDashboard from "./Components/SellerDashboard";
import SellerListing from "./Components/SellerListing";
import SellerQueries from "./Components/SellerQueries";
import ScrollToTop from "./Components/ScrollToTop";
import AgentsList from "./Components/AgentsList";
import SellerProfile from "./Components/SellerProfile";
import AgentsPropertiesList from "./Components/AgentsPropertiesList";
import AgentAddProperty from "./Components/AgentAddProperty";
import Blogs from "./Components/Blogs";
import AboutUs from "./Components/AboutUs";
import Investments from "./Components/Investments";
import AboutUsPage from "./Components/AboutUsPage";
import EventInvitation from "./Components/Event/EventInvitation";
import JoinEvent from "./Components/Event/JoinEvent";
import EventGallery from "./Components/Event/EventGallery";
import HomePage from "./Components/HomePage";
import AddProperty from "./Components/AddProperty";
import FavouriteProperties from "./Components/FavouriteProperties";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import LatestNews from "./Components/LatestNews";

function App() {
  const [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(sessionStorage.getItem("isAdmin"));
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/user-dashboard" element={<UserDashboard />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/header" element={<Header />} />
            <Route exact path="/footer" element={<Footer />} />
            <Route exact path="/properties" element={<Properties />} />
            <Route
              exact
              path="/properties-details"
              element={<PropertyDetails />}
            />
            <Route
              exact
              path="/divine-bricks-properties/:name?"
              element={<HomePropertyDetails />}
            />
            <Route
              exact
              path="/divine-bricks-property-listing"
              element={<HomePropertyListing />}
            />
            <Route exact path="/user-header" element={<UserHeader />} />
            <Route exact path="/direct-income" element={<DirectIncome />} />
            <Route
              exact
              path="/emi-transactions"
              element={<EmiTransactions />}
            />
            <Route exact path="/my-properties" element={<MyProperties />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/reward-income" element={<RewardIncome />} />
            <Route exact path="/payment-data" element={<PaymentData />} />
            {/* admin */}
            <Route exact path="/users-list" element={<UsersList />} />
            <Route exact path="/properties-list" element={<PropertiesList />} />
            <Route exact path="/emis-list" element={<EmiList />} />
            <Route
              exact
              path="/direct-income-users"
              element={<DirectIncomeUsers />}
            />
            <Route
              exact
              path="/period-reward-users"
              element={<PeriodRewardUsers />}
            />
            <Route exact path="/approve-deposit" element={<ApproveDeposit />} />
            <Route exact path="/type-manager" element={<TypeManager />} />
            <Route
              exact
              path="/event-register-list"
              element={<EventManager />}
            />
            <Route
              exact
              path="/category-manager"
              element={<CategoryManager />}
            />
            <Route exact path="/gallery-manager" element={<GalleryManager />} />
            <Route exact path="/admin/news-manager" element={<NewsManager />} />
            <Route
              exact
              path="/amenities-manager"
              element={<AmenitiesManager />}
            />
            <Route exact path="/icons-manager" element={<IconsManager />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/seller-register" element={<SellerRegister />} />
            <Route exact path="/seller-login" element={<SellerLogin />} />
            <Route
              exact
              path="/seller-dashboard"
              element={<SellerDashboard />}
            />
            <Route exact path="/seller-listing" element={<SellerListing />} />
            <Route exact path="/seller-queries" element={<SellerQueries />} />
            <Route exact path="/agents-list" element={<AgentsList />} />
            <Route exact path="/seller-profile" element={<SellerProfile />} />
            <Route
              exact
              path="/agents-properties-list"
              element={<AgentsPropertiesList />}
            />
            <Route
              exact
              path="/agent-add-property"
              element={<AgentAddProperty />}
            />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/about-page" element={<AboutUs />} />
            <Route exact path="/investments" element={<Investments />} />
            <Route exact path="/about-us" element={<AboutUsPage />} />
            <Route exact path="/join-our-event" element={<JoinEvent />} />
            <Route exact path="/event-gallery" element={<EventGallery />} />
            <Route
              exact
              path="/download-invitation-card/:id"
              element={<EventInvitation />}
            />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/latest-news" element={<LatestNews />} />
            <Route exact path="/add-property" element={<AddProperty />} />
            <Route
              exact
              path="/favourite-properties"
              element={<FavouriteProperties />}
            />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import dashboard_img from "../image/dashbord.png";
import direct_income_img from "../image/direct income.png";
import properties_img from "../image/properties.png";
import emi_transactions_img from "../image/emi transactions.png";
import profile_img from "../image/profile.png";
import my_properties_img from "../image/assets 1.png";
import logout_img from "../image/LOGOUT.png";
import logo from "../image/logo-divine.png";
const UserHeader = () => {
  const [admin, setAdmin] = useState();

  useEffect(() => {
    setAdmin(sessionStorage.getItem("isAdmin"));
  }, []);
  const navigate = useNavigate();
  // ---------Responsive-navbar-active-animation-----------

  function dashboard(event) {
    navigate("/user-dashboard");
  }

  function properties() {
    navigate("/properties");
  }

  function profile() {
    navigate("/profile");
  }
  function directIncome() {
    navigate("/direct-income");
  }
  function transactions() {
    navigate("/emi-transactions");
  }
  function myProperties() {
    navigate("/my-properties");
  }
  function logout() {
    navigate("/");
    sessionStorage.removeItem("token");
  }
  function rewardIncome() {
    navigate("/reward-income");
  }

  function admindashboard() {
    navigate("/admin-dashboard");
  }
  function userslist() {
    navigate("/users-list");
  }
  function propertiesList() {
    navigate("/properties-list");
  }
  function emislist() {
    navigate("/emis-list");
  }
  function directIncomeUsers() {
    navigate("/direct-income-users");
  }

  function periodRewardUsers() {
    navigate("/period-reward-users");
  }
  function approveDeposit() {
    navigate("/approve-deposit");
  }
  const handleClick = (e) => {
    var check = e.target;
    $(".dropdown-top").removeClass("active");
    $(check).parent().find(".dropdown-top-content").toggleClass("active");
    $(check).parent().toggleClass("active");
  };

  return (
    <>
      <nav className="navbar navbar-expand-custom navbar-mainbg">
        <Link to={admin == 0 ? "/user-dashboard" : "/admin-dashboard"}>
          <a className="navbar-brand navbar-logo" href="#">
            <img
              src={logo}
              style={{
                width: "120px",
                background: "white",
                borderRadius: "4px",
                padding: "5px",
              }}
            />
          </a>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars text-white"></i>
        </button>

        {admin == 0 ? (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li class={"nav-item"} onClick={dashboard}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={dashboard_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  Dashboard
                </a>
              </li>
              <li className="nav-item" onClick={properties}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={properties_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  Properties
                </a>
              </li>
              <li className="nav-item" onClick={profile}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={profile_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  Profile
                </a>
              </li>
              <li className="nav-item" onClick={directIncome}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={direct_income_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  Direct Income
                </a>
              </li>

              {/* <li className="nav-item" onClick={transactions}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />EMI Transactions</a>
                            </li> */}
              <li className="nav-item" onClick={myProperties}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={my_properties_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  My Properties
                </a>
              </li>

              <li className="nav-item" onClick={rewardIncome}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={direct_income_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  Reward Income
                </a>
              </li>
              <li className="nav-item" onClick={logout}>
                <a className="nav-link" href="javascript:void(0);">
                  <img
                    src={logout_img}
                    style={{ marginRight: "4px", height: "16px" }}
                  />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              {/* <li class={"nav-item"} onClick={admindashboard}>
                                <a className="nav-link" href="javascript:void(0);"><img src={dashboard_img} style={{ marginRight: '4px', height: '16px' }} />Dashboard</a>
                            </li> */}
              <div className="dropdown-top">
                <Link to="/admin-dashboard">
                  <button className="dropdown-top-btn" onClick={handleClick}>
                    Dashboard
                  </button>
                </Link>
              </div>
              <div className="dropdown-top">
                <button className="dropdown-top-btn" onClick={handleClick}>
                  List Manager
                </button>
                <div className="dropdown-top-content">
                  <button>
                    <Link to="/users-list">
                      <span>Users List</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/agents-list">
                      <span>Agents List</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/agents-properties-list">
                      <span>Agents Properties List</span>
                    </Link>
                  </button>
                </div>
              </div>

              <div className="dropdown-top">
                <button className="dropdown-top-btn" onClick={handleClick}>
                  Property Manager
                </button>
                <div className="dropdown-top-content">
                  <button>
                    <Link to="/properties-list">
                      <span>Property List</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/category-manager">
                      <span>Builder Manager</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/type-manager">
                      <span>Category Manager</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/amenities-manager">
                      <span>Amenities Manager</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/icons-manager">
                      <span>Icons Manager</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/gallery-manager">
                      <span>Gallery Manager</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/admin/news-manager">
                      <span>News Manager</span>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="dropdown-top d-none">
                <Link to="/approve-deposit">
                  <button className="dropdown-top-btn" onClick={handleClick}>
                    Approve/Reject Deposits
                  </button>
                </Link>
              </div>
              <div className="dropdown-top d-none">
                <button className="dropdown-top-btn" onClick={handleClick}>
                  Reports
                </button>
                <div className="dropdown-top-content">
                  <button>
                    <Link to="/emis-list">
                      <span>EMI Transaction Report</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/direct-income-users">
                      <span>Direct Income Report</span>
                    </Link>
                  </button>
                  <button>
                    <Link to="/period-reward-users">
                      <span>Reward Report</span>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="dropdown-top">
                <Link to="/properties-list">
                  <button className="dropdown-top-btn" onClick={handleClick}>
                    Properties List
                  </button>
                </Link>
              </div>
              <div className="dropdown-top">
                <Link to="/event-register-list">
                  <button className="dropdown-top-btn" onClick={handleClick}>
                    Events Register
                  </button>
                </Link>
              </div>
              <div className="dropdown-top">
                <Link to="/">
                  <button className="dropdown-top-btn" onClick={handleClick}>
                    Logout
                  </button>
                </Link>
              </div>
              {/* <li className="nav-item" onClick={userslist}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />Users List</a>
                            </li>
                            <li className="nav-item" onClick={approveDeposit}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />Approve Deposit</a>
                            </li>
                            <li className="nav-item" onClick={emislist}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />Emis Transaction Report</a>
                            </li>
                            <li className="nav-item" onClick={directIncomeUsers}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />Direct Income Report</a>
                            </li>
                            <li className="nav-item" onClick={periodRewardUsers}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />Reward Report</a>
                            </li> */}
              {/* <li className="nav-item" onClick={propertiesList}>
                                <a className="nav-link" href="javascript:void(0);"><img src={emi_transactions_img} style={{ marginRight: '4px', height: '16px' }} />Properties List</a>
                            </li> */}
              {/* <li className="nav-item" onClick={logout}>
                                <a className="nav-link" href="javascript:void(0);"><img src={logout_img} style={{ marginRight: '4px', height: '16px' }} />Logout</a>
                            </li> */}
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};
export default UserHeader;

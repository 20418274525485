import React, { useEffect, useState } from "react";
import logo from "../image/logo-divine.png";
import { Link } from "react-router-dom";
import img1 from "../image/Social Media Icons/fb.svg";
import img2 from "../image/Social Media Icons/insta.svg";
import img3 from "../image/Social Media Icons/Vector.svg";
import img4 from "../image/Social Media Icons/Vector-1.svg";
import Modal from "react-modal";
const customStyles = {
  content: {
    position: "relative",
    top: "10px",
    width: "700px",
    height: "410px",
    margin: "0 auto",
    padding: "0px !important",
    border: "none !important",
  },
};
const HomeFooter = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    closeModal();
  }, []);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    document.body.style.overflow = "unset";
    setIsOpen(false);
  }
  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  return (
    <>
      <section
        className="main-footer"
        style={{ background: "#010101", padding: "40px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
                <li>
                  <img src={logo} style={{ width: "150px" }} />
                </li>
                <li style={{ marginTop: "20px" }}>
                  Welcome to Divine Bricks - Your Trusted Partner in Real Estate
                </li>
                <ul className="footer-social-icons">
                  <a
                    href="https://www.facebook.com/Divinebricks.realestate"
                    target="_blank"
                  >
                    <li>
                      <img
                        src={img1}
                        title="divine-bricks-facebook"
                        alt="divine-bricks-facebook"
                      />
                    </li>
                  </a>
                  <a
                    href="https://www.instagram.com/divine_bricks/"
                    target="_blank"
                    alt="divine-bricks-instagram"
                  >
                    <li>
                      <img
                        src={img2}
                        title="divine-bricks-instagram"
                        alt="divine-bricks-instagram"
                      />
                    </li>
                  </a>
                  <a href="https://twitter.com/DivineBricks" target="_blank">
                    <li>
                      <img
                        src={img4}
                        title="divine-bricks-twitter"
                        alt="divine-bricks-twitter"
                      />
                    </li>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC8M-SboOvquw6JKnLJk4h4w"
                    target="_blank"
                  >
                    <li>
                      <img
                        src={img3}
                        title="divine-bricks-youtube"
                        alt="divine-bricks-youtube"
                      />
                    </li>
                  </a>
                </ul>
              </ul>
              <Link to={"MSME-certificate_divine-bricks.pdf"} target="_blank">
                <img
                  src="msme-logo.svg"
                  height={150}
                  width={150}
                  alt="msme-logo"
                />
              </Link>
            </div>
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
                <li
                  style={{ textDecoration: "underline", marginBottom: "10px" }}
                >
                  Contact Details
                </li>
                <li style={{ display: "flex" }}>
                  <i
                    className="fa fa-map-marker"
                    aria-hidden="true"
                    style={{ paddingTop: "8px", paddingRight: "10px" }}
                  ></i>{" "}
                  Dubai : 3202/32nd floor, Aspin Commercial Tower, Shaikh Zayed
                  Road, Near Financial Metro Station, Dubai, U.A.E.
                </li>
                {/* <li style={{ display: "flex" }}>
                  <i
                    className="fa fa-map-marker"
                    aria-hidden="true"
                    style={{ paddingTop: "8px", paddingRight: "10px" }}
                  ></i>{" "}
                  Dubai : WH2-10A, Mabrook Business Center, P2-Block A, Saint
                  Shuaib 3, DIC, PO BOX : 45195, Dubai
                </li> */}
                <li>
                  <i
                    className="fa fa-phone"
                    aria-hidden="true"
                    style={{ paddingRight: "10px" }}
                  ></i>
                  <a href="tel:+971 54 320 2567" style={{ color: "#fff" }}>
                    +971 54 320 2567
                  </a>
                </li>
                <li>
                  <i
                    className="fa fa-envelope"
                    aria-hidden="true"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  <a
                    href="mailto:info@divinebricks.com"
                    style={{ color: "#fff" }}
                  >
                    info@divinebricks.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
                <li
                  style={{ textDecoration: "underline", marginBottom: "10px" }}
                >
                  Quick Links
                </li>
                <li>
                  <Link to="/privacy-policy" style={{ color: "#fff" }}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
                <li
                  style={{ textDecoration: "underline", marginBottom: "10px" }}
                >
                  Popular Searches
                </li>
                <li>
                  <Link
                    to="/divine-bricks-property-listing?typeArr=villa"
                    style={{ color: "#fff" }}
                  >
                    Villa in Dubai
                  </Link>
                </li>
                <li>
                  <Link
                    to="/divine-bricks-property-listing?typeArr=apartment"
                    style={{ color: "#fff" }}
                  >
                    Apartment in Dubai
                  </Link>
                </li>
                <li>
                  <Link
                    to="/divine-bricks-property-listing?typeArr=hotel-apartment"
                    style={{ color: "#fff" }}
                  >
                    Hotel Apartment in Dubai
                  </Link>
                </li>
                <li>
                  <Link
                    to="/divine-bricks-property-listing?typeArr=commercial-building"
                    style={{ color: "#fff" }}
                  >
                    Commercial Building in Dubai
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Link
        className="scroll-top-1"
        target="_blank"
        to="https://api.whatsapp.com/send?phone=971543202567"
      >
        <img src="../whatsapp-icon.webp" />
      </Link>{" "}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        shouldOnOverlayScroll={false}
        className={"modalClass"}
      >
        <i
          className="fa fa-close specialClass"
          onClick={() => closeModal()}
        ></i>
        <Link
          target="_blank"
          to={
            "https://allevents.in/new%20delhi/dubai-real-estate-expo-in-new-delhi-divine-bricks-unveils-lucrative-opportunities/80002169663321?ref=smdl"
          }
        >
          <img
            src="../divine-bricks-event-banner.webp"
            alt="divine-bricks-event-banner"
            title="divine-bricks-event-new-delhi"
            className="bannerIMG"
          />
        </Link>
      </Modal>
    </>
  );
};
export default HomeFooter;

import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import moment from "moment";
import { Link } from "react-router-dom";

const HomeArticles = ({ recrodsCount }) => {
  const [news, setNews] = useState();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    getNews();
  }, []);

  function getNews() {
    axios
      .get(`api/users/get-news-list?recrodsCount=${recrodsCount}`, {
        headers: {},
      })
      .then((resp) => {
        console.log("resp", resp.data);
        if (resp.data.success) {
          setNews(resp.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <section
        className="articles-section"
        style={{ background: "#FFFFFF", padding: "40px" }}
      >
        <div className="container">
          <h3 style={{ color: "#5C5959", fontSize: "28px" }}>Latest News</h3>
          <div className="row">
            {news?.length > 0
              ? news.map((ne, index) => {
                  return (
                    <>
                      <div
                        className="col-md-4 mt-4"
                        key={index}
                        style={{ textAlign: "left" }}
                      >
                        <Link to={ne.link_url} target="_blank">
                          <div className="card blogs-articles-card">
                            <div className="position-relative">
                              <p
                                style={{
                                  position: "absolute",
                                  bottom: "-20px",
                                  background: "#8F5726",
                                  color: "white",
                                  padding: "14px 8px",
                                  borderRadius: "5px",
                                  marginBottom: "0px",
                                }}
                              >
                                {ne.news_date
                                  ? moment(ne.news_date).format("DD")
                                  : null}
                                {" - "}
                                {months[moment(ne.news_date).month()]}
                              </p>
                              <img
                                src={
                                  "https://api.divinebricks.com" + ne.img_url
                                }
                                alt="divine-bricks-blogs"
                                title="divine-bricks-blogs"
                                style={{ width: "100%", height: "206px" }}
                              />
                            </div>
                            <ul></ul>
                            <p style={{ color: "#212121", fontSize: "19px" }}>
                              {ne.name}
                            </p>
                            <a
                              href={ne.link_url}
                              target="_blank"
                              style={{
                                color: "#788593",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                            >
                              Read More
                            </a>
                          </div>
                        </Link>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
          {recrodsCount != "All" ? (
            <>
              <div className="row mt-4">
                <div className="col-md-12  text-center">
                  <Link
                    to={"latest-news"}
                    class="button-hover-style"
                    style={{
                      background: "rgb(143, 87, 38)",
                      border: "none",
                      color: "white",
                      padding: "4px 13px",
                      borderRadius: "5px",
                    }}
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};
export default HomeArticles;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Blogs = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section className="testimonials bg-white-2 rec-pro">
        <div className="container-fluid">
          <div
            className="section-title col-md-5"
            style={{ marginBottom: "0px" }}
          >
            <h3>Our Blogs</h3>
            <h2>Where Every House Tells a Story.</h2>
          </div>
          <Carousel responsive={responsive} className="mt-5">
            <a
              style={{ color: "black", textDecoration: "none" }}
              title="Exploring Dubai Creek Harbour: Insights, Opportunities, and Investment Tips"
            >
              <div className="singleJobClinet">
                <img
                  src="https://cloud.famproperties.com/fam/blog/806-125457.jpg"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                    height: "178px",
                  }}
                  alt="exploring-dubai-creek-harbour-insights-opportunities-and-investment-tips"
                  title="Exploring Dubai Creek Harbour: Insights, Opportunities, and Investment Tips"
                />
                <p>
                  <strong>
                    Exploring Dubai Creek Harbour: Insights, Opportunities, and
                    Investment Tips
                  </strong>
                </p>
                <div className="detailJC">
                  <p>
                    Dubai Creek Harbour, Emaar Properties, seamlessly blends
                    tradition with modernity. Set against the stunning backdrop
                    of Downtown Dubai's skyline, traditional abra boats sail on
                    the historic Dubai Creek Harbour waterway, encapsulating the
                    city's evolution and deep-rooted traditions.
                  </p>
                </div>
              </div>
            </a>
            <a
              style={{ color: "black", textDecoration: "none" }}
              title="A Comprehensive Look at Investor Rights in Dubai's Off-Plan
              Market Analysis"
            >
              <div className="singleJobClinet">
                <img
                  src="https://cloud.famproperties.com/fam/blog/809-104956.jpg"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                    height: "178px",
                  }}
                  alt="investor-rights-dubai-off-plan-market-analysis"
                  title="A Comprehensive Look at Investor Rights in Dubai's Off-Plan
                  Market Analysis"
                />
                <p>
                  <strong>
                    A Comprehensive Look at Investor Rights in Dubai's Off-Plan
                    Market
                  </strong>
                </p>
                <div className="detailJC">
                  <p>
                    Investors considering off-plan properties in Dubai often
                    wonder about their rights if the handover hits a snag. To
                    navigate the off-plan journey with confidence, consider
                    consulting a legal practitioner in Dubai.
                  </p>
                </div>
              </div>
            </a>
            <a
              title="Burj Khalifa's 14-Year Legacy: A Skyline Icon Evoking
              Investor Excitement"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="singleJobClinet">
                <img
                  src="https://cloud.famproperties.com/fam/blog/811-124124.jpg"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                    height: "178px",
                  }}
                  alt="burj-khalifa-14-year-legacy-skyline-icon-investor-excitement"
                  title="Burj Khalifa's 14-Year Legacy: A Skyline Icon Evoking
                  Investor Excitement"
                />
                <p>
                  <strong>
                    Burj Khalifa's 14-Year Legacy: A Skyline Icon Evoking
                    Investor Excitement
                  </strong>
                </p>
                <div className="detailJC">
                  <p>
                    Dubai's iconic Burj Khalifa celebrates its 14th anniversary
                    amidst noteworthy changes in the real estate sector. Beyond
                    its architectural magnificence, the tower's enduring appeal
                    now reflects significant shifts in investor sentiment and
                    market dynamics, as outlined by global property consultant
                    Knight Frank.
                  </p>
                </div>
              </div>
            </a>
            <a
              style={{ color: "black", textDecoration: "none" }}
              title="Dubai Property Market in 2024: The Rise of '1% Monthly'
              Payment Plans"
            >
              <div className="singleJobClinet">
                <img
                  src="https://cloud.famproperties.com/fam/blog/805-113719.jpg"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                    height: "178px",
                  }}
                  alt="the-rise-of-1-percent-monthly-payment-plans"
                  title="Dubai Property Market in 2024: The Rise of '1% Monthly'
                  Payment Plans"
                />
                <p>
                  <strong>
                    Dubai Property Market in 2024: The Rise of '1% Monthly'
                    Payment Plans
                  </strong>
                </p>
                <div className="detailJC">
                  <p>
                    In the ever-evolving landscape of Dubai's property market,
                    2024 marks a pivotal year characterized by the emergence of
                    '1% monthly' payment plans. Developers, anticipating a shift
                    in dynamics, are strategically adopting these plans to
                    attract end-user buyers and provide more accessible avenues
                    for property acquisition.
                  </p>
                </div>
              </div>
            </a>
            <a
              style={{ color: "black", textDecoration: "none" }}
              title="Becoming a Real Estate Agent in Dubai: What You Need to Know"
            >
              <div className="singleJobClinet">
                <img
                  src="https://cloud.famproperties.com/fam/blog/804-171724.jpeg"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                    height: "178px",
                  }}
                  alt="requirements-to-become-a-real-estate-agent-in-dubai"
                  title="Becoming a Real Estate Agent in Dubai: What You Need to Know"
                />
                <p>
                  <strong>
                    Becoming a Real Estate Agent in Dubai: What You Need to Know
                  </strong>
                </p>
                <div className="detailJC">
                  <p>
                    Dubai's booming real estate sector has sparked a surge in
                    interest among individuals looking to carve a career in this
                    dynamic industry. The process of becoming a real estate
                    agent in the emirate involves several crucial steps,
                    ensuring compliance with the country’s laws and regulations.
                  </p>
                </div>
              </div>
            </a>
          </Carousel>
        </div>
      </section>
    </>
  );
};
export default Blogs;

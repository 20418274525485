import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import axios from "../axiosInstance";
import UserHeader from "./UserHeader";
import { toast } from "react-toastify";
import $ from 'jquery'
import { ColorRing } from "react-loader-spinner";
import EditIcon from '@mui/icons-material/Edit';

const IconsManager = () => {

    const columns = [
        {
            name: "Icons ID",
            selector: (row) => row.id,
            width: '100px'
        },
        {
            name: "Name",
            selector: (row) => row.type,
            width: '250px'
        },
        {
            name: "Logo",
            selector: (row) => <div className="">{row.type == 'Youtube Link' ? <a href={row.image_url} target="_blank">Youtube Link</a> : <img src={'https://api.divinebricks.com' + row?.image_url} style={{ width: '50px', height: '50px' }} /> }</div>,
        },
        {
            name: "Date",
            selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
        },
        {
            name: "Status",
            selector: (row) => <div className={row.is_status == 'inactive' ? "badge badge-danger p-2" : "badge badge-success p-2"}>{row.is_status}</div>,
            width: 'auto'
        },
        {
            name: "Action",
            selector: (row) => <><button data-toggle="modal" data-target="#exampleModal1" onClick={() => { setCurrentRow(row); setIconType(row.type); setImage(row.image_url); setStatus(''); console.log(row); }} style={{ border: 'none', background: 'transparent' }}><EditIcon /></button>
                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Edit Icons</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="container my-3">
                                    <div className="row">
                                        <div className="col-md-12 mt-3" style={{ textAlign: 'left' }}>
                                            <label>Add Logo</label>
                                            <select className="form-control" value={iconType} readOnly>
                                                <option selected disabled>Select Type of Icon</option>
                                                <option value='Icon'>Icon</option>
                                                <option value='Photo'>Photo</option>
                                                <option value='Youtube Link'>Youtube Link</option>
                                                <option value='Other'>Other</option>
                                            </select>
                                            {iconType == 'Icon' || iconType == 'Photo' || iconType == 'Other' ? <> <input className='form-control mt-2' type="file" id="myfile" name="myfile" required onChange={imageUpload3} />
                                                <img id="output3" src={'https://api.divinebricks.com' + image} alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover' }} /></> : ''}
                                            {iconType == 'Youtube Link' ? <input type="text" className="form-control mt-2" placeholder="Enter Youtube Link" value={image} onChange={(e) => setImage(e.target.value)} /> : ''}
                                        </div>
                                        <div className="col-md-12 mt-3" style={{ textAlign: 'left' }}>
                                            <label>Select Status</label>
                                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option selected disabled>Select Status</option>
                                                <option value='active'>Active</option>
                                                <option value='inactive'>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" onClick={() => editType(row)}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div></>,
            width: 'auto'
        },
    ];

    const [data, setData] = useState()
    const [name, setName] = useState()
    const [image, setImage] = useState()
    const [logo, setLogo] = useState()
    const [type, setType] = useState()
    const [updateName, setUpdateName] = useState()
    const [updateImage, setUpdateImage] = useState()
    const [updateLogo, setUpdateLogo] = useState()
    const [status, setStatus] = useState()
    const [visible, setVisible] = useState(false)
    const [currentRow, setCurrentRow] = useState()
    const [iconType, setIconType] = useState()

    const token = sessionStorage.getItem("token")
    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {
        axios
            .get(
                `api/admins/get-admin-icon-list`,
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result.docs)
            })
            .catch((err) => {
                console.log(err)
            });
    }


    const addType = () => {
        setVisible(true)
        axios
            .post(
                `api/admins/add-icon`,
                {
                    type: iconType,
                    image_url: image,
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setVisible(false)
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 'succ4' })
                    $("[data-dismiss=modal]").trigger({ type: "click" })
                    fetchData()
                }
                else {
                    toast.error(resp.data.msg, { toastId: 'er4' })
                }
                // setData(resp.data.result.docs)
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }

    const editType = (row) => {
        setVisible(true)
        axios
            .post(
                `api/admins/update-icon`,
                {
                    type: type != undefined ? type : currentRow?.type,
                    iconId: currentRow.id.toString(),
                    image_url: image != undefined ? image : currentRow?.image,
                    is_status: status == '' ? currentRow.is_status : status
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setVisible(false)
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 'succ4' })
                    $("[data-dismiss=modal]").trigger({ type: "click" })
                    fetchData()
                }
                else {
                    toast.error(resp.data.msg, { toastId: 'er4' })
                }
                // setData(resp.data.result.docs)
            })
            .catch((err) => {
                console.log(err)
            });
    }


    const imageUpload4 = async (event) => {
        var output1 = document.getElementById('output5');
        output1.src = URL.createObjectURL(event.target.files[0]);
        output1.onload = function () {
            URL.revokeObjectURL(output1.src) // free memory
        }
        await uploadFile4(event.target.files[0])
    }


    function uploadFile4(file) {
        setVisible(true)
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-document`,
            formData,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        ).
            then((resp) => {
                setVisible(false)
                setUpdateLogo(resp.data.result?.image_url)
            }).catch((err) => { console.log('eerr', err) });
    }


    const imageUpload3 = async (event) => {
        var output1 = document.getElementById('output3');
        output1.src = URL.createObjectURL(event.target.files[0]);
        output1.onload = function () {
            URL.revokeObjectURL(output1.src) // free memory
        }
        await uploadFile3(event.target.files[0])
    }


    function uploadFile3(file) {
        setVisible(true)
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-document`,
            formData,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        ).
            then((resp) => {
                setVisible(false)
                setImage(resp.data.result?.image_url)
            }).catch((err) => { console.log('eerr', err) });
    }
    return (
        <>
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <UserHeader />
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h4 className="card-title" style={{ textAlign: 'left' }}>Icons List</h4>
                            <button className="btn btn-danger" data-toggle="modal" data-target="#exampleModal">Add Icons</button>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive export-button-table">
                                <DataTable
                                    pagination={true}
                                    columns={columns}
                                    data={data}
                                    paginationServer={true}
                                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Icons</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="container my-3">
                                <div className="row">
                                    <div className="col-md-12 mt-3" style={{ textAlign: 'left' }}>
                                        <label>Add Logo</label>
                                        <select className="form-control" value={iconType} onChange={(e) => setIconType(e.target.value)}>
                                            <option selected disabled>Select Type of Icon</option>
                                            <option value='Icon'>Icon</option>
                                            <option value='Photo'>Photo</option>
                                            <option value='Youtube Link'>Youtube Link</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                        {iconType == 'Icon' || iconType == 'Photo' || iconType == 'Other' ? <> <input className='form-control mt-2' type="file" id="myfile" name="myfile" required onChange={imageUpload3} />
                                            <img id="output3" src={'https://api.divinebricks.com' + logo} alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover' }} /></> : ''}
                                        {iconType == 'Youtube Link' ? <input type="text" className="form-control mt-2" placeholder="Enter Youtube Link" value={image} onChange={(e) => setImage(e.target.value)} /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={addType}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default IconsManager
import React, { useState, useEffect } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import $ from 'jquery'
import OtpInput from 'react-otp-input';
import SellerHeader from "./SellerHeader";
import registerImg from '../image/seller-login.webp'
import Header from './Header';
import HomePageHeader from './HomePageHeader';
import TopHeader from './TopHeader';


const SellerRegister = () => {
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [mobile, setMobile] = useState()
    const [visible, setVisible] = useState(false)
    const [otp, setOtp] = useState('');
    const [check, setCheck] = useState(false);
    const navigate = useNavigate()

    const goBack = (e) => {
        e.preventDefault()
        $('.enter-otp').css('display', 'none')
        $('.enter-details').css('display', 'block')
    }

    const register = (e) => {
        if (check == false) {
            toast.error('Please check Terms & Conditions')
            e.preventDefault()
            return
        }
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/agent-register`,
                {
                    name: firstName + lastName,
                    email: email,
                    password: password,
                    mobile_no: mobile,
                },
                // {
                //   headers: {
                //     "x-access-token": token,
                //   },
                // }
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's1' })
                    $('.enter-otp').css('display', 'block')
                    $('.enter-details').css('display', 'none')
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }

    const verifyOtp = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/complete-agent-register`,
                {
                    email: email,
                    otp: otp
                },
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e2' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's2' })
                    setTimeout(() => {
                        navigate('/seller-login')
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }
    return (
        <>
            {visible ? <div className="screen-overlay"></div> : ''}
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <div className='seller-register-page' style={{ overflow: 'hidden' }}>
                <TopHeader />
                <HomePageHeader />
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <img src={registerImg} />
                        </div>
                        <div className="col-md-5 enter-details" style={{ margin: 'auto' }}>
                            <h2 style={{ textAlign: 'left', marginBottom: '20px' }}>Register</h2>
                            <form className="seller-login-panel">
                                <div className="row">
                                    <div className="col-md-6 md-mb-0 mb-3">
                                        <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>

                                <input className='form-control mb-2' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email address' id='email' type='email' />
                                <input className='form-control mb-2' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' id='password' type='password' />
                                <input type="number" className="form-control mb-3" placeholder="Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                <div className="d-flex mt-3 mb-3 short-font-12" style={{ alignItems: 'center' }}> <input type="checkbox" className="form-control" onClick={() => setCheck(true)} style={{ marginTop: '0' }} /> I have read and agree to the Terms & Conditions</div>
                                <button type="submit" className="btn btn-primary" onClick={register}>Register</button>
                                <div className="mt-2">Already have an Account? <Link to='/seller-login'>Login</Link></div>
                            </form>
                        </div>
                        <div className='enter-otp' style={{ margin: 'auto' }}>
                            <p style={{ fontSize: '20px', color: '#1e5239' }}>Verify OTP</p>
                            <h4 className='mb-4' style={{ fontSize: '16px', color: '#1e5239' }}>Enter 4 digit OTP sent to {email}</h4>
                            <p style={{ fontSize: '20px', color: '#1e5239' }}>Please use 1000 for now</p>
                            <div className='d-flex justify-content-center'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    inputStyle="fill-otp"
                                    renderSeparator={<span>&nbsp;</span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                            <div className="d-flex mt-4">
                                <Link className='w-100' style={{ color: 'white' }} onClick={goBack}><button type="submit" className="btn btn-danger w-100">&larr; Go Back</button></Link>
                                <Link className='w-100 mx-2' style={{ color: 'white' }} onClick={verifyOtp}><button type="submit" className="btn btn-primary w-100">Verify</button></Link>
                            </div>
                            {/* <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <button className='btn btn-warning' style={{ color: 'white' }} onClick={goBack}>Go Back</button>
                                </div>
                                <div className='col-md-6'>
                                    <button className="mb-4 btn btn-primary" style={{ color: 'white', fontSize: '20px' }} onClick={verifyOtp}>Submit</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerRegister
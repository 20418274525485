import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import axios from "../axiosInstance";
import UserHeader from "./UserHeader";
import { toast } from "react-toastify";
import $ from "jquery";
import { ColorRing } from "react-loader-spinner";
import EditIcon from "@mui/icons-material/Edit";

const CategoryManager = () => {
  const columns = [
    {
      name: "Builder ID",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "250px",
    },

    {
      name: "Image",
      selector: (row) => (
        <div className="">
          <img
            src={"https://api.divinebricks.com" + row?.image}
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    {
      name: "Logo",
      selector: (row) => (
        <div className="">
          <img
            src={"https://api.divinebricks.com" + row?.logo}
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.is_status == "inactive"
              ? "badge badge-danger p-2"
              : "badge badge-success p-2"
          }
        >
          {row.is_status}
        </div>
      ),
      width: "auto",
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <button
            data-toggle="modal"
            data-target="#exampleModal1"
            onClick={() => {
              setCurrentRow(row);
              setUpdateName("");
              setStatus("");
            }}
            style={{ border: "none", background: "transparent" }}
          >
            <EditIcon />
          </button>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Builder
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container my-3">
                    <div className="row">
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Name</label>
                        <input
                          className="form-control"
                          placeholder="Name"
                          type="text"
                          value={
                            updateName != "" ? updateName : currentRow.name
                          }
                          onChange={(e) => setUpdateName(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Edit Image</label>
                        <input
                          className="form-control"
                          type="file"
                          id="myfile"
                          name="myfile"
                          required
                          onChange={imageUpload1}
                        />
                        <img
                          id="output2"
                          src={
                            "https://api.divinebricks.com" + currentRow?.image
                          }
                          alt="your image"
                          style={{
                            height: "100px",
                            width: "100px",
                            marginTop: "10px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Edit Logo</label>
                        <input
                          className="form-control"
                          type="file"
                          id="myfile"
                          name="myfile"
                          required
                          onChange={imageUpload4}
                        />
                        <img
                          id="output5"
                          src={
                            "https://api.divinebricks.com" + currentRow?.logo
                          }
                          alt="your image"
                          style={{
                            height: "100px",
                            width: "100px",
                            marginTop: "10px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Status</label>
                        <select
                          className="form-control"
                          value={status != "" ? status : currentRow.is_status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option selected disabled>
                            Select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => editType(row)}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      width: "auto",
    },
  ];

  const [data, setData] = useState();
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [logo, setLogo] = useState();
  const [updateName, setUpdateName] = useState();
  const [updateImage, setUpdateImage] = useState();
  const [updateLogo, setUpdateLogo] = useState();
  const [status, setStatus] = useState();
  const [visible, setVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const token = sessionStorage.getItem("token");
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`api/admins/get-admin-builder-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addType = () => {
    setVisible(true);
    axios
      .post(
        `api/admins/add-builder`,
        {
          name: name,
          image: image,
          logo: logo,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editType = (row) => {
    setVisible(true);
    axios
      .post(
        `api/admins/update-builder`,
        {
          name: updateName == "" ? currentRow.name : updateName,
          builderId: currentRow.id.toString(),
          image: updateImage != undefined ? updateImage : currentRow?.image,
          logo: updateLogo != undefined ? updateLogo : currentRow?.logo,
          is_status: status == "" ? currentRow.is_status : status,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageUpload1 = async (event) => {
    var output1 = document.getElementById("output2");
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile1(event.target.files[0]);
  };

  function uploadFile1(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`api/users/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        setUpdateImage(resp.data.result?.image_url);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }

  const imageUpload4 = async (event) => {
    var output1 = document.getElementById("output5");
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile4(event.target.files[0]);
  };

  function uploadFile4(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`api/users/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        setUpdateLogo(resp.data.result?.image_url);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }

  const imageUpload2 = async (event) => {
    var output1 = document.getElementById("output1");
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile2(event.target.files[0]);
  };

  function uploadFile2(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`api/users/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        setImage(resp.data.result?.image_url);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }

  const imageUpload3 = async (event) => {
    var output1 = document.getElementById("output3");
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile3(event.target.files[0]);
  };

  function uploadFile3(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`api/users/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        setLogo(resp.data.result?.image_url);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }
  return (
    <>
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <UserHeader />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div
              className="card-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Builders List
              </h4>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Add Builder
              </button>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Builder
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container my-3">
                <form>
                  <div className="row">
                    <div
                      className="col-md-12 mt-3"
                      style={{ textAlign: "left" }}
                    >
                      <label>Name</label>
                      <input
                        className="form-control"
                        placeholder="Name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div
                      className="col-md-12 mt-3"
                      style={{ textAlign: "left" }}
                    >
                      <label>Add Image</label>
                      <input
                        type="file"
                        id="myfile"
                        name="myfile"
                        className="form-control"
                        required
                        onChange={imageUpload2}
                      />
                      <img
                        id="output1"
                        src={"https://api.divinebricks.com" + image}
                        alt="your image"
                        className="form-control"
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "10px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div
                      className="col-md-12 mt-3"
                      style={{ textAlign: "left" }}
                    >
                      <label>
                        Add Logo <span>85x85 px</span>
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="myfile"
                        name="myfile"
                        required
                        onChange={imageUpload3}
                      />
                      <img
                        id="output3"
                        src={"https://api.divinebricks.com" + logo}
                        alt="your image"
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "10px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addType}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryManager;

import React, { useState, useEffect, useRef } from "react";
import UserHeader from "./UserHeader";
import { Circle } from "rc-progress";
import axios from "../axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import img1 from "../image/Frame 14.png";
import img2 from "../image/Frame 15.png";
import img3 from "../image/Frame 16.png";
import img4 from "../image/Frame 17.png";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import user_img from ''
const columns = [
  {
    name: "User ID",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    name: "Amount",
    selector: (row) => "$" + row.Amount,
  },
  {
    name: "Date",
    selector: (row) => moment(row.createdon).format("DD-MM-YYYY  HH:mm:ss"),
  },
  {
    name: "Remarks",
    selector: (row) => (row.remarks == null ? null : row.remarks),
    width: "auto",
  },
];

const data_tables = [
  {
    id: 1,
    Amount: "100",
    date: "1988",
    remarks: "Paid 8% for 1st Month",
  },
  {
    id: 2,
    Amount: "200",
    date: "2000",
    remarks: "Paid 8% for 2nd Month",
  },
];

const columns1 = [
  {
    name: "User ID",
    selector: (row) => row.id,
    width: "80px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    width: "120px",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    width: "220px",
  },
  {
    name: "Mobile No.",
    selector: (row) => row.mobile_no,
  },
  {
    name: "Date",
    selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
  },
  {
    name: "Status",
    selector: (row) => (
      <div
        className={
          row.is_status == "inactive"
            ? "badge badge-danger p-2"
            : "badge badge-success p-2"
        }
      >
        {row.is_status}
      </div>
    ),
  },
];

const data1 = [
  {
    id: 1,
    Amount: "100",
    date: "1988",
    remarks: "Direct Income from Satiish",
  },
  {
    id: 2,
    Amount: "200",
    date: "2000",
    remarks: "Direct Income from Ashiish",
  },
];

const AdminDashboard = () => {
  function copyReferral(e) {
    e.preventDefault();
    // Get the text field
    var copyText = document.getElementById("myInput");
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }
  const [data, setData] = useState();
  const [adminData, setAdminData] = useState();
  const propertyData = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token == null || token == "undefined") {
      navigate("/");
    }
    axios
      .get(`api/users/get-user-details`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setAdminData(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`api/users/get-user-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result?.rows);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-property-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        propertyData.current = resp.data.result?.propertyList;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const percentage = 64;
  return (
    <>
      <UserHeader />

      <div className="container pb-5">
        <div className="row mb-4 mt-4">
          <div className="col-12">
            <div className="card tt-page-header" style={{ borderRadius: "0" }}>
              <div
                className="card-body d-lg-flex align-items-center justify-content-lg-between"
                style={{ background: "white" }}
              >
                <div className="tt-page-title">
                  <h2 className="h5 mb-lg-0">Admin Dashboard</h2>
                </div>
                <div className="tt-action">
                  <Link to="/users-list" className="btn btn-secondary">
                    {" "}
                    <PeopleOutlinedIcon /> Manage Users
                  </Link>

                  <Link to="/properties-list" className="btn btn-primary mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-plus me-2"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Add Property
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6" style={{ textAlign: "left" }}>
                <div className="card" style={{ border: "1px solid yellow" }}>
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <img src={img2} />
                      <div style={{ paddingLeft: "16px" }}>
                        <p
                          style={{
                            color: "#919eab",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginBottom: "5px",
                          }}
                        >
                          Properties Listed
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "28px",
                          }}
                        >
                          10
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ textAlign: "left" }}>
                <div className="card" style={{ border: "1px solid red" }}>
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <img src={img3} />
                      <div style={{ paddingLeft: "16px" }}>
                        <p
                          style={{
                            color: "#919eab",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginBottom: "5px",
                          }}
                        >
                          Total Builder
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "28px",
                          }}
                        >
                          6
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h6
                      className="card-title"
                      style={{ textAlign: "left", marginBottom: "0" }}
                    >
                      Latest Users Report
                    </h6>
                  </div>
                  <div
                    className="card-body pt-0"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    <div className="table-responsive export-button-table">
                      <DataTable
                        pagination={true}
                        columns={columns1}
                        data={data}
                        paginationServer={true}
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div
                className="card-body"
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  height: "auto",
                }}
              >
                <p
                  style={{
                    color: "#919eab",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "20px",
                  }}
                >
                  Recent Properties
                </p>
                {propertyData.current?.map((data) => {
                  return (
                    <div
                      className="d-flex mb-3 align-items-center"
                      style={{
                        border: "2px solid #315745",
                        padding: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      <Link
                        to="/properties-list"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100px",
                            maxHeight: "60px",
                            borderRadius: "4px",
                          }}
                          src={"https://api.divinebricks.com" + data?.image}
                        />
                        <p
                          style={{
                            paddingLeft: "10px",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "black",
                          }}
                        >
                          {data.name}
                        </p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2 d-none">
          <div className="col-md-6 mt-2">
            <div className="card">
              <div className="card-header">
                <h6
                  className="card-title"
                  style={{ textAlign: "left", marginBottom: "0" }}
                >
                  Latest Emis Report
                </h6>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive export-button-table">
                  <DataTable
                    pagination={true}
                    columns={columns}
                    data={data1}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="card">
              <div className="card-header">
                <h6
                  className="card-title"
                  style={{ textAlign: "left", marginBottom: "0" }}
                >
                  Latest Direct Income Report
                </h6>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive export-button-table">
                  <DataTable
                    pagination={true}
                    columns={columns}
                    data={data1}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card">
              <div style={{ padding: "1.25rem", textAlign: "left" }}>
                <h6 style={{ textAlign: "left" }}>My Referral Link</h6>
                <div className="d-flex">
                  <input
                    className="form-control"
                    id="myInput"
                    style={{ border: "none", maxWidth: "400px" }}
                    type="text"
                    value={
                      "https://divinebricks.ae/register?referralcode=" +
                      adminData?.referralCode
                    }
                    readOnly
                  />
                  <button
                    className="btn btn-primary"
                    style={{ width: "100px" }}
                    onClick={copyReferral}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;

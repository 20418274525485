import React, { useState, useEffect, useRef } from "react";
import HomePageHeader from "../HomePageHeader";
import TopHeader from "../TopHeader";
import HomeFooter from "../HomeFooter";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const JoinEvent = () => {
  const divRef = useRef(null);
  const { id } = useParams();

  const [eventData, setEventData] = useState();
  useEffect(() => {
    console.log(">>id>>>", id);
    getJoinData();
  }, []);
  const getJoinData = async () => {
    axios
      .post(`api/users/get-join-form`, { joinId: id }, {})
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.success) {
          setEventData(resp.data.result);
        } else {
          toast.error(resp.data.msg);
        }
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  };
  const handlePrint = () => {
    if (divRef.current) {
      const printContent = divRef.current.innerHTML;
      const newWindow = window.open("", "", "height=600,width=800");
      newWindow.document.write("<html><head><title>Print</title>");
      newWindow.document.write(
        '<link rel="stylesheet" type="text/css" href="./event.css" />'
      ); // Adjust this path to your CSS file
      newWindow.document.write("</head><body >");
      newWindow.document.write(printContent);
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.print();
    }
  };
  return (
    <>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <section
            className="headings about-page-heading"
            title="divine-bricks-about-us"
            alt="divine-bricks-about-us"
            style={{
              background: "url('/join-dubai-event.jpg') center top no-repeat",
              backgroundSize: "100%",
              height: "220px",
            }}
          ></section>
          <section className="row">
            <div className="book col-8" ref={divRef}>
              <div
                className="page"
                style={{
                  width: "105mm",
                  minHeight: "148mm",
                  margin: "10mm auto",
                  border: "1px #D3D3D3 solid",
                  borderRadius: "5px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#d2a679",
                }}
              >
                <div
                  style={{
                    padding: "0.5rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "3rem",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ padding: "1rem", width: "100%" }}>
                      <div
                        id="invite"
                        style={{
                          fontSize: "3.75rem",
                          lineHeight: "1",
                          textAlign: "center",
                        }}
                      >
                        You're Invited!
                      </div>
                      <div
                        style={{
                          marginTop: "1rem",
                          fontSize: "1.875rem",
                          lineHeight: "2.25rem",
                          textAlign: "center",
                        }}
                        id="names"
                      >
                        to GALA Event of
                        <br />
                        Divine Bricks{" "}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <hr
                      style={{
                        position: "relative",
                        borderTopWidth: "4px",
                        borderColor: "#000000",
                        borderStyle: "double",
                      }}
                    />

                    <div
                      style={{
                        marginTop: "1rem",
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                        textAlign: "center",
                      }}
                    >
                      <strong>
                        {eventData?.image != "/images/abc.png" ? (
                          <>
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                              }}
                              src={
                                "https://api.divinebricks.com/" +
                                eventData?.image
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        <br /> Token No: {eventData?.qrCodeImage}
                        <br />
                        Name : {eventData?.name} <br />
                        Mobile No. : {eventData?.mobile_no} <br />
                      </strong>
                      <p></p>

                      <hr
                        style={{
                          position: "relative",
                          marginTop: "1rem",
                          borderTopWidth: "4px",
                          borderColor: "#000000",
                          borderStyle: "double",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: ".5rem",
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                        width: "100%",
                      }}
                    >
                      <em>
                        <strong>Date:</strong> July 08, 2024
                        <br />
                        <strong>Time:</strong> 07:30 PM onwards
                        <br />
                        <strong>Location:</strong> SHANGRI-LA-DUBAI
                      </em>
                    </div>
                  </div>
                  <hr
                    style={{
                      position: "relative",
                      marginTop: "1rem",
                      borderTopWidth: "4px",
                      borderColor: "#000000",
                      borderStyle: "double",
                    }}
                  />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: ".75rem",
                        fontSize: "0.9rem",
                        lineHeight: "1.75rem",
                        width: "100%",
                      }}
                    >
                      <em>
                        <strong>divinebricksofficial@gmail.com | </strong>
                        <strong>www.divinebricks.com</strong>
                      </em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="book col-2">
              <button
                style={{
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginTop: "2.5rem",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "1.25rem",
                  lineHeight: "1.75rem",
                  borderRadius: "1rem",
                }}
                onClick={(e) => handlePrint()}
              >
                Download PDF
              </button>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default JoinEvent;

import { React, useState } from "react";

const initialInput = {
    currentSavings: 10000,
    yearlyContribution: 1200,
    expectedReturn: 7,
    duration: 10
};

const InvestmentFormInput = (props) => {
    const [userInput, setUserInput] = useState(initialInput);

    const currentSavingsHandler = (event) => {
        setUserInput((prev) => {
            return { ...prev, currentSavings: +event.target.value };
        });
    };
    const yearlyContributionHandler = (event) => {
        setUserInput((prev) => {
            return { ...prev, yearlyContribution: +event.target.value };
        });
    };
    const expectedReturnHandler = (event) => {
        setUserInput((prev) => {
            return { ...prev, expectedReturn: +event.target.value };
        });
    };
    const durationHandler = (event) => {
        setUserInput((prev) => {
            return { ...prev, duration: +event.target.value };
        });
    };

    const resetHandler = () => {
        setUserInput(initialInput);
        props.onReset();
    };
    const submitHandler = (event) => {
        event.preventDefault();
        props.onSubmit(userInput);
    };

    return (
        <div>
            <form className="investment-calc">
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <label>Current Savings ($)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="current-savings"
                            value={userInput.currentSavings}
                            onChange={currentSavingsHandler}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <label>Yearly Savings ($)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="yearly-contribution"
                            value={userInput.yearlyContribution}
                            onChange={yearlyContributionHandler}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <label>Expected Interest (%, per year)</label>
                        <input
                            type="number"
                            id="expected-return"
                            className="form-control"
                            value={userInput.expectedReturn}
                            onChange={expectedReturnHandler}
                        />
                    </div>
                    <div className="col-md-6">
                        <label>Investment Duration (years)</label>
                        <input
                            type="number"
                            id="duration"
                            className="form-control"
                            value={userInput.duration}
                            onChange={durationHandler}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <button
                            type="reset"
                            className="btn btn-danger"
                            onClick={resetHandler}
                        >
                            Reset
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3"
                            onClick={submitHandler}
                        >
                            Calculate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default InvestmentFormInput

import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from "../axiosInstance";
import EditIcon from "@mui/icons-material/Edit";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

const AgentsPropertiesList = () => {
  const [remarks, setRemarks] = useState();
  const [status, setStatus] = useState();
  const [propertyId, setPropertyId] = useState();
  const [visible, setVisible] = useState(false);

  const token = sessionStorage.getItem("token");
  const updateProperty = () => {
    //setVisible(true);
    axios
      .post(
        `api/admins/update-agent-property-status`,
        {
          propertyId: propertyId.toString(),
          is_status: status,
          remark: remarks,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        if (resp.data.success) {
          toast.success(resp.data.msg, { toastId: "su12" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
        }
        if (!resp.data.success) {
          toast.error(resp.data.msg, { toastId: "er12" });
        }
        setVisible(false);
        setData(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
        setVisible(false);
      });
  };

  const columns = [
    {
      name: <div style={{ fontWeight: "800" }}>Agent ID</div>,
      selector: (row) => row.agentData.id,
      width: "70px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Agent Name</div>,
      selector: (row) => row.agentData.name,
    },
    {
      name: <div style={{ fontWeight: "800" }}>Property Name</div>,
      selector: (row) => row.name,
      width: "140px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Builder Name</div>,
      selector: (row) => row.builderData.name,
      width: "100px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Area</div>,
      selector: (row) => row.max_area,
    },
    {
      name: <div style={{ fontWeight: "800" }}>Address</div>,
      selector: (row) => row.address,
    },
    {
      name: <div style={{ fontWeight: "800" }}>Price</div>,
      selector: (row) => "AED " + row.price,
      width: "160px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Image</div>,
      selector: (row) => (
        <div>
          <a
            href={"https://api.divinebricks.com" + row.banner}
            download="custom.jpg"
            target="_blank"
          >
            <img
              src={"https://api.divinebricks.com" + row.banner}
              style={{ maxWidth: "200px", maxHeight: "50px" }}
            />
          </a>
        </div>
      ),
    },
    {
      name: <div style={{ fontWeight: "800" }}>Date</div>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      width: "120px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Action</div>,
      selector: (row) => (
        <div>
          <EditIcon
            style={{ width: "0.8em", cursor: "pointer" }}
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => setPropertyId(row.id)}
          />
        </div>
      ),
      width: "160px",
    },
    // <select><option selected disabled>Select Status</option><option value='approve'>Approve</option><option value='pending'>Pending</option><option value='reject'>Reject</option></select>
  ];
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="my-3 expandable-data">
        <div className="row admin-property-add">
          <div className="col-md-4 mb-4">
            <label>Property Image</label>
            <br />
            <a
              target="_blank"
              href={"https://api.divinebricks.com" + data?.image}
            >
              <img
                src={"https://api.divinebricks.com" + data?.image}
                style={{ width: "100px", height: "100px" }}
              />
            </a>
          </div>
          <div className="col-md-4 mb-4">
            <label>Property Banner Image</label>
            <br />
            <a
              target="_blank"
              href={"https://api.divinebricks.com" + data?.banner}
            >
              <img
                src={"https://api.divinebricks.com" + data?.banner}
                style={{ width: "100px", height: "100px" }}
              />
            </a>
          </div>
          <div className="col-md-4 mb-4">
            <label>Gallery Images</label>
            <br />
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                {data?.galleryData?.map((data1, key) => {
                  return (
                    <div
                      class={
                        key == 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      <img
                        src={"https://api.divinebricks.com" + data1?.img_url}
                        alt=""
                        style={{ width: "100%", height: "100px" }}
                      />
                    </div>
                  );
                })}
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <label>Floor Plans Images</label>
            <br />
            <div
              id="carouselExampleControls1"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                {data?.floorData?.map((data1, key) => {
                  return (
                    <div
                      class={
                        key == 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      <img
                        src={"https://api.divinebricks.com" + data1?.img_url}
                        alt=""
                        style={{ width: "100%", height: "100px" }}
                      />
                    </div>
                  );
                })}
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls1"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls1"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <label>Amenities</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Property Size"
              value={data?.ameties}
              readOnly
            />
          </div>
          <div className="col-md-4 mb-4">
            <label>Category</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Property Size"
              value={data?.category}
              readOnly
            />
          </div>
          <div className="col-md-4 mb-4">
            <label>No of Bedrooms</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Bathrooms"
              value={data?.min_beds}
              readOnly
            />
          </div>
          <div className="col-md-4 mb-4">
            <label>No of Bathrooms</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Bathrooms"
              value={data?.min_bath}
              readOnly
            />
          </div>
          <div className="col-md-4 mb-4">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Property Size"
              value={data?.address}
              readOnly
            />
          </div>
          <div className="col-md-4 mb-4">
            <label>City</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Property Size"
              value={data?.city}
              readOnly
            />
          </div>
          <div className="col-md-4 mb-4">
            <label>State</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Property Size"
              value={data?.state}
              readOnly
            />
          </div>
          <div className="col-md-12 mb-4" style={{ textAlign: "left" }}>
            <label>Property Details</label>
            <p
              type="text"
              className="form-control"
              placeholder="Enter Description"
              dangerouslySetInnerHTML={{ __html: data?.pdetails }}
              readOnly
            ></p>
          </div>
        </div>
      </div>
    );
  };
  const [data, setData] = useState();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    axios
      .get(`api/admins/get-agent-property-list?agentProperty=1`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function Capitalise(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
  return (
    <>
      <UserHeader />
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Agents Properties List
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Property Status
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body add-property-modal">
              <div className="row admin-property-add">
                <div className="col-md-12 mb-4">
                  <label>Enter Note</label>
                  <textarea
                    rows={5}
                    style={{ width: "100%" }}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  ></textarea>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Enter Popular Property Section</label>
                  <select
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option disabled selected>
                      Select Status
                    </option>
                    <option value="active">Approve</option>
                    <option value="rejected">Reject</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateProperty}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AgentsPropertiesList;

import InvestmentFormInput from "./InvestmentFormInput";


const InvestmentForm = (props) => {
  const submitHandler = (userInput) => {
    props.onCalculate(userInput);
  };
  const resetHandler = () => {
    props.onReset();
  };
  return (
    <div>
      <InvestmentFormInput onSubmit={submitHandler} onReset={resetHandler} />
    </div>
  );
};

export default InvestmentForm;

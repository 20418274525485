import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";
import { Link } from "react-router-dom";
import beds from "../image/beds.svg";
import bath from "../image/bath.svg";
import sqft from "../image/sqft.svg";
import wishlist from "../image/wishlist.png";
import address from "../image/address.png";
import axios from "../axiosInstance.js";

const FavouriteProperties = () => {
    var filteredData
    const [propertiesList, setPropertiesList] = useState()

    useEffect(() => {
        const favArr = localStorage.getItem("favourite_properties")
        axios
            .get(
                `api/users/get-home-property-list`
            )
            .then((resp) => {
                setPropertiesList(resp.data?.result?.propertyList)
                const arr1 = resp.data?.result?.propertyList
                setPropertiesList(arr1.filter(item => favArr.includes(item.id)))
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])
  return (
    <>
      <TopHeader />
      <HomePageHeader />
      <section style={{ padding: "40px" }}>
        <div className="container">
          <Tabs>
            <TabList>
              <Tab>Favourite Properties</Tab>
              <Tab>Saved Properties</Tab>
            </TabList>

            <TabPanel>
              <h4>Favourite Properties</h4>
              <div className="row mt-4">
                {propertiesList?.map((data) => {
                    return (
                        <div className="col-md-3 mb-4">
                            <div
                                className="card carousel__item"
                                style={{
                                display: "flex",
                                padding: "0",
                                textAlign: "left",
                                }}
                            >
                                <div className="position-relative properties-dubai-div">
                                <span>Rent</span>
                                <img src={wishlist} />
                                <img
                                    src={"https://api.divinebricks.com" + data?.image}
                                    style={{
                                    width: "100%",
                                    height: "140px",
                                    borderRadius: "2px",
                                    }}
                                />
                                </div>
                                <div style={{ width: "100%", padding: "10px" }}>
                                <p
                                    style={{
                                    marginBottom: "0",
                                    color: "#5C5959",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textAlign: "left",
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    }}
                                >
                                    {data?.name}
                                </p>
                                <p
                                    style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#5C5959",
                                    fontWeight: "400",
                                    fontSize: "10px",
                                    marginBottom: "0",
                                    marginTop: "-5px",
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    }}
                                >
                                    <img src={address} style={{ marginRight: "5px" }} />
                                    {data?.address}
                                </p>
                                <div className="d-flex dubai-property-buttons">
                                    <span>
                                    <img src={beds} style={{ width: "12px" }} />{data?.min_beds} Beds
                                    </span>
                                    <span>
                                    <img src={bath} style={{ width: "12px" }} />{data?.min_bath} Bath
                                    </span>
                                    <span>
                                    <img src={sqft} style={{ width: "12px" }} />
                                    {data?.max_area} Sqft
                                    </span>
                                </div>
                                <p
                                    style={{
                                    textAlign: "left",
                                    marginTop: "10px",
                                    color: "#8F5726",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    marginBottom: "5px",
                                    }}
                                >
                                    AED {parseInt(data?.price).toLocaleString()}
                                </p>
                                <button
                                    className="cta"
                                    style={{
                                    border: "none",
                                    background: "transparent",
                                    padding: "4px 10px",
                                    }}
                                >
                                How is the testing main part of development?
                                Tester can find bugs and errors through testing which are left by developers.
                                A tester can suggest an addition in feature.
                                He/she will help improve UX.
                                    Having tester in team will improve developers performance in terms of on-time product delivery and save time to check the errors by itself.
                                    Lastly client don't have to cross check if there is any error in the feature or not
                                    <Link
                                      to={"/divine-bricks-properties/"+data?.slug}
                                      title={data?.name}
                                      style={{ color: "black", textDecoration: "none" }}
                                    >
                                    <span>View Details &rarr;</span>
                                    </Link>
                                </button>
                                </div>
                            </div>
                        </div>
                    )
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <h4>Saved Seaches</h4>
            </TabPanel>
          </Tabs>
        </div>
      </section>
    </>
  );
};
export default FavouriteProperties;

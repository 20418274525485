import React from "react";
import TopHeader from "./TopHeader";
import HomePageHeader from "./HomePageHeader";
import HomePageSearch from "./HomePageSearch";
import BuySell from "./BuySell";
import NewProperties from "./NewProperties";
import HomePageAboutUs from "./HomePageAboutUs";
import HomePagePopularProperties from "./HomePagePopularProperties";
import HomePageBanner from "./HomePageBanner";
import HomePageBuilder from "./HomePageBuilders";
import HomePostProperty from "./HomePostProperty";
import HomePageBenefits from "./HomePageBenefits";
import HomeArticles from "./HomeArticles";
import HomeSubscribe from "./HomeSubscribe";
import HomeFooter from "./HomeFooter";
import HomePagePropertyType from "./HomePagePropertyType";
import HomeBanner3 from "./HomeBanner3";

const HomePage = () => {
  return (
    <>
      <TopHeader />
      <HomePageHeader />
      <HomePageSearch />
      <BuySell />
      <NewProperties />
      <HomePageAboutUs />
      <HomePagePopularProperties />
      <HomePageBanner />
      <HomePageBuilder />
      <HomePagePropertyType />
      <HomeBanner3 />
      <HomePostProperty />
      <HomePageBenefits />
      <HomeArticles recrodsCount={3} />
      <HomeSubscribe />
      <HomeFooter />
    </>
  );
};
export default HomePage;

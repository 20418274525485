import React, { useState } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import eyes from '../image/eyes.png'
import logo from '../image/logo-divine.png'

const Login = () => {
    const [name, setName] = useState()
    const [username, setUserName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [referral, setReferral] = useState()
    const [visible, setVisible] = useState(false)
    const [otp, setOtp] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()
    const login = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/login`,
                {
                    username: username,
                    password: password,
                    isAdmin: '1'
                },
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's1' })
                    setTimeout(() => {
                        if(resp.data?.result?.isAdmin == 0) {
                            navigate('/user-dashboard')
                        }
                        else {
                            navigate('/admin-dashboard')
                        }
                    }, 2000);
                    sessionStorage.setItem("token", resp.data?.token)
                    sessionStorage.setItem("isAdmin", resp.data?.result?.isAdmin)
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }
    const handleShow = () => {
        setShowPassword(!showPassword)
    }
    return (
        <>
            {visible ? <div className="screen-overlay"></div> : ''}
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <div className="gradient-form" style={{ overflow: 'hidden' }}>

                <div className='row'>

                    <div className="col-md-6">
                        <div className="d-flex flex-column container ms-5">

                            <div className="text-center mb-5 pt-4">
                                <Link to='/'><img src={logo}
                                    style={{ width: '185px' }} alt="logo" /></Link>
                            </div>

                            <p style={{ fontSize: '20px', color: 'black' }}>Please login to your account</p>

                            <form>
                                <input className='form-control mb-4' placeholder='Email Id' id='form1' type='email' value={username} onChange={(e) => setUserName(e.target.value)} />
                                <div className='position-relative'>
                                    <input className='form-control mb-4' placeholder='Password' id='form2' type={showPassword? 'text': 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <img className='password-eyes' src={eyes} onClick={handleShow} />
                                </div>


                                <div className="text-center pt-1 mb-5 pb-1">
                                    <button className="mb-4 w-100 gradient-custom-2" style={{ color: 'white', fontSize: '20px', background: 'linear-gradient(90deg, rgba(192, 127, 0, 1) 0%, rgba(203, 170, 92, 1) 56%)' }} onClick={login}>Sign in</button>
                                    <a className="text-muted" href="#!">Forgot password?</a>
                                </div>
                            </form>
                            <div className="d-flex flex-row align-items-center justify-content-center pb-5 mb-5">
                                <p className="mb-0">Don't have an account?</p>
                                <Link to='/register'><button className='btn btn-danger mx-2'>
                                    Register
                                </button></Link>
                            </div>

                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="d-flex flex-column  justify-content-center gradient-custom-2 mb-4" style={{height: '100vh', background: 'linear-gradient(90deg, rgba(192, 127, 0, 1) 0%, rgba(203, 170, 92, 1) 56%)'}}>

                            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                                <h4 class="mb-4">We are more than just a company</h4>
                                <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}
export default Login
import React, { useState, useEffect } from "react";
import axios from "../axiosInstance";
import UserHeader from "./UserHeader";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PropertyDetails = () => {
  const [data, setData] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    const token = sessionStorage.getItem("token");
    axios
      .post(
        `api/users/get-property-details`,
        { propertyId: sessionStorage.getItem("propertyId") },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setShow(false);
        setData(resp.data.result.propertyDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const wrapper = document.querySelector("#wrapper");
  const carousel = document.querySelector("#image-carousel");
  const images = document.querySelectorAll("img");
  const btn = document.querySelectorAll("button");
  const previous = document.querySelector("#prev");
  const nxt = document.querySelector("#next");

  images.forEach((slide, index) => {
    slide.style.left = `${index * 100}%`;
  });
  let counter = 0;

  const slideImage = () => {
    images.forEach((e) => {
      e.style.transform = `translateX(-${counter * 100}%)`;
    });
  };
  const prev = () => {
    if (counter > 0) {
      counter--;
      slideImage();
    }
  };
  const next = () => {
    if (counter <= images.length - 2) {
      counter++;
      slideImage();
    }
  };

  return (
    <>
      <UserHeader />
      <div className="position-relative">
        {show ? (
          <Skeleton count="1" height="400px" />
        ) : (
          <img
            style={{ height: "70vh" }}
            class="d-block w-100"
            src={"https://api.divinebricks.com" + data?.banner}
            alt="Property Banner"
          />
        )}
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          style={{ position: "absolute", left: "20px", bottom: "20px" }}
        >
          All Photos
        </button>
      </div>
      <div className="container" style={{ marginBottom: "50px" }}>
        <div className="row pt-4">
          <div className="col-md-7">
            <div className="d-flex">
              <h2>{data?.name}</h2>
              <span className="badge-span">{data?.category}</span>
            </div>
            <h6 style={{ textAlign: "left" }}>{data?.address}</h6>
            <p style={{ textAlign: "left", marginTop: "25px" }}>
              {data?.pdetails}
            </p>
          </div>
          <div className="col-md-5 p-5">
            <div
              className="card property-details-card"
              style={{ padding: "20px 40px" }}
            >
              <h5 style={{ textAlign: "left" }}>
                <span class="badge badge-success">Available Now</span>
              </h5>
              <h2 style={{ textAlign: "left" }}>
                $ {parseInt(data?.price).toLocaleString()}
              </h2>
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-md-6">
                  <p
                    style={{ color: "#CBAA5C", fontWeight: "600" }}
                    className="mb-0"
                  >
                    Square Feet
                  </p>
                  <h6>{data?.min_area}</h6>
                </div>
                <div className="col-md-6">
                  <p
                    style={{ color: "#CBAA5C", fontWeight: "600" }}
                    className="mb-0"
                  >
                    Bedrooms
                  </p>
                  <h6>{data?.min_beds}</h6>
                </div>
              </div>
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-md-6">
                  <p
                    style={{ color: "#CBAA5C", fontWeight: "600" }}
                    className="mb-0"
                  >
                    Bathrooms
                  </p>
                  <h6>{data?.address}</h6>
                </div>
                <div className="col-md-6">
                  <p
                    style={{ color: "#CBAA5C", fontWeight: "600" }}
                    className="mb-0"
                  >
                    Location
                  </p>
                  <h6>{data?.min_bath}</h6>
                </div>
              </div>
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-md-6">
                  <p
                    style={{ color: "#CBAA5C", fontWeight: "600" }}
                    className="mb-0"
                  >
                    Property Type
                  </p>
                  <h6>{data?.typeArr}</h6>
                </div>
                <div className="col-md-6">
                  <p
                    style={{ color: "#CBAA5C", fontWeight: "600" }}
                    className="mb-0"
                  >
                    Parking
                  </p>
                  <h6>{data?.parking}</h6>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <Link to="/payment">
                    <button
                      className="btn w-100 mt-4"
                      style={{ backgroundColor: "#315745", color: "white" }}
                      disabled={data?.is_status != "active" ? true : false}
                    >
                      {data?.is_status == "active" ? "Booking" : "Booked"}{" "}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p style={{ textAlign: "left", marginTop: "20px" }}>
          <button
            class="btn btn-primary"
            type="button"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Floor Plans
          </button>
        </p>
        <div class="collapse show" id="collapseExample">
          <div class="card card-body">
            <div className="row">
              {data?.floorData?.map((data1) => {
                return (
                  <div className="col-md-6">
                    <a
                      target="_blank"
                      href={"https://api.divinebricks.com" + data1.img_url}
                      data-lightbox="roadtrip"
                      style={{ flex: "0.5", textAlign: "center" }}
                    >
                      <img
                        class="img-fluid"
                        src={"https://api.divinebricks.com" + data1.img_url}
                        alt="image"
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  {data?.galleryData?.map((data1, key) => {
                    return (
                      <div
                        class={
                          key == 0 ? "carousel-item active" : "carousel-item"
                        }
                      >
                        <img
                          src={"https://api.divinebricks.com" + data1?.img_url}
                          alt=""
                          style={{
                            width: "100%",
                            maxHeight: "500px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleControls"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleControls"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetails;

import React, { useEffect, useState } from "react";
import logo from "../image/logo-divine.png";
import { Link, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "../axiosInstance";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropertyGrid from "./PropertyGrid";
import Carousel from "react-multi-carousel";
import banner1 from "../image/damac.webp";
import banner2 from "../image/shobha__ad.webp";
import bookingImg from "../image/booking-img.webp";
import constructionImg from "../image/construction-img.webp";
import handoverImg from "../image/handover-img.webp";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";
import HomeFooter from "./HomeFooter";
import { Helmet } from "react-helmet";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const HomePropertyDetails = () => {
  const location = useLocation();

  const [data, setData] = useState();
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [captcha, setCaptcha] = useState(false);
  const [show, setShow] = useState(false);
  const [similarProperties, setSimilarProperties] = useState();
  const [randomImage, setRandomImage] = useState();
  const [websiteData, setWebsiteData] = useState();
  const [amenitiesList, setAmenitiesList] = useState();
  const imageUrls = [banner1, banner2];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    setRandomImage(imageUrls[randomIndex]);
  };

  const query = new URLSearchParams(window.location.search);
  // const id = query.get('id')
  const id = window.location.pathname.split("/").pop();

  useEffect(() => {
    setShow(true);
    axios
      .post(`api/users/get-home-property-detail`, {
        propertyId: id,
      })
      .then((resp) => {
        setShow(false);
        setData(resp.data.result.propertyDetails);
        setAmenitiesList(resp.data.result.amenitiesArr);
        axios
          .get(
            `api/users/get-home-property-list?builderId=${resp.data.result.propertyDetails.builderId}&propertyId=${resp.data.result.propertyDetails.id}`
          )
          .then((resp) => {
            setSimilarProperties(resp.data.result?.propertyList);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    getRandomImage();
    axios
      .get(`api/users/get-settings`)
      .then((resp) => {
        setWebsiteData(resp.data.result.settingData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  function submitForm() {
    if (!captcha) {
      alert("Please enter captcha");
      return;
    }
    axios
      .post(`api/users/save-inquiry-from`, {
        name: name,
        mobile_no: mobile,
        email: email,
        message: message,
      })
      .then((resp) => {
        if (!resp.data.success) {
          toast.error(resp.data.msg, { toastId: "e1" });
        }
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "s1" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function clickCaptcha(value) {
    setCaptcha(true);
  }
  return (
    <>
      <Helmet>
        <title>
          {data?.seo_title != null ? data?.seo_title : "Divine Bricks"}
        </title>
        <meta
          name="keywords"
          content={
            data?.seo_keywords != null ? data?.seo_keywords : "Divine Bricks"
          }
        />
        <meta
          name="description"
          content={
            data?.seo_description != null
              ? data?.seo_description
              : "Divine Bricks"
          }
        />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={data?.seo_title != null ? data?.seo_title : "Divine Bricks"}
        />
        <meta
          property="og:description"
          content={
            data?.seo_description != null
              ? data?.seo_description
              : "Divine Bricks"
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Divine Bricks" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content={
            data?.seo_description != null
              ? data?.seo_description
              : "Divine Bricks"
          }
        />
        <meta
          name="twitter:title"
          content={data?.seo_title != null ? data?.seo_title : "Divine Bricks"}
        />
        <meta name="twitter:site" content={window.location.href} />
        <meta
          name="twitter:image"
          content={
            data?.galleryData?.length > 0
              ? "https://api.divinebricks.com" + data?.galleryData[0]?.img_url
              : null
          }
        />
        <meta name="twitter:creator" content="Divine Bricks" />
      </Helmet>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <div className="clearfix"></div>
          <section
            className="single-proper blog details"
            style={{ paddingBottom: "0", paddingTop: "20px" }}
          >
            <div className="container">
              <h4 className="p-2">
                <Link to="/" style={{ color: "#9fa29b" }}>
                  Divine Bricks /
                </Link>
                <Link
                  to="/divine-bricks-property-listing/"
                  style={{ color: "#9fa29b" }}
                >
                  Divine Bricks Properties /
                </Link>
                <span style={{ color: "black" }}>{data?.name}</span>
              </h4>
            </div>
            <div className="container property-details-page">
              <div className="row">
                <div className="col-lg-8 col-md-12 blog-pots">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        id="carouselExampleControls"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {show ? (
                            <Skeleton count={1} height={300} />
                          ) : (
                            <>
                              {data?.galleryData?.map((data1, key) => {
                                return (
                                  <div
                                    class={
                                      key == 0
                                        ? "carousel-item active"
                                        : "carousel-item"
                                    }
                                  >
                                    <img
                                      src={
                                        "https://api.divinebricks.com" +
                                        data1?.img_url
                                      }
                                      alt={data?.slug}
                                      title={data?.name}
                                      style={{
                                        width: "100%",
                                        maxHeight: "300px",
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleControls"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleControls"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                      <section className="headings-2 pt-4">
                        <div
                          className="pro-wrapper"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="">
                            <div className="">
                              {show ? (
                                <Skeleton count={1} />
                              ) : (
                                <>
                                  <h3>{data?.name}</h3>
                                  <div className="mt-0">
                                    <a
                                      href="#listing-location"
                                      className="listing-address"
                                    >
                                      <i className="fa fa-map-marker pr-2 ti-location-pin mrg-r-5"></i>
                                      {data?.address}, {data?.city},
                                      {data?.state}
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="">
                            <div className="detail-wrapper-body m-for-mobile">
                              <div className="listing-title-bar">
                                <h4 style={{ fontWeight: "500" }}>
                                  AED {parseInt(data?.price).toLocaleString()}
                                </h4>
                                <div className="mt-0">
                                  <a
                                    href="#listing-location"
                                    className="listing-address"
                                  >
                                    <h3>{data?.min_area} sq ft</h3>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div className="blog-info details mb-30 mt-4">
                        <h5 className="mb-4">Description</h5>
                        <p className="mb-3">
                          <b>{data?.short_details}</b>
                        </p>
                        <p
                          className="mb-3"
                          dangerouslySetInnerHTML={{ __html: data?.pdetails }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="single homes-content details mb-30 wprt-image-video w50 pro">
                    <h5 className="mb-4">Property Details</h5>
                    <ul className="homes-list custom-homes-lists clearfix">
                      <li>
                        <span className="font-weight-bold mr-1">
                          Property ID:
                        </span>
                        <span className="det">{data?.id}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">
                          Property Type:
                        </span>
                        <span className="det">{data?.typeArr}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">
                          Property status:
                        </span>
                        <span className="det">For {data?.category}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">
                          Property Price:
                        </span>
                        <span className="det">AED {data?.price}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">Bedrooms:</span>
                        <span className="det">{data?.min_beds}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">Bath:</span>
                        <span className="det">{data?.min_bath}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">Parking:</span>
                        <span className="det">{data?.parking}</span>
                      </li>
                      <li>
                        <span className="font-weight-bold mr-1">
                          Year Built:
                        </span>
                        <span className="det">
                          {moment(data?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {amenitiesList?.length > 0 ? (
                    <>
                      <div className="single homes-content details mb-30 wprt-image-video w50 pro">
                        <h5 className="">Amenities</h5>
                        <ul className="homes-list custom-homes-lists clearfix no-padding">
                          <div
                            className="my-amenities-section"
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {amenitiesList?.map((data) => {
                              return (
                                <div
                                  style={{
                                    border: "1px solid #DDDDDD",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    minWidth: "140px",
                                    margin: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <img
                                      src={
                                        "https://api.divinebricks.com" +
                                        data.image
                                      }
                                      style={{ height: "50px", width: "50px" }}
                                    />
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    {data.name}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="single homes-content details mb-30 wprt-image-video w50 pro d-none">
                    <h5 className="">Payment Plan</h5>
                    <ul className="homes-list custom-homes-lists clearfix no-padding">
                      <div
                        className="my-amenities-second-section"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <div
                          style={{
                            border: "1px solid #DDDDDD",
                            padding: "10px",
                            borderRadius: "8px",
                            minWidth: "140px",
                            margin: "5px",
                            flex: "0.3",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={bookingImg}
                              style={{ height: "50px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{ textAlign: "center", fontWeight: "500" }}
                          >
                            20%
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              background:
                                "linear-gradient(90deg, rgb(192, 127, 0) 0%, rgb(203, 170, 92) 56%)",
                              color: "white",
                              borderRadius: "25px",
                              padding: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Down Payment
                          </div>
                          <div style={{ textAlign: "center" }}>
                            On Booking Date
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #DDDDDD",
                            padding: "10px",
                            borderRadius: "8px",
                            minWidth: "140px",
                            margin: "5px",
                            flex: "0.3",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={constructionImg}
                              style={{ height: "50px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{ textAlign: "center", fontWeight: "500" }}
                          >
                            50%
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              background:
                                "linear-gradient(90deg, rgb(192, 127, 0) 0%, rgb(203, 170, 92) 56%)",
                              color: "white",
                              borderRadius: "25px",
                              padding: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            During Construction
                          </div>
                          <div style={{ textAlign: "center" }}>
                            1st - 37th Installment
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #DDDDDD",
                            padding: "10px",
                            borderRadius: "8px",
                            minWidth: "140px",
                            margin: "5px",
                            flex: "0.3",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={handoverImg}
                              style={{ height: "50px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{ textAlign: "center", fontWeight: "500" }}
                          >
                            30%
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              background:
                                "linear-gradient(90deg, rgb(192, 127, 0) 0%, rgb(203, 170, 92) 56%)",
                              color: "white",
                              borderRadius: "25px",
                              padding: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            During Handover
                          </div>
                          <div style={{ textAlign: "center" }}>
                            100% Completion
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                  {data?.floorData?.length > 0 ? (
                    <>
                      <div className="floor-plan property wprt-image-video w50 pro">
                        <h5>Floor Plans</h5>
                        <div
                          id="carouselExampleControls1"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            {data?.floorData?.length > 0
                              ? data?.floorData.map((data1, key) => {
                                  return (
                                    <div
                                      class={
                                        key == 0
                                          ? "carousel-item active"
                                          : "carousel-item"
                                      }
                                    >
                                      <img
                                        src={
                                          "https://api.divinebricks.com" +
                                          data1.img_url
                                        }
                                        alt=""
                                        style={{
                                          width: "100%",
                                          maxHeight: "300px",
                                        }}
                                      />
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls1"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls1"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {data?.video_link !== null ? (
                    <div className="property wprt-image-video w50 pro">
                      <h5>Property Video</h5>
                      <a target="_blank" href={data?.video_link}>
                        <i className="fa fa-play"></i>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <aside className="col-lg-4 col-md-12 car">
                  <div className="single widget">
                    <div className="sidebar">
                      <div className="widget-boxed mt-33">
                        <div className="widget-boxed-header">
                          <h4>Builder Information</h4>
                        </div>
                        <div className="widget-boxed-body">
                          <div className="sidebar-widget author-widget2">
                            <div className="author-box clearfix">
                              <img
                                src={
                                  "https://api.divinebricks.com" +
                                  data?.builderData?.image
                                }
                                alt={data?.builderData?.slug + "-logo"}
                                className="author__img"
                              />
                              <h4 className="author__title">
                                {data?.builderData?.name}
                              </h4>
                            </div>
                            <ul className="author__contact side-details">
                              <li>
                                <span className="la la-map-marker">
                                  <i className="fa fa-map-marker"></i>
                                </span>
                                <div>
                                  <p style={{ marginBottom: "0" }}>
                                    <strong>Dubai:</strong>
                                    {websiteData?.d_address}
                                  </p>
                                  {/* {websiteData?.i_address != null ? (
                                    <p style={{ marginBottom: "0" }}>
                                      <strong>Dubai:</strong>
                                      {websiteData?.i_address}
                                    </p>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                              </li>
                              <li
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="la la-phone">
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <a href={"telto:" + websiteData?.mobile_no}>
                                  {websiteData?.mobile_no}
                                </a>
                              </li>
                              <li
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="la la-envelope-o">
                                  <i
                                    className="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <a href={"mailto:" + websiteData?.email}>
                                  {websiteData?.email}
                                </a>
                              </li>
                            </ul>
                            <div className="agent-contact-form-sidebar">
                              <h4>Request Inquiry</h4>
                              <div
                                className="enquiry-parmeteres d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div>
                                  <a
                                    target="_blank"
                                    href="tel:+971 54 320 2567"
                                    style={{ color: "white" }}
                                  >
                                    <i
                                      className="fa fa-phone"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    <span className="for-desktop">Call</span>
                                  </a>
                                </div>
                                <div>
                                  <a
                                    target="_blank"
                                    href={"mailto:" + websiteData?.email}
                                    style={{ color: "white" }}
                                  >
                                    <i
                                      className="fa fa-envelope"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    <span className="for-desktop">Email</span>
                                  </a>
                                </div>
                                <div>
                                  <a
                                    target="_blank"
                                    href="https://api.whatsapp.com/send/?phone=+971 54 320 2567&text==Hello I want enquiry about this property!"
                                    style={{ color: "white" }}
                                  >
                                    <i
                                      className="fa fa-whatsapp"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    <span className="for-desktop">
                                      Whatsapp
                                    </span>
                                  </a>
                                </div>
                              </div>
                              <form>
                                <input
                                  type="text"
                                  id="fname"
                                  name="full_name"
                                  placeholder="Full Name"
                                  required=""
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                  type="number"
                                  id="pnumber"
                                  name="phone_number"
                                  placeholder="Phone Number"
                                  required=""
                                  value={mobile}
                                  onChange={(e) => setMobile(e.target.value)}
                                />
                                <input
                                  type="email"
                                  id="emailid"
                                  name="email_address"
                                  placeholder="Email Address"
                                  required=""
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                <textarea
                                  placeholder="Message"
                                  name="message"
                                  required=""
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <div className="react-captcha">
                                  <ReCAPTCHA
                                    sitekey="6LeeaDgpAAAAACqZBp8p_SmksS740mtQujApUgsh"
                                    onChange={clickCaptcha}
                                  />
                                </div>
                                <input
                                  name="sendmessage"
                                  className="multiple-send-message"
                                  value="Submit Request"
                                  onClick={submitForm}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        src={randomImage}
                        style={{ marginTop: "20px", borderRadius: "4px" }}
                      />
                      <h4 className="mt-4">Useful Links</h4>
                      <div className="property-tags">
                        <h4>Properties in Dubai</h4>
                        <h4>Properties by DAMAC</h4>
                        <h4>Properties by SOBHA</h4>
                        <h4>Properties by EMAAR</h4>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
            <div>
              <section className="featured portfolio home18 bg-white-3">
                <div className="container-fluid">
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="section-title ml-3">
                      <h3>Similar</h3>
                      <h2>Properties</h2>
                    </div>
                  </div>
                  <Carousel responsive={responsive} infinite={true}>
                    {similarProperties ? (
                      similarProperties?.map((data) => {
                        return <PropertyGrid data={data} />;
                      })
                    ) : (
                      <div></div>
                    )}
                  </Carousel>
                </div>
              </section>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default HomePropertyDetails;

import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import address from "../image/address.png";
import axios from "../axiosInstance.js";
import beds from "../image/beds.svg";
import bath from "../image/bath.svg";
import sqft from "../image/sqft.svg";
import { Link } from "react-router-dom";

const NewProperties = () => {
  const [newPropertiesList, setNewPropertiesList] = useState();
  useEffect(() => {
    axios
      .get(`api/users/get-home-property-list?recent_property=Yes`)
      .then((resp) => {
        setNewPropertiesList(resp.data.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section
        className="new-properties-section"
        style={{ background: "#F0F0F0", padding: "40px" }}
      >
        <div className="container">
          <div
            style={{
              borderRadius: "12px",
              background: "#fff",
              border: "1.5px solid rgba(92, 89, 89, 0.20)",
              padding: "20px",
            }}
            className="new-properties-panel"
          >
            <p
              style={{
                color: "#5C5959",
                fontSize: "28px",
                marginBottom: "5px",
                paddingTop: "20px",
              }}
            >
              Newly Launched Properties
            </p>
            <p style={{ color: "#8F5726", fontSize: "14px" }}>
              Just now launched in the market
            </p>

            {newPropertiesList ? (
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className="carousel-item-padding-40-px"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 3,
                  },
                  mobile: {
                    breakpoint: {
                      max: 767,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 768,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {newPropertiesList &&
                  newPropertiesList?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="card carousel__item"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <img
                          src={"https://api.divinebricks.com" + data?.image}
                          className="carousel__item_img"
                          alt={data?.slug}
                          title={data?.name}
                          style={{
                            borderRadius: "50%",
                            maxWidth: "140px",
                            maxHeight: "140px",
                            borderRight: "1px dotted #D9D9D9",
                            padding: "0 5px",
                            objectFit: "cover",
                          }}
                        />
                        <div style={{ width: "100%", padding: "10px" }}>
                          <p
                            style={{
                              marginBottom: "0",
                              color: "#5C5959",
                              fontSize: "14px",
                              fontWeight: "600",
                              textAlign: "left",
                              width: "150px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {data?.name}
                          </p>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#5C5959",
                              fontWeight: "400",
                              fontSize: "10px",
                              marginBottom: "0",
                              marginTop: "-5px",
                              width: "150px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            <img src={address} style={{ marginRight: "5px" }} />
                            {data?.address}
                          </p>
                          <div className="d-flex dubai-property-buttons mb-1">
                            <span>
                              <img src={beds} style={{ width: "12px" }} />
                              {data?.min_beds} Beds
                            </span>
                            <span>
                              <img src={bath} style={{ width: "12px" }} />
                              {data?.min_bath} Bath
                            </span>
                            <span>
                              <img src={sqft} style={{ width: "12px" }} />
                              {data?.max_area} Sqft
                            </span>
                          </div>
                          <p
                            style={{
                              textAlign: "left",
                              marginTop: "0px",
                              color: "#8F5726",
                              fontSize: "14px",
                              fontWeight: "700",
                            }}
                          >
                            AED {parseInt(data?.price).toLocaleString()}
                          </p>
                          <div className="d-flex new-launch-buttons mb-3">
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 7 5"
                                fill="none"
                              >
                                <path
                                  d="M5.73436 4.93566C5.13691 4.93566 4.54548 4.82664 3.96008 4.60861C3.37467 4.39057 2.84289 4.07967 2.36474 3.6759C1.88678 3.27213 1.51875 2.82395 1.26065 2.33135C1.00255 1.83875 0.873505 1.33808 0.873505 0.829326V0.574951H2.56548L2.83075 1.79232L2.01344 2.48882C2.11859 2.64629 2.23569 2.79568 2.36474 2.937C2.49379 3.07832 2.6324 3.20955 2.78057 3.33068C2.91917 3.44777 3.07098 3.55986 3.23597 3.66693C3.40096 3.77401 3.57895 3.87391 3.76994 3.96661L4.6016 3.26405L6.03548 3.51237V4.93566H5.73436Z"
                                  fill="#8F5726"
                                />
                              </svg>
                              Call
                            </button>
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 7 5"
                                fill="none"
                              >
                                <path
                                  d="M6.16916 0.742065H1.24627C1.15301 0.742065 1.06357 0.779113 0.997622 0.845057C0.931678 0.911001 0.89463 1.00044 0.89463 1.0937V4.61005C0.89463 4.70331 0.931678 4.79275 0.997622 4.8587C1.06357 4.92464 1.15301 4.96169 1.24627 4.96169H6.16916C6.26242 4.96169 6.35186 4.92464 6.4178 4.8587C6.48375 4.79275 6.52079 4.70331 6.52079 4.61005V1.0937C6.52079 1.00044 6.48375 0.911001 6.4178 0.845057C6.35186 0.779113 6.26242 0.742065 6.16916 0.742065ZM5.8984 4.61005H1.53812L2.76885 3.33713L2.51567 3.09275L1.24627 4.4061V1.36094L3.43168 3.53581C3.49756 3.6013 3.58668 3.63806 3.67958 3.63806C3.77248 3.63806 3.8616 3.6013 3.92748 3.53581L6.16916 1.30644V4.38325L4.87514 3.08923L4.62724 3.33713L5.8984 4.61005ZM1.47659 1.0937H5.88433L3.67958 3.28615L1.47659 1.0937Z"
                                  fill="#8F5726"
                                />
                              </svg>
                              Email
                            </button>
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 9 9"
                                fill="none"
                              >
                                <path
                                  d="M0.22497 4.3897C0.224774 5.09994 0.410351 5.79343 0.763223 6.40468L0.191223 8.49314L2.32851 7.93274C2.91965 8.25456 3.58199 8.42318 4.25505 8.42322H4.25682C6.47873 8.42322 8.28742 6.61519 8.28837 4.39288C8.2888 3.31602 7.86981 2.3034 7.10856 1.54156C6.34745 0.779794 5.33519 0.36005 4.25666 0.359558C2.03448 0.359558 0.225921 2.16749 0.225003 4.3897"
                                  fill="url(#paint0_linear_86_64)"
                                />
                                <path
                                  d="M0.0810173 4.38814C0.080788 5.12393 0.273016 5.84222 0.63847 6.47536L0.0459595 8.63869L2.25988 8.05821C2.86989 8.3908 3.55669 8.56615 4.25556 8.56641H4.25736C6.55899 8.56641 8.43265 6.69334 8.43363 4.39148C8.43402 3.27592 7.99996 2.22691 7.21152 1.43778C6.42298 0.648747 5.37452 0.213899 4.25736 0.21344C1.95533 0.21344 0.0819347 2.08625 0.0810173 4.38814ZM1.39949 6.36632L1.31682 6.2351C0.969324 5.68256 0.785909 5.04405 0.786171 4.3884C0.786892 2.47513 2.34399 0.918529 4.25867 0.918529C5.1859 0.918922 6.0573 1.28038 6.71272 1.93619C7.3681 2.59207 7.72874 3.46393 7.72851 4.39122C7.72766 6.3045 6.17053 7.86129 4.25736 7.86129H4.25598C3.63303 7.86097 3.02208 7.69367 2.48926 7.37753L2.36246 7.30233L1.04868 7.64679L1.39949 6.36632Z"
                                  fill="url(#paint1_linear_86_64)"
                                />
                                <path
                                  d="M3.21356 2.64318C3.13538 2.46943 3.05311 2.46592 2.97877 2.46288C2.91789 2.46026 2.8483 2.46045 2.77878 2.46045C2.70918 2.46045 2.59611 2.48663 2.50054 2.59099C2.40487 2.69544 2.13528 2.94785 2.13528 3.46124C2.13528 3.97466 2.50922 4.47081 2.56135 4.5405C2.61355 4.61006 3.28325 5.69731 4.3439 6.11558C5.22539 6.46317 5.40477 6.39404 5.59608 6.37661C5.78743 6.35925 6.21349 6.12426 6.30042 5.88059C6.38741 5.63696 6.38741 5.42812 6.36133 5.38447C6.33525 5.341 6.26566 5.31488 6.1613 5.26272C6.05691 5.21053 5.54389 4.95808 5.44825 4.92325C5.35258 4.88845 5.28302 4.87109 5.21343 4.97557C5.14384 5.0799 4.94401 5.31488 4.8831 5.38447C4.82226 5.45423 4.76135 5.46291 4.65702 5.41072C4.5526 5.35836 4.21654 5.2483 3.81783 4.89284C3.50762 4.61625 3.29819 4.27468 3.23731 4.17019C3.17644 4.06587 3.23079 4.00932 3.28312 3.95732C3.33 3.91057 3.3875 3.83547 3.43973 3.77456C3.49176 3.71362 3.50913 3.67014 3.54392 3.60055C3.57875 3.5309 3.56132 3.46995 3.53527 3.41776C3.50913 3.36557 3.30635 2.8495 3.21356 2.64318Z"
                                  fill="white"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_86_64"
                                    x1="405.049"
                                    y1="813.718"
                                    x2="405.049"
                                    y2="0.359558"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#1FAF38" />
                                    <stop offset="1" stopColor="#60D669" />
                                  </linearGradient>
                                  <linearGradient
                                    id="paint1_linear_86_64"
                                    x1="419.43"
                                    y1="842.739"
                                    x2="419.43"
                                    y2="0.21344"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#F9F9F9" />
                                    <stop offset="1" stopColor="white" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </button>
                          </div>
                          <Link
                            to={"/divine-bricks-properties/" + data?.slug}
                            title={data?.name}
                          >
                            <button
                              className="button-hover-style"
                              style={{
                                border: "none",
                                background: "#8F5726",
                                color: "white",
                                fontSize: "10px",
                                borderRadius: "2px",
                                padding: "4px 10px",
                              }}
                            >
                              View Details &rarr;
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </Carousel>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default NewProperties;

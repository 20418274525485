import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from '../axiosInstance'

const columns = [
    {
        name: <div style={{fontWeight: '800'}}>User ID</div>,
        selector:(row) => row.id,
        width: '70px'
    },
    {
        name: <div style={{fontWeight: '800'}}>User Name</div>,
        selector: (row) => row.name,
        width: '160px'
    },
    {
        name: <div style={{fontWeight: '800'}}>Email</div>,
        selector: (row) => row.email,
        width: '200px'
    },
    {
        name: <div style={{fontWeight: '800'}}>Mobile No.</div>,
        selector: (row) => row.mobile_no,
    },
    {
        name: <div style={{fontWeight: '800'}}>Password</div>,
        selector: (row) => row.password,
    },
    {
        name: <div style={{fontWeight: '800'}}>Referral Code</div>,
        selector: (row) => row.referralCode,
    },
    {
        name: <div style={{fontWeight: '800'}}>EMI Dat</div>,
        selector: (row) => row.emi_date,
    },
    {
        name: <div style={{fontWeight: '800'}}>Passport Image</div>,
        selector: (row) => <div><a href={"https://api.divinebricks.com" + row.attachment} download='custom.jpg' target="_blank"><img src={"https://api.divinebricks.com" + row.attachment} style={{ maxWidth: '200px', maxHeight: '100px' }} /></a></div>,
    },
    {
        name: <div style={{fontWeight: '800'}}>Date</div>,
        selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
        width: '160px'
    },
    {
        name: <div style={{fontWeight: '800'}}>Status</div>,
        selector: (row) => <div className={row.is_status == 'inactive' ? "badge badge-danger p-2" :  "badge badge-success p-2"}>{row.is_status}</div>
    },
];
const ExpandedComponent = ({ data }) => {
    return <div className="container my-3"><div className="row"><div className="col-md-3" style={{ textAlign: 'left' }}><label>Wallet Amount</label><input type="text" value={data.walletAmt} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>Hold Amount</label><input type="text" value={data.holdAmt} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>Available Amount</label><input type="text" value={data.availAmt} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>Withdraw Amount</label><input type="text" value={data.withAmt} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>Address</label><input type="text" value={data.address} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>City</label><input type="text" value={data.city} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>District</label><input type="text" value={data.district} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>State</label><input type="text" value={data.state} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>Country</label><input type="text" value={data.country} className="form-control mb-3" readOnly /></div><div className="col-md-3" style={{ textAlign: 'left' }}><label>Pincode</label><input type="text" value={data.pincode} className="form-control mb-3" readOnly /></div></div></div>;
};

const UsersList = () => {
    const [data, setData] = useState()

    useEffect(() => {
        const token = sessionStorage.getItem("token")
        axios
            .get(
                `api/users/get-user-list`,
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result.rows)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])
    return (
        <>
            <UserHeader />
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title" style={{ textAlign: 'left' }}>Users Report</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive export-button-table">
                                <DataTable
                                    pagination={true}
                                    columns={columns}
                                    data={data}
                                    paginationServer={true}
                                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                    expandableRows
                                    expandableRowsComponent={ExpandedComponent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UsersList
import React from "react";
import buySell1 from "../image/buysell1.svg";
import buySell2 from "../image/buysell2.svg";
import buySell3 from "../image/buysell3.svg";
import buySell4 from "../image/buysell4.svg";

const BuySell = () => {
  return (
    <>
      <section
        className="mobile-section"
        style={{ paddingTop: "100px", paddingBottom: "40px" }}
      >
        <div className="container">
          <h3
            className="mb-5"
            style={{ color: "#5C5959", fontWeight: "700", fontSize: "28px" }}
          >
            Buy, Sell or Rent your Property
          </h3>
          <div className="row">
            <div className="col-md-3" style={{ overflow: "hidden" }}>
              <img
                className="zoom-image-effects"
                alt="divine-bricks-sale-your-property"
                title="divine-bricks-sale-your-property"
                src={buySell1}
                style={{
                  background: "rgba(252, 229, 179, 0.50)",
                  borderRadius: "10px",
                  padding: "10px",
                  height: "200px",
                }}
              />
              <p className="mt-2" style={{ color: "#5C5959" }}>
                Sale Your Property
              </p>
            </div>
            <div className="col-md-3" style={{ overflow: "hidden" }}>
              <img
                className="zoom-image-effects"
                alt="divine-bricks-buy-property"
                title="divine-bricks-buy-property"
                src={buySell2}
                style={{
                  background: "rgba(252, 229, 179, 0.50)",
                  borderRadius: "10px",
                  padding: "10px",
                  height: "200px",
                }}
              />
              <p className="mt-2" style={{ color: "#5C5959" }}>
                Buy Property
              </p>
            </div>
            <div className="col-md-3" style={{ overflow: "hidden" }}>
              <img
                className="zoom-image-effects"
                src={buySell3}
                alt="divine-bricks-rent-your-property"
                title="divine-bricks-rent-your-property"
                style={{
                  background: "rgba(252, 229, 179, 0.50)",
                  borderRadius: "10px",
                  padding: "10px",
                  height: "200px",
                }}
              />
              <p className="mt-2" style={{ color: "#5C5959" }}>
                Rent Your Property
              </p>
            </div>
            <div className="col-md-3" style={{ overflow: "hidden" }}>
              <img
                className="zoom-image-effects"
                alt="divine-bricks-invest-in-real-estate"
                title="divine-bricks-invest-in-real-estate"
                src={buySell4}
                style={{
                  background: "rgba(252, 229, 179, 0.50)",
                  borderRadius: "10px",
                  padding: "10px",
                  height: "200px",
                }}
              />
              <p className="mt-2" style={{ color: "#5C5959" }}>
                Invest in Real Estate
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <img
                src="/divine-bricks-banner-2.png"
                alt="divine-bricks-banner-2"
                title="divine-bricks-banner-2"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BuySell;

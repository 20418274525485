import React from "react";
import about from "../image/about-pages.webp";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";
import HomeFooter from "./HomeFooter";
import HomeArticles from "./HomeArticles";

const LatestNews = () => {
  return (
    <>
      <body class="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <section
            class="headings about-page-heading"
            title="divine-bricks-privacy-policy"
            alt="divine-bricks-privacy-policy"
            style={{
              background: "url(" + about + ") center top no-repeat",
              backgroundSize: "100%",
              height: "200px",
            }}
          >
            <div
              class="text-heading text-center"
              style={{ paddingTop: "80px" }}
            >
              <div class="container">
                <h2>Latest News</h2>
              </div>
            </div>
          </section>
          <section class="about-us fh mb-5">
            <div class="container">
              <HomeArticles recrodsCount={"All"} />
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default LatestNews;

import React, { useState, useEffect } from "react";
import logo from "../image/logo-divine.png";
import contact from "../image/contact.webp";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "../axiosInstance";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [websiteData, setWebsiteData] = useState();
  const [typeList, setTypeList] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    axios
      .get(`api/users/get-settings`)
      .then((resp) => {
        setShow(false);
        setWebsiteData(resp.data.result.settingData);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-type-list`, {
        headers: {
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzAzMDUyOTAxLCJleHAiOjE3MTA4Mjg5MDF9.CuxMVpJvCE1asp0153mTq7HGX7PXQeXSSBA6oJhrh20",
        },
      })
      .then((resp) => {
        setTypeList(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>Contact Us Divine Brick</title>{" "}
        <meta name="keywords" content="contact to divine bricks" />
        <meta
          name="description"
          content="Contact us for any inquiries, feedback, or assistance. Our dedicated team is here to help you. Find our contact details, address, and a convenient contact form. Let's connect and address your needs today."
        />
      </Helmet>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <Header />
          <div className="clearfix"></div>
          <section className="contact-us mt-5">
            <div className="container">
              <div className="property-location mb-5 mt-md-0 mt-3">
                <div className="divider-fade"></div>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <h3 className="mb-4">Contact Us</h3>
                    <form
                      id="contactform"
                      className="contact-form"
                      name="contactform"
                      method="post"
                      novalidate="novalidate"
                    >
                      <div id="success" className="successform">
                        <p
                          className="alert alert-success font-weight-bold"
                          role="alert"
                        >
                          Your message was sent successfully!
                        </p>
                      </div>
                      <div id="error" className="errorform">
                        <p>
                          Something went wrong, try refreshing and submitting
                          the form again.
                        </p>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              required=""
                              className="form-control input-custom input-full"
                              name="name"
                              placeholder="First Name"
                              aria-required="true"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              required=""
                              className="form-control input-custom input-full"
                              name="lastname"
                              placeholder="Last Name"
                              aria-required="true"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-custom input-full"
                              name="email"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-custom input-full"
                              name="number"
                              placeholder="Mobile Number"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <select className="form-control">
                              <option disabled selected>
                                Select Category
                              </option>
                              <option value="Buy">Buy</option>
                              <option value="Sell">Sell</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <select className="form-control">
                              <option disabled selected>
                                Select Type
                              </option>
                              {typeList?.map((data) => {
                                return (
                                  <option value={data.id}>{data.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <textarea
                            className="form-control textarea-custom input-full"
                            id="ccomment"
                            name="message"
                            required=""
                            rows="8"
                            placeholder="Message"
                            aria-required="true"
                          ></textarea>
                        </div>
                      </div>

                      <button
                        id="submit-contact"
                        className="btn btn-primary btn-lg"
                        style={{ border: "none" }}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                  <div
                    className="col-lg-4 col-md-12 background-contact mt-md-0 mt-3"
                    style={{
                      background:
                        "linear-gradient(rgba(32, 51, 100, 0.8), rgba(32, 51, 100, 0.8)), url(" +
                        contact +
                        ") no-repeat center center",
                      backgroundSize: "cover",
                      padding: "2rem",
                    }}
                  >
                    <div className="call-info">
                      <h3>Contact Details</h3>
                      <p className="mb-3">
                        Please find below contact details and contact us today!
                      </p>
                      <ul style={{ paddingLeft: "0" }}>
                        <li>
                          <div className="info">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                              style={{ paddingTop: "5px" }}
                            ></i>
                            <p
                              className="in-p"
                              style={{ marginLeft: "0", lineHeight: "22px" }}
                            >
                              <div>
                                <p
                                  style={{
                                    marginBottom: "5px",
                                    display: "flex",
                                    fontSize: "12px",
                                  }}
                                >
                                  <strong style={{ marginRight: "5px" }}>
                                    Dubai:
                                  </strong>{" "}
                                  {show ? (
                                    <Skeleton count={1} width="200px" />
                                  ) : (
                                    websiteData?.d_address
                                  )}
                                </p>
                                {/* {websiteData?.i_address != null ? (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      display: "flex",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <strong style={{ marginRight: "5px" }}>
                                      Dubai:
                                    </strong>{" "}
                                    {show ? (
                                      <Skeleton count={1} width="200px" />
                                    ) : (
                                      websiteData?.i_address
                                    )}
                                  </p>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info">
                            <i className="fa fa-phone" aria-hidden="true"></i>
                            <p className="in-p">
                              {show ? (
                                <Skeleton count={1} width="200px" />
                              ) : (
                                websiteData?.mobile_no
                              )}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info">
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                            <p className="in-p ti">
                              {show ? (
                                <Skeleton count={1} width="200px" />
                              ) : (
                                websiteData?.email
                              )}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info cll">
                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                            <p className="in-p ti">10:00 a.m - 7:00 p.m</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </body>
    </>
  );
};
export default ContactUs;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from 'jquery'
// , { id: 4, name: 'Queries', img: 'fas fa-comment', redirect: '/seller-queries' }

const SellerSidebar = () => {
    const path = useLocation()
    
    const [showActive1, setShowActive1] = useState(false)
    const [showActive2, setShowActive2] = useState(false)
    const [showActive3, setShowActive3] = useState(false)
    const [showActive4, setShowActive4] = useState(false)
    const [boxShadow, setBoxShadow] = useState('0px 0px 4.9px 4px rgba(0, 0, 0, 0.15) inset')
    const [newBoxShadow, setNewBoxShadow] = useState('0px 10px 5px 0px rgba(221, 221, 221, 1)')
    const data = [{ id: 1, name: 'Home', img: 'fa fa-home', redirect: '/seller-dashboard', boxShadow: showActive1? newBoxShadow : boxShadow }, { id: 2, name: 'Add Property', img: 'fa fa-plus', redirect: '/agent-add-property', boxShadow: showActive2? newBoxShadow : boxShadow }, { id: 3, name: 'Listing', img: 'fa fa-list', redirect: '/seller-listing', boxShadow: showActive3 ? newBoxShadow : boxShadow }, { id: 4, name: 'Logout', img: 'fa fa-sign-out', redirect: '/seller-login', boxShadow: showActive4 ? newBoxShadow :boxShadow }]



    useEffect(() => {
        var url = path.pathname
        if(url == '/seller-dashboard') {
            setShowActive1(true)
            setShowActive2(false)
            setShowActive3(false)
            setShowActive4(false)
        }
        if(url == '/agent-add-property') {
            setShowActive1(false)
            setShowActive2(true)
            setShowActive3(false)
            setShowActive4(false)
        }
        if(url == '/seller-listing') {
            setShowActive1(false)
            setShowActive2(false)
            setShowActive3(true)
            setShowActive4(false)
        }
        if(url == '/seller-login') {
            setShowActive1(false)
            setShowActive2(false)
            setShowActive3(false)
            setShowActive4(true)
        }
    }, [])

    return (
        <>
            <div className="seller-sidebar for-desktop">
                {data.map((data) => {
                    return (
                        <Link to={data.redirect} style={{color: 'black'}}>
                            <div id={data.id} key={data.id} className="seller-sidebar-panel" style={{ borderRadius: '5px',  boxShadow: data.boxShadow }}>
                                <div><i style={{color: '#C07F00'}} class={data.img} aria-hidden='true' ></i></div>
                                <div style={{color: '#C07F00'}}>{data.name}</div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </>
    )
}
export default SellerSidebar
import React, { useState, useEffect } from "react";
import $ from "jquery";
import img from "../image/post-property.webp";
import OtpInput from "react-otp-input";
import axios from "../axiosInstance.js";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";

const HomePostProperty = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setInterval(() => {
      if ($(".glow-text").css("background") == "black") {
        $(".glow-text").css("background", "brown");
      } else {
        $(".glow-text").css("background", "black");
      }
    }, 500);
  }, []);

  //   function showOtp() {

  //   }
  function showForm() {
    $(".details-form").css("display", "block");
    $(".otp-form").css("display", "none");
  }
  function showOtp() {
    setVisible(true);
    axios
      .post(
        `api/users/register`,
        {
          name: name,
          email: email,
          password: password,
          referralCode: "1",
        }
        // {
        //   headers: {
        //     "x-access-token": token,
        //   },
        // }
      )
      .then((resp) => {
        setVisible(false);
        if (!resp.data.success) {
          toast.error(resp.data.msg, { toastId: "e1" });
        }
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "s1" });
          $(".details-form").css("display", "none");
          $(".otp-form").css("display", "block");
        }
      })
      .catch((err) => {
        console.log(err);
        setVisible(false);
      });
  }

  function register(e) {
    e.preventDefault();
    setVisible(true);
    axios
      .post(
        `api/users/complete-register`,
        {
          email: email,
          otp: otp,
        }
        // {
        //   headers: {
        //     "x-access-token": token,
        //   },
        // }
      )
      .then((resp) => {
        setVisible(false);
        if (!resp.data.success) {
          toast.error(resp.data.msg, { toastId: "e2" });
        }
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "s2" });
          //   $('#post-property').modal('toggle');
          window.location.reload();
          //   $('#post-property').modal('hide');
        }
      })
      .catch((err) => {
        console.log(err);
        setVisible(false);
      });
  }

  return (
    <>
      {visible ? <div className="screen-overlay"></div> : ""}
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <section
        className="sell-property-section"
        style={{ background: "#F9F9F9", padding: "40px", paddingTop: "80px" }}
      >
        <div className="container">
          <h4 style={{ color: "#8F5726", fontSize: "14px", fontWeight: "400" }}>
            ARE YOU AN OWNER?
          </h4>
          <h3 style={{ color: "#5C5959", fontSize: "28px", fontWeight: "700" }}>
            Sell or rent your property swiftly <br /> with Divine Bricks
          </h3>
          <div className="row mt-5">
            <div className="col-md-6" style={{ textAlign: "left" }}>
              <p style={{ color: "#5C5959", fontSize: "20px" }}>
                POST YOUR PROPERTY
              </p>
              <p
                className="register-property"
                style={{
                  color: "#8F5726",
                  fontSize: "40px",
                  fontWeight: "800",
                  lineHeight: "50px",
                }}
              >
                Register to post your <br className="for-desktop" /> property
                for
                <span
                  className="glow-text"
                  style={{
                    background: "#5C5959",
                    color: "white",
                    fontSize: "12px",
                    padding: "5px 10px",
                    position: "relative",
                    top: "-5px",
                    left: "10px",
                  }}
                >
                  FREE
                </span>
              </p>
              <p style={{ color: "#5C5959", fontSize: "20px" }}>
                Sell or rent your Villa / Apartment property
              </p>
              <button
                className="button-hover-style"
                style={{
                  border: "none",
                  background: "#5C5959",
                  padding: "10px 20px",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "2px",
                  marginTop: "20px",
                }}
                data-toggle="modal"
                data-target="#post-property"
              >
                Post Your Property For Free
              </button>
            </div>
            <div className="col-md-6 second-col">
              <img
                className="floating-image"
                src={img}
                title="divine-bricks-post-property"
              />
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="post-property"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{ top: "80px", maxWidth: "400px" }}
        >
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Account
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="details-form">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    className="form-control mb-3"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    className="form-control mb-3"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {/* <input
                    type="number"
                    className="form-control"
                    placeholder="Phone Number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                  /> */}
                  <div className="d-flex align-items-center mt-3">
                    <input
                      type="checkbox"
                      className="form-control"
                      name="tc"
                      id="tc"
                      style={{ marginTop: "0px" }}
                      required
                    />
                    <label
                      htmlFor="tc"
                      style={{ paddingBottom: 0, marginBottom: 0 }}
                    >
                      I agree to the Terms & Conditions
                    </label>
                  </div>
                </div>
                <div
                  className="otp-form"
                  style={{ display: "none", textAlign: "left" }}
                >
                  <h3>Verify your email ID</h3>
                  <h3 className="mb-5">
                    {email}{" "}
                    <EditIcon
                      onClick={showForm}
                      style={{ cursor: "pointer" }}
                    />
                  </h3>
                  <p>Enter your 4 digit OTP</p>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    className="mt-4"
                    inputStyle="fill-otp"
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  <p className="mt-2">
                    Haven't received yet? <a href="">Resend OTP</a>
                  </p>
                  <button
                    className="mt-4"
                    style={{
                      border: "none",
                      padding: "10px",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    onClick={register}
                  >
                    Verify & Continue
                  </button>
                </div>
              </div>
              <div
                className="modal-footer details-form"
                style={{ textAlign: "right" }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={showOtp}
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePostProperty;

import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import address from "../image/address.png";
import beds from "../image/beds.svg";
import bath from "../image/bath.svg";
import sqft from "../image/sqft.svg";
import wishlist from "../image/wishlist.png";
import bg from "../image/divine webp/popular_properties.webp";
import axios from "../axiosInstance.js";
import { Link } from "react-router-dom";

const HomePagePopularProperties = () => {
  const [popularProperties, setPopularProperties] = useState();

  useEffect(() => {
    axios
      .get(`api/users/get-home-property-list?populer_property=Yes`)
      .then((resp) => {
        setPopularProperties(resp.data.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section
        className="popular-property-section"
        style={{ background: "url(" + bg + ")", padding: "40px" }}
      >
        <h3 style={{ color: "#5C5959", fontSize: "28px", fontWeight: "700" }}>
          Popular Properties in Dubai
        </h3>

        {popularProperties ? (
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className="carousel-item-padding-40-px"
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 20,
              },
              mobile: {
                breakpoint: {
                  max: 767,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 768,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {popularProperties?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="card carousel__item"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 10px",
                    textAlign: "left",
                  }}
                >
                  <div className="position-relative properties-dubai-div">
                    <span>Rent</span>
                    <img src={wishlist} />
                    <img
                      src={"https://api.divinebricks.com" + data?.image}
                      style={{
                        width: "100%",
                        height: "140px",
                        borderRadius: "2px",
                      }}
                      alt={data?.slug}
                      title={data?.name}
                    />
                  </div>
                  <div style={{ width: "100%", padding: "10px" }}>
                    <p
                      style={{
                        marginBottom: "0",
                        color: "#5C5959",
                        fontSize: "14px",
                        fontWeight: "600",
                        textAlign: "left",
                        width: "150px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {data?.name}
                    </p>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#5C5959",
                        fontWeight: "400",
                        fontSize: "10px",
                        marginBottom: "0",
                        marginTop: "-5px",
                        width: "150px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <img src={address} style={{ marginRight: "5px" }} />
                      {data?.address}
                    </p>
                    <div className="d-flex dubai-property-buttons">
                      <span>
                        <img src={beds} style={{ width: "12px" }} />
                        {data?.min_beds} Beds
                      </span>
                      <span>
                        <img src={bath} style={{ width: "12px" }} />
                        {data?.min_bath} Bath
                      </span>
                      <span>
                        <img src={sqft} style={{ width: "12px" }} />
                        {data.max_area} Sqft
                      </span>
                    </div>
                    <p
                      style={{
                        textAlign: "left",
                        marginTop: "10px",
                        color: "#8F5726",
                        fontSize: "14px",
                        fontWeight: "700",
                        marginBottom: "5px",
                      }}
                    >
                      AED {parseInt(data?.price).toLocaleString()}
                    </p>
                    <button
                      className="cta"
                      style={{
                        border: "none",
                        background: "transparent",
                        padding: "4px 10px",
                      }}
                    >
                      <Link
                        to={"/divine-bricks-properties/" + data?.slug}
                        title={data?.name}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        <span>View Details &rarr;</span>
                      </Link>
                    </button>
                  </div>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};
export default HomePagePopularProperties;

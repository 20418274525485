import React, { useState, useEffect } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import $ from 'jquery'
import OtpInput from 'react-otp-input';
import logo from '../image/logo-divine.png'

const Register = () => {

    useEffect(() => {
        var url = window.location.href;
        var url_string = url;
        var url = new URL(url_string);
        setReferral(
            url.searchParams.get("referralcode")
                ? url.searchParams.get("referralcode")
                : referral
        );
    }, []);

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [referral, setReferral] = useState()
    const [visible, setVisible] = useState(false)
    const [otp, setOtp] = useState('');
    const navigate = useNavigate()

    const goBack = (e) => {
        e.preventDefault()
        $('.enter-otp').css('display', 'none')
        $('.enter-details').css('display', 'block')
    }

    const register = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/register`,
                {
                    name: name,
                    email: email,
                    password: password,
                    referralCode: referral,
                },
                // {
                //   headers: {
                //     "x-access-token": token,
                //   },
                // }
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's1' })
                    $('.enter-otp').css('display', 'block')
                    $('.enter-details').css('display', 'none')
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }

    const verifyOtp = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/complete-register`,
                {
                    email: email,
                    otp: otp
                },
                // {
                //   headers: {
                //     "x-access-token": token,
                //   },
                // }
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e2' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's2' })
                    setTimeout(() => {
                        navigate('/login')
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }
    return (
        <>
            {visible ? <div className="screen-overlay"></div> : ''}
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <div className="gradient-form" style={{ overflow: 'hidden' }}>

                <div className='row h-100'>

                    <div className="col-md-6" style={{ margin: 'auto' }}>
                        <div className="d-flex flex-column container ms-5">

                            <div className="text-center mb-4 pt-2">
                                <Link to='/'><img src={logo}
                                    style={{ width: '185px' }} alt="logo" /></Link>
                            </div>
                            <div className='enter-details'>
                                <p style={{ fontSize: '20px', color: 'black' }}>Please Register Your Account</p>


                                <input className='form-control mb-2' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' id='name' type='text' />
                                <input className='form-control mb-2' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email address' id='email' type='email' />
                                <input className='form-control mb-2' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' id='password' type='password' />
                                <input className='form-control mb-2' value={referral} onChange={(e) => setReferral(e.target.value)} placeholder='Referral Code' id='referral_code' type='text' />


                                <div className="text-center pt-1 mb-2 pb-1">
                                    <button className="mb-4 w-100 gradient-custom-2" style={{ color: 'white', fontSize: '20px', background: 'linear-gradient(90deg, rgba(192, 127, 0, 1) 0%, rgba(203, 170, 92, 1) 56%)' }} onClick={register}>Sign up</button>
                                    {/* <a className="text-muted" href="#!">Forgot password?</a> */}
                                </div>

                                <div className="d-flex flex-row align-items-center justify-content-center pb-5 mb-5">
                                    <p className="mb-0">Already have an account?</p>
                                    <Link to='/login'><button className='btn btn-danger mx-2'>
                                        Login
                                    </button></Link>
                                </div>
                            </div>
                            <div className='enter-otp'>
                                <p style={{ fontSize: '20px', color: '#1e5239' }}>Verify OTP</p>
                                <h4 className='mb-4' style={{ fontSize: '16px', color: '#1e5239' }}>Enter 4 digit OTP sent to {email}</h4>
                                <h5>Please use Otp - 1000 for now</h5>
                                <div className='d-flex justify-content-center'>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        inputStyle="fill-otp"
                                        renderSeparator={<span>&nbsp;</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <button className='btn w-100 btn-warning' style={{ color: 'white' }} onClick={goBack}>Go Back</button>
                                    </div>
                                    <div className='col-md-6'>
                                        <button className="mb-4 w-100 gradient-custom-2" style={{ color: 'white', fontSize: '20px' }} onClick={verifyOtp}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="d-flex flex-column  justify-content-center gradient-custom-2 mb-4" style={{height: '100vh', background: 'linear-gradient(90deg, rgba(192, 127, 0, 1) 0%, rgba(203, 170, 92, 1) 56%)'}}>

                            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                                <h4 class="mb-4">We are more than just a company</h4>
                                <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}
export default Register
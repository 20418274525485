import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery'
import axios from '../axiosInstance'

var storage_data = [localStorage.getItem("favourite_properties")]
const PropertyListingGrid = (data, view) => {
    const [propertiesList, setPropertiesList] = useState()
    const favArr = localStorage.getItem("favourite_properties")
    
    const [bookmarkColor, setBookmarkColor] = useState('white')
    function bookmark(e) {
        if($(e.target).css('color') == 'rgb(255, 255, 255)') {
            setBookmarkColor('red')
            storage_data.push($(e.target).attr('id'))
        }
        else {
            setBookmarkColor('white')
            const index = storage_data.indexOf($(e.target).attr('id'))
            if (index > -1) { 
                storage_data.splice(index, 1); 
              }
            // storage_data.pop($(e.target).attr('id'))
        }
        localStorage.setItem('favourite_properties', storage_data)
    }
    return (
        <>
            {data.view != 'list' ?
                <div class="item col-lg-6 col-md-6 col-xs-12 landscapes sale">
                    <div class="project-single aos-init aos-animate" data-aos="fade-up">
                        <div class="project-inner project-head">
                            <div class="homes">
                                <div class="homes-img">
                                    <div class="homes-tag button alt featured">{data?.data?.typeArr}</div>
                                    {/* <div class="homes-tag button alt sale">For {data?.data?.category}</div> */}
                                    <div class="homes-price">AED {parseInt(data?.data?.price).toLocaleString()}</div>
                                    <div style={{ position: 'absolute', bottom: '20px', right: '16px', zIndex: '9999' }}><i id={data?.data?.id} class='fa fa-heart' aria-hidden='true' style={{ color: bookmarkColor, fontSize: '24px', cursor: 'pointer' }} onClick={bookmark}></i></div>
                                    <img src={'https://api.divinebricks.com/' + data?.data?.image} alt={data?.data?.slug} title={data?.data?.name} class="img-responsive" style={{ maxHeight: '174px', height: '174px' }} />
                                </div>
                            </div>
                            {/* <div class="button-effect">
                                                                <a href="single-property-1.html" class="btn"><i class="fa fa-link"></i></a>
                                                                <a href="https://www.youtube.com/watch?v=14semTlwyUY" class="btn popup-video popup-youtube"><i class="fas fa-video"></i></a>
                                                                <a href="single-property-2.html" class="img-poppu btn"><i class="fa fa-photo"></i></a>
                                                            </div> */}
                        </div>
                        <Link to={'/divine-bricks-properties/' + data?.data?.slug}>
                            <div class="homes-content">
                                <h3><a href="#" className="name-dots">{data?.data?.name}</a></h3>
                                <p class="homes-address name-dots mb-3">
                                    <a href="#">
                                        <i class="fa fa-map-marker"></i><span>{data?.data?.address}</span>
                                    </a>
                                </p>
                                <ul class="homes-list clearfix pb-3">
                                    <li class="the-icons">
                                        <i class="flaticon-bed mr-2" aria-hidden="true"></i>
                                        <span><strong>{data?.data?.min_beds}</strong> Bedrooms</span>
                                    </li>
                                    <li class="the-icons">
                                        <i class="flaticon-bathtub mr-2" aria-hidden="true"></i>
                                        <span><strong>{data?.data?.min_bath}</strong> Bathrooms</span>
                                    </li>
                                    <li class="the-icons">
                                        <i class="flaticon-square mr-2" aria-hidden="true"></i>
                                        <span><strong>{data?.data?.min_area}</strong> sq ft</span>
                                    </li>
                                    <li class="the-icons">
                                        <i class="flaticon-car mr-2" aria-hidden="true"></i>
                                        <span>Parking <strong>{data?.data?.parking}</strong></span>
                                    </li>
                                </ul>
                                <div class="footer">
                                    <a href="#">
                                        <img src={'https://api.divinebricks.com' + data?.data?.builderData?.logo} alt="" class="mr-2" /> {data?.data?.builderData?.name}
                                    </a>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                :
                <div class="item col-lg-12 col-md-12 col-xs-12 landscapes sale">
                    <Link to={'/divine-bricks-properties/' + data?.data?.slug}>
                        <div class="project-single aos-init aos-animate" data-aos="fade-up" style={{ display: 'flex' }}>
                            <div class="project-inner project-head" style={{ flexGrow: '1', maxWidth: '350px' }}>
                                <div class="homes">
                                    <a href="#" class="homes-img">
                                        <div class="homes-tag button alt featured">{data?.data?.typeArr}</div>
                                        <div class="homes-tag button alt sale">For {data?.data?.category}</div>
                                        <div class="homes-price">AED {parseInt(data?.data?.price).toLocaleString()}</div>
                                        <img src={'https://api.divinebricks.com/' + data?.data?.image}  alt={data?.data?.slug} title={data?.data?.name} class="img-responsive" style={{ height: '250px' }} />
                                    </a>
                                </div>
                                {/* <div class="button-effect">
                                                                <a href="single-property-1.html" class="btn"><i class="fa fa-link"></i></a>
                                                                <a href="https://www.youtube.com/watch?v=14semTlwyUY" class="btn popup-video popup-youtube"><i class="fas fa-video"></i></a>
                                                                <a href="single-property-2.html" class="img-poppu btn"><i class="fa fa-photo"></i></a>
                                                            </div> */}
                            </div>
                            <div class="homes-content" style={{ flexGrow: '1' }}>
                                <h3><a href="#" className="name-dots">{data?.data?.name}</a></h3>
                                <p class="homes-address name-dots mb-3">
                                    <a href="#">
                                        <i class="fa fa-map-marker"></i><span>{data?.data?.address}</span>
                                    </a>
                                </p>
                                <ul class="homes-list clearfix pb-3">
                                    <li class="the-icons">
                                        <i class="flaticon-bed mr-2" aria-hidden="true"></i>
                                        <span><strong>{data?.data?.min_beds}</strong> Bedrooms</span>
                                    </li>
                                    <li class="the-icons">
                                        <i class="flaticon-bathtub mr-2" aria-hidden="true"></i>
                                        <span><strong>{data?.data?.min_bath}</strong> Bathrooms</span>
                                    </li>
                                    <li class="the-icons">
                                        <i class="flaticon-square mr-2" aria-hidden="true"></i>
                                        <span><strong>{data?.data?.min_area}</strong> sq ft</span>
                                    </li>
                                    <li class="the-icons">
                                        <i class="flaticon-car mr-2" aria-hidden="true"></i>
                                        <span>Parking <strong>{data?.data?.parking}</strong></span>
                                    </li>
                                </ul>
                                <div class="footer">
                                    <a href="#">
                                        <img src={'https://api.divinebricks.com' + data?.data?.builderData?.logo} alt="" class="mr-2" /> {data?.data?.builderData?.name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>}
        </>
    )
}
export default PropertyListingGrid
import React, { useEffect, useState } from "react";
import $ from "jquery";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "../axiosInstance.js";
import { useNavigate } from "react-router-dom";

const HomePageSearch = () => {
  const navigate = useNavigate();
  const [typeList, setTypeList] = useState();
  const [propertyType, setPropertyType] = useState();
  const [websiteData, setWebsiteData] = useState();
  const [propertyStatus, setPropertyStatus] = useState();
  useEffect(() => {
    axios
      .get(`api/users/get-home-property-types`)
      .then((resp) => {
        setTypeList(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-settings`)
      .then((resp) => {
        setWebsiteData(resp.data.result.settingData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function submitSearch() {
    navigate(
      `/divine-bricks-property-listing?keyword=${search}&typeArr=${propertyType}&category=${propertyStatus}`
    );
  }

  const [search, setSearch] = useState();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    setSearch(transcript);
  }, [transcript]);

  function handleSpeech() {
    SpeechRecognition.startListening();
  }
  function typeButton(e) {
    var check = e.target;
    $(check).parent().find("button").css("background", "none");
    $(check).parent().find("button").css("color", "#5C5959");
    $(check).css("background", "#8F5726");
    $(check).css("color", "#fff");
  }
  return (
    <>
      <section
        title="divine-bricks"
        className="position-relative search-bar-banner"
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" +
            "https://api.divinebricks.com/" +
            websiteData?.home_banner +
            ") center top no-repeat",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "contain",
          height: "350px",
        }}
      >
        <div className="search-bar">
          <div
            className="search-bar-buttons-div mb-4"
            style={{ textAlign: "left" }}
          >
            <button onClick={typeButton}>For Sale</button>
            <button onClick={typeButton}>For Buy</button>
            <button onClick={typeButton}>For Rent</button>
          </div>
          <div className="row">
            <div className="col-md-3">
              <select
                className="form-control"
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <option disabled selected>
                  Select Type
                </option>
                {typeList?.map((data, index) => {
                  return (
                    <option key={index} value={data?.slug}>
                      {data?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                placeholder="Search Property in Dubai"
                className="form-control"
                value={
                  search !== "undefined" &&
                  search !== null &&
                  search !== undefined &&
                  search !== ""
                    ? search
                    : transcript
                }
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="col-md-1 col-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="28"
                viewBox="0 0 22 28"
                fill="none"
                style={{
                  border: "1px solid black",
                  borderRadius: "50%",
                  height: "32px",
                  width: "32px",
                  cursor: "pointer",
                  padding: "7px",
                }}
                onClick={handleSpeech}
              >
                <path
                  d="M15.75 5.79416C15.75 3.37791 13.7912 1.41916 11.375 1.41916C8.95875 1.41916 7 3.37791 7 5.79416V13.9192C7 16.3354 8.95875 18.2942 11.375 18.2942C13.7912 18.2942 15.75 16.3354 15.75 13.9192V5.79416Z"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 13.2942C2 18.4718 6.19731 22.6692 11.375 22.6692C16.5527 22.6692 20.75 18.4718 20.75 13.2942"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.375 22.6692V26.4192"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="col-md-1 col-6">
              <button
                className="button-hover-style"
                style={{
                  background: "#8F5726",
                  color: "white",
                  borderRadius: "2px",
                  border: "none",
                  padding: "5px 10px",
                }}
                onClick={submitSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomePageSearch;

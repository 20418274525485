import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import img5 from "../image/5.svg";
import img6 from "../image/6.svg";
import img7 from "../image/7.svg";
import img8 from "../image/8.svg";
import naveen from "../image/naveen.jpg";
import chander from "../image/chander.jpeg";
import nitin from "../image/nitin.jpeg";
import about from "../image/about-pages.webp";
import banner from "../image/about-us-banner.webp";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";
import HomeFooter from "./HomeFooter";

const PrivacyPolicy = () => {
  return (
    <>
      <body class="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <section
            class="headings about-page-heading"
            title="divine-bricks-privacy-policy"
            alt="divine-bricks-privacy-policy"
            style={{
              background: "url(" + about + ") center top no-repeat",
              backgroundSize: "100%",
              height: "200px",
            }}
          >
            <div
              class="text-heading text-center"
              style={{ paddingTop: "80px" }}
            >
              <div class="container">
                <h2>Privacy Policy</h2>
              </div>
            </div>
          </section>
          <section class="about-us fh mb-5">
            <div class="container">
              <div class="row mt-5 mb-5">
                <div class="col-lg-12 col-md-12 who-1">
                  <h2 class="text-left mb-4">
                    PRIVACY{" "}
                    <span style={{ color: "rgb(190, 153, 81)" }}>POLICY</span>
                  </h2>
                  <div class="pftext">
                    <p>
                      Welcome to our website! If you continue to browse and use
                      this website, you are agreeing to comply with and be bound
                      by the following terms and conditions of use, which
                      together with our privacy policy govern Divine Bricks Real
                      Estate relationship with you in relation to this website.
                      If you disagree with any part of these terms and
                      conditions, please do not use our website or mobile.
                    </p>
                    <p>
                      Our registered office is: Divine Bricks Real Estate,
                      3202/32nd floor, Aspin Commercial Tower, Shaikh Zayed
                      Road, Near Financial Metro Station, Dubai, U.A.E.{" "}
                    </p>
                    <p>
                      The use of this website is subject to the following terms
                      of use:{" "}
                    </p>
                  </div>
                  <div class="pftext">
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> We or our
                      agents do not make any representations or warranties
                      regarding the accuracy or completeness of the information
                      provided. The Client acknowledges that any information
                      received is for informational purposes only and should not
                      be considered legal, financial, or investment advice. The
                      Client agrees to conduct their own due diligence and seek
                      independent professional advice before making any
                      decisions or entering into any agreements. The Client
                      releases the company and its agents from any liability
                      arising from their reliance on the provided information.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> The content of
                      the pages of this website is for your general information
                      and use only. It is subject to change without notice.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> The
                      information contained on this website is not intended to
                      and does not constitute an offer, solicitation,
                      inducement, invitation or commitment to purchase,
                      subscribe to, provide or sell or lease properties or to
                      provide any recommendations on which visitors to this
                      website should rely for financial, investment or other
                      advice or to take any decision based on such information.
                      Visitors to this website are encouraged to seek individual
                      advice from their legal, financial, personal and other
                      advisers before making any investment or financial
                      decision or purchasing or leasing any property, asset, or
                      real estate related product.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Neither we nor
                      any third parties provide any warranty or guarantee as to
                      the accuracy, timeliness, performance, completeness or
                      suitability of the information and materials found or
                      offered on this website or for any particular purpose. You
                      acknowledge that such information and materials may
                      contain inaccuracies or errors and we expressly exclude
                      liability for any such inaccuracies or errors to the
                      fullest extent permitted by law.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Your use of
                      any information or materials on this website is entirely
                      at your own risk, for which we shall not be liable. It
                      shall be your own responsibility to ensure that any
                      property, investment, asset, services or information
                      available through this website meet your specific
                      requirements.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Unless
                      otherwise expressly noted, all content included on this
                      website, including, without limitation, images, logos,
                      articles and other materials, are protected by copyrights,
                      and/or other intellectual property owned, controlled or
                      licensed by Divine Bricks Real Estate. All trademarks and
                      logos displayed on this website are the property of their
                      respective owners, who may or may not be affiliated with
                      Divine Bricks Real Estate. Visitors are responsible for
                      complying with all applicable copyright, trademark and
                      other applicable laws. We allow visitors to make copies of
                      this website as necessary incidental acts during their
                      viewing of the website. Visitors can print, for their
                      personal use, as much of the website as is reasonable for
                      private purposes. All other use is strictly prohibited.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Unauthorized
                      use of this website may give rise to a claim for damages
                      and/or be a criminal offence.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> This website
                      includes statements that are, or may be deemed to be,
                      “forward looking statements”. These forward looking
                      statements can be identified by the use of forward looking
                      terminology, including the terms “believes”, “estimates”,
                      “anticipates”, “expects”, “intends”, “may”, “will” or
                      “should” or, in each case, their negative or other
                      variations or comparable terminology. These forward
                      looking statements include all matters that are not
                      historical facts. They may appear in a number of places
                      throughout this website include statements regarding the
                      intentions, beliefs or current expectations of Divine
                      Bricks Real Estate concerning, among other things, the
                      investment performance, results of operations, financial
                      condition, liquidity and prospects of Divine Bricks Real
                      Estate. By their nature, forward looking statements
                      involve risks and uncertainties because they relate to
                      events and depend on circumstances that may or may not
                      occur in the future. Forward looking statements are not
                      guarantees of future performance. Actual investment
                      performance, results of operations, return on investments,
                      timely completion of development or projects may differ
                      materially from the impression created by the forward
                      looking statements contained on this website.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Divine Bricks
                      Real Estate does not undertake to update any of these
                      forward-looking statements.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> From time to
                      time this website may also include links to other website
                      or mobile apps. These links are provided for your
                      convenience to provide further information. They do not
                      signify that we endorse the website or mobile app(s). We
                      have no responsibility for the content of the linked
                      website or mobile app(s).
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> To the maximum
                      extent permitted by applicable law and regulatory
                      requirements, Divine Bricks Real Estate specifically
                      disclaims any liability for errors, inaccuracies or
                      omissions on this website or mobile app and for any loss
                      (whether direct or indirect) or damage resulting from its
                      use, whether caused by negligence or otherwise. Visitors
                      are responsible for compliance with all local laws and
                      regulations.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Your use of
                      this website and any dispute arising out of such use of
                      the website is subject to the laws of United Arab
                      Emirates.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Our failure to
                      insist upon strict enforcement of any provision of these
                      terms and conditions will not constitute a waiver of any
                      provision or right. Any legal action or proceeding between
                      us and you related to these terms and conditions will be
                      brought exclusively in a court of competent jurisdiction
                      in Dubai, United Arab Emirates.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> The logos,
                      images (artistic and photos) have been used solely for the
                      purpose of reference to the original brand, company,
                      builder, developer only to provide a perspective to the
                      website user and does not associate the website or its
                      owners, affiliates, vendors and employees to any of the
                      brands mentioned in the website in any way whatsoever.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> No warranties
                      of any kind, implied, express or statutory, including but
                      not limited to the warranties of non-infringement of third
                      party rights, title, merchantability, fitness for a
                      particular purpose and freedom from computer virus, are
                      given in conjunction with the information and materials.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> In no event
                      will Divine Bricks Real Estate be liable for any damages,
                      including without limitation direct or indirect, special,
                      incidental, or consequential damages, losses or expenses
                      arising in connection with the Site or use thereof or
                      inability to use by any party, or reliance on the contents
                      of the Site, or in connection with any failure of
                      performance, error, omission, interruption, defect, delay
                      or failure in operation or transmission, computer virus or
                      line or system failure, even if Divine Bricks Real Estate,
                      its representatives, are advised of the possibility of
                      such damages, losses or expense, hyperlinks to other
                      internet resources are at your own risk; the content,
                      accuracy, opinions expressed, and other links provided by
                      these resources are not investigated, verified, monitored,
                      or endorsed by Divine Bricks Real Estate. This Exclusion
                      clause shall take effect to the fullest extent permitted
                      by all applicable law.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> All
                      information submitted to Divine Bricks Real Estate via the
                      Site shall be deemed and remain the property of Divine
                      Bricks Real Estate who shall be free to use, for any
                      purpose, any ideas, concepts, know-how or techniques
                      contained in information a visitor to the Site provides
                      Divine Bricks Real Estate through the Site. Divine Bricks
                      Real Estate shall not be subject to any obligations of
                      confidentiality or privacy regarding submitted information
                      except as agreed by Divine Bricks Real Estate, as
                      specifically set forth elsewhere on the Site in its
                      Privacy Policy, or as otherwise specifically agreed or
                      required by law.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> Should you
                      have any objections to the content posted on this website
                      you are requested to write to info@divinebricks.com with
                      the subject: ‘content request clarification’ along with
                      your name, address and telephone / mobile number and we
                      will respond as soon as possible.
                    </p>
                    <p>
                      <span style={{ fontSize: "18px" }}>●</span> By accessing
                      the Site and obtaining the facilities, products or
                      services offered through the Site, you agree that all
                      applicable law of Dubai and the United Arab Emirates (UAE)
                      shall govern such access and the provision of such
                      facilities, products and services and you agree to submit
                      to the exclusive jurisdiction of the courts of the Emirate
                      of Dubai, UAE.
                    </p>
                  </div>
                  <h3 class="text-left">
                    Payments
                    <span style={{ color: "rgb(190, 153, 81)" }}>
                      Disclaimer:
                    </span>
                  </h3>
                  <div class="pftext">
                    <p>
                      As per the instructions of the Dubai Land Department,
                      individual Real Estate Agents are UNAUTHORIZED to receive
                      any cash or funds transfer to their personal bank
                      accounts. Cash payments must be handed over to the real
                      estate Company Finance Team only or Sales Progression
                      Officers at the real estate agency office. Cash payments
                      can also be handed over at the official Trustee’s offices
                      in the UAE in the presence of the real estate agency’s
                      official Sales Progression Officer or Transferred to the
                      official real estate company bank account. Divine Bricks
                      Real Estate confirms the subject payments only through
                      electronically generated receipts from our official email
                      info@divinebricks.com. For any further assistance or
                      clarification, kindly contact info@divinebricks.com.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default PrivacyPolicy;

import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from '../axiosInstance'

const columns = [
    {
        name: "S. No",
        selector: (row) => row.id,
        width: '100px'
    },
    {
        name: "Username",
        selector: (row) => row.userData.name,
        width: '100px'
    },
    {
        name: "Property Id",
        selector: (row) => row.propertyId,
        width: '100px'
    },
    {
        name: "Property Name",
        selector: (row) => row.propertyData.name,
    },
    {
        name: "EMI Date",
        selector: (row) => row.emi_date,
    },
    {
        name: "Amount",
        selector: (row) => "$" + row.amount,
    },
    {
        name: "Date",
        selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
        name: "Status",
        selector: (row) => <div className={row.is_status == 'Pending' ? "badge badge-warning p-2" : row.is_status == 'Success' ? "badge badge-success p-2" : "badge badge-danger p-2"}>{row.is_status}</div>,
    },
];

const EmiList = () => {
    const [type, setType] = useState('paid')
    const [data, setData] = useState()
    const [pending, setPending] = useState()
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);

    useEffect(() => {
        const token = sessionStorage.getItem("token")
        axios
            .post(
                `api/users/get-emi-list?page=${page}&perpage=${perpage}`,
                {
                    propertyId: null
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setPending(false)
                setData(resp.data.result.rows)
            })
            .catch((err) => {
                console.log(err)
            });
        axios
            .post(
                `api/users/reports`,
                {
                    "type": "3"
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [page, perpage])

    function handleType(e) {
        setType(e.target.value)
    }

    const handlePageChange = (page) => {
        setPage(page);
      };
      const handleRowsPerPageChange = (perpage) => {
        setPerpage(perpage);
        setPage(1);
      };
    return (
        <>
            <UserHeader />
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header" style={{ textAlign: 'left' }}>
                            <h4 className="card-title" style={{ textAlign: 'left' }}>EMIs Report</h4>
                            {/* <div class="btn-group mt-3" role="group" aria-label="Basic example">
                                <button type="button" value='paid' class="btn btn-danger" onClick={handleType}>Paid</button>
                                <button type="button" value='pending' class="btn btn-danger" onClick={handleType}>Pending</button>
                            </div> */}
                        </div>
                        <div className="card-body">
                            <div className="table-responsive export-button-table">
                                {type == 'paid' ?
                                    <DataTable
                                        pagination={true}
                                        columns={columns}
                                        data={data}
                                        paginationServer={true}
                                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                    />
                                    :
                                    <DataTable
                                        pagination={true}
                                        columns={columns}
                                        data={data}
                                        paginationServer={true}
                                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                        onChangePage={handlePageChange}
                                        progressPending={pending}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmiList
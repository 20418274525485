import React, { useState, useEffect } from "react";
import logo from "../image/logo-divine.png";
import { Link } from "react-router-dom";
import axios from "../axiosInstance";

const Footer = () => {
  const [websiteData, setWebsiteData] = useState();
  const [footerLinks, setFooterLinks] = useState();

  useEffect(() => {
    axios
      .get(`api/users/get-settings`)
      .then((resp) => {
        setWebsiteData(resp.data.result.settingData);
        setFooterLinks(resp.data.result.footerLinks);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <footer className="first-footer">
        <div className="top-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg col-xl">
                <div className="netabout">
                  <Link to="/">
                    <img
                      src={logo}
                      style={{ width: "200px", marginBottom: "10px" }}
                      alt="divine-bricks-logo-2"
                    />
                  </Link>
                  <p
                    className="mt-3"
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{ __html: websiteData?.abutUs }}
                  ></p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg col-xl">
                <div className="widget">
                  <h3>Contact Details</h3>
                  <div className="contactus">
                    <ul>
                      <li style={{ marginBottom: 0 }}>
                        <div className="info">
                          <p
                            className="in-p"
                            style={{ marginLeft: "0", lineHeight: "22px" }}
                          >
                            <div>
                              <div className="d-flex">
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                  style={{ paddingTop: "5px" }}
                                ></i>
                                <p
                                  style={{
                                    marginBottom: "7px",
                                    display: "flex",
                                    lineHeight: "24px",
                                  }}
                                >
                                  <strong style={{ marginRight: "5px" }}>
                                    Dubai:
                                  </strong>{" "}
                                  {websiteData?.d_address}
                                </p>
                              </div>
                              {/* <div className="d-flex">
                                {websiteData?.i_address != null ? (
                                  <>
                                    <i
                                      className="fa fa-map-marker"
                                      aria-hidden="true"
                                      style={{ paddingTop: "5px" }}
                                    ></i>
                                    <p
                                      style={{
                                        marginBottom: "0",
                                        display: "flex",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      <strong style={{ marginRight: "5px" }}>
                                        Dubai:
                                      </strong>{" "}
                                      {websiteData?.i_address}
                                    </p>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div> */}
                            </div>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="info">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          <p className="in-p">{websiteData?.mobile_no}</p>
                        </div>
                      </li>
                      <li>
                        <div className="info">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                          <p className="in-p ti">
                            <a
                              href="mailto:info@divinebricks.ae"
                              style={{
                                color: "#666",
                                fontWeight: "400",
                                paddingLeft: "8px",
                              }}
                            >
                              {websiteData?.email}
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg col-xl">
                <div className="newsletters">
                  <h3>Newsletters</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: websiteData?.news_letter,
                    }}
                  ></p>
                </div>
                <form
                  className="bloq-email mailchimp form-inline"
                  method="post"
                >
                  <label htmlFor="subscribeEmail" className="error"></label>
                  <div className="email">
                    <input
                      type="email"
                      id="subscribeEmail"
                      name="EMAIL"
                      placeholder="Enter Your Email"
                    />
                    <input type="submit" value="Subscribe" />
                    <p className="subscription-success"></p>
                  </div>
                </form>
              </div>
              <div className="col-12 footer-links-all mt-4">
                <div className="" style={{ lineHeight: "25px" }}>
                  {footerLinks?.map((data) => {
                    return (
                      <Link
                        to={data.slug}
                        target="_blank"
                        title={data.title}
                        style={{ color: "black" }}
                      >
                        {data.title} |
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;

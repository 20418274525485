import React from "react";
import { Link } from "react-router-dom";
import img1 from "../image/Social Media Icons/fb (1).svg";
import img2 from "../image/Social Media Icons/instagram (1).svg";
import img3 from "../image/Social Media Icons/x.svg";
import img4 from "../image/Social Media Icons/youtube.svg";

const TopHeader = () => {
  return (
    <>
      <section
        style={{
          background: "#EADBC8",
          height: "32px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container">
          <ul
            className="topheader-ul"
            style={{
              listStyleType: "none",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
            }}
          >
            <div>
              <a
                href="https://www.facebook.com/Divinebricks.realestate"
                target="_blank"
              >
                <img
                  src={img1}
                  style={{ width: "20px", marginRight: "20px" }}
                />
              </a>
              <a
                href="https://www.instagram.com/divine_bricks/"
                target="_blank"
              >
                <img
                  src={img2}
                  style={{ width: "20px", marginRight: "20px" }}
                />
              </a>
              <a href="https://twitter.com/DivineBricks" target="_blank">
                <img
                  src={img3}
                  style={{ width: "20px", marginRight: "20px" }}
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UC8M-SboOvquw6JKnLJk4h4w"
                target="_blank"
              >
                <img
                  src={img4}
                  style={{ width: "20px", marginRight: "20px" }}
                />
              </a>
            </div>
            <div className="for-desktop" style={{ display: "flex" }}>
              <li>
                <Link to="/favourite-properties" style={{ color: "#000" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    className="mx-1"
                  >
                    <path
                      d="M5.5 10.5462L4.7025 9.82024C1.87 7.25174 0 5.55224 0 3.47874C0 1.77924 1.331 0.453735 3.025 0.453735C3.982 0.453735 4.9005 0.899235 5.5 1.59774C6.0995 0.899235 7.018 0.453735 7.975 0.453735C9.669 0.453735 11 1.77924 11 3.47874C11 5.55224 9.13 7.25174 6.2975 9.82024L5.5 10.5462Z"
                      fill="black"
                    />
                  </svg>
                  Favourite Properties
                </Link>
              </li>
              <li style={{ marginLeft: "20px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  className="mx-1"
                >
                  <path
                    d="M10.7034 5.01228C10.8267 4.9204 10.9182 4.79222 10.965 4.64572C11.0119 4.49922 11.0116 4.34174 10.9644 4.19537C10.9172 4.04899 10.8254 3.92106 10.7018 3.82951C10.5782 3.73796 10.4291 3.68739 10.2753 3.68488L7.28867 3.57205C7.27399 3.57103 7.25991 3.5658 7.24813 3.55696C7.23636 3.54813 7.22739 3.53608 7.2223 3.52227L6.19025 0.734729C6.13839 0.592909 6.04421 0.470449 5.92046 0.383929C5.7967 0.297408 5.64934 0.251007 5.49834 0.251007C5.34734 0.251007 5.19998 0.297408 5.07623 0.383929C4.95247 0.470449 4.85829 0.592909 4.80643 0.734729L3.7777 3.53223C3.77261 3.54604 3.76364 3.55809 3.75187 3.56692C3.74009 3.57575 3.72601 3.58099 3.71133 3.582L0.724677 3.69483C0.570899 3.69734 0.421764 3.74792 0.298181 3.83947C0.174598 3.93102 0.0827658 4.05895 0.0355601 4.20532C-0.0116456 4.3517 -0.0118574 4.50917 0.0349544 4.65567C0.0817662 4.80218 0.173254 4.93035 0.29659 5.02223L2.63945 6.864C2.65118 6.87323 2.65994 6.88569 2.66466 6.89985C2.66938 6.914 2.66984 6.92923 2.666 6.94364L1.8596 9.80087C1.81782 9.94637 1.82187 10.1012 1.8712 10.2443C1.92053 10.3874 2.01275 10.5119 2.13532 10.6007C2.25789 10.6896 2.40485 10.7385 2.55621 10.7409C2.70757 10.7432 2.85598 10.6989 2.98126 10.6139L5.45686 8.95466C5.46905 8.94624 5.48352 8.94173 5.49834 8.94173C5.51316 8.94173 5.52763 8.94624 5.53982 8.95466L8.01542 10.6139C8.13901 10.7018 8.28689 10.749 8.43853 10.749C8.59018 10.749 8.73806 10.7018 8.86164 10.6139C8.98424 10.5259 9.07655 10.4021 9.12588 10.2594C9.1752 10.1168 9.17911 9.96241 9.13708 9.81747L8.32404 6.95028C8.31974 6.93589 8.31997 6.92052 8.32473 6.90626C8.32948 6.89201 8.33851 6.87957 8.35059 6.87064L10.7034 5.01228Z"
                    fill="black"
                  />
                </svg>
                Saved Searches
              </li>
              <li style={{ marginLeft: "20px" }}>
                <Link to="/event-gallery" className="text-dark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    className="mx-1"
                  >
                    <path
                      d="M10.7034 5.01228C10.8267 4.9204 10.9182 4.79222 10.965 4.64572C11.0119 4.49922 11.0116 4.34174 10.9644 4.19537C10.9172 4.04899 10.8254 3.92106 10.7018 3.82951C10.5782 3.73796 10.4291 3.68739 10.2753 3.68488L7.28867 3.57205C7.27399 3.57103 7.25991 3.5658 7.24813 3.55696C7.23636 3.54813 7.22739 3.53608 7.2223 3.52227L6.19025 0.734729C6.13839 0.592909 6.04421 0.470449 5.92046 0.383929C5.7967 0.297408 5.64934 0.251007 5.49834 0.251007C5.34734 0.251007 5.19998 0.297408 5.07623 0.383929C4.95247 0.470449 4.85829 0.592909 4.80643 0.734729L3.7777 3.53223C3.77261 3.54604 3.76364 3.55809 3.75187 3.56692C3.74009 3.57575 3.72601 3.58099 3.71133 3.582L0.724677 3.69483C0.570899 3.69734 0.421764 3.74792 0.298181 3.83947C0.174598 3.93102 0.0827658 4.05895 0.0355601 4.20532C-0.0116456 4.3517 -0.0118574 4.50917 0.0349544 4.65567C0.0817662 4.80218 0.173254 4.93035 0.29659 5.02223L2.63945 6.864C2.65118 6.87323 2.65994 6.88569 2.66466 6.89985C2.66938 6.914 2.66984 6.92923 2.666 6.94364L1.8596 9.80087C1.81782 9.94637 1.82187 10.1012 1.8712 10.2443C1.92053 10.3874 2.01275 10.5119 2.13532 10.6007C2.25789 10.6896 2.40485 10.7385 2.55621 10.7409C2.70757 10.7432 2.85598 10.6989 2.98126 10.6139L5.45686 8.95466C5.46905 8.94624 5.48352 8.94173 5.49834 8.94173C5.51316 8.94173 5.52763 8.94624 5.53982 8.95466L8.01542 10.6139C8.13901 10.7018 8.28689 10.749 8.43853 10.749C8.59018 10.749 8.73806 10.7018 8.86164 10.6139C8.98424 10.5259 9.07655 10.4021 9.12588 10.2594C9.1752 10.1168 9.17911 9.96241 9.13708 9.81747L8.32404 6.95028C8.31974 6.93589 8.31997 6.92052 8.32473 6.90626C8.32948 6.89201 8.33851 6.87957 8.35059 6.87064L10.7034 5.01228Z"
                      fill="black"
                    />
                  </svg>
                  Event Gallery
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </section>
    </>
  );
};
export default TopHeader;

import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from "../axiosInstance";

const columns = [
    {
        name: "Property ID",
        selector: (row) => row.propertyId,
    },
    {
        name: "Property Name",
        selector: (row) => row.propertyData?.name,
    },
    {
        name: "Amount",
        selector: (row) => "$" + row.amount,
    },
    {
        name: "EMI Date",
        selector: (row) => row.emi_date,
    },
    {
        name: "Date",
        selector: (row) => moment(row.createdon).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
        name: "Status",
        selector: (row) => <div className={row.is_status == 'Pending' ? "badge badge-warning p-2" : row.is_status == 'Ontime' ? "badge badge-success p-2" : "badge badge-danger p-2"}>{row.is_status}</div>,
        width: 'auto'
    },
];

const PaymentData = () => {
    var getData = []
    var num
    const [data, setData] = useState()
    useEffect(() => {
        getData = (sessionStorage.getItem("myPayment"))
        num = parseInt(getData) - 1
        const token = sessionStorage.getItem("token")
        axios
            .get(
                `api/users/get-buy-property-list`,
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result.rows[0])
                getData = resp.data.result.rows[0].paymentData
            })
            .catch((err) => {
                console.log(err)
            });
}, [])
return (
    <>
        <UserHeader />
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title" style={{ textAlign: 'left' }}>Payment Report for Property Id - {sessionStorage.getItem("myProperties")}</h4>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive export-button-table">
                            <DataTable
                                pagination={true}
                                columns={columns}
                                data={getData}
                                paginationServer={true}
                                paginationRowsPerPageOptions={[10, 50, 100, 500]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default PaymentData
import React from "react";
import groupSvg from '../image/about-svg.svg'
import aboutImg from '../image/about-img.png'
import aboutUs from '../image/about-img.webp'


const HomePageAboutUs = () => {
    return (
        <>
            <section className="about-us-section" style={{background: 'url('+aboutUs+') no-repeat', padding: '40px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5" style={{overflow: 'hidden'}}>
                            {/* <img className="zoom-hover-effects" src={aboutImg} alt="divine-bricks-brand-advertisements" title="divine-bricks-brand-advertisements" /> */}
                        </div>
                        <div className="col-md-7" style={{textAlign: 'left'}}>
                            <p style={{color: '#5C5959', marginBottom: '5px', fontSize: '16px'}}>About Us</p>
                            <p style={{color: '#8F5726', fontSize: '28px', fontWeight: '700', lineHeight: '40px'}}>Our mission is to create exceptional living spaces that inspire and elevate the quality of life.</p>
                            <ul style={{ paddingLeft: '0', listStyleType: 'none', lineHeight: '32px' }}>
                                <li style={{display: 'flex', alignItems: 'center', color: '#5C5959', fontSize: '14px'}}><img src={groupSvg} style={{marginRight: '5px'}} />Find excellent deals</li>
                                <li style={{display: 'flex', alignItems: 'center', color: '#5C5959', fontSize: '14px'}}><img src={groupSvg} style={{marginRight: '5px'}} />Friendly host & Fast support</li>
                                <li style={{display: 'flex', alignItems: 'center', color: '#5C5959', fontSize: '14px'}}><img src={groupSvg} style={{marginRight: '5px'}} />List your own property</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default HomePageAboutUs
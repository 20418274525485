import React from "react";
import logo from '../image/logo-divine.png'
import SellerSidebar from "./SellerSidebar";
import $ from 'jquery'
import { Link } from "react-router-dom";


const SellerDashboardHeader = () => {

    function handleSidebar() {
        $('.sidebar-panel').toggle('slow')
    }
    return (
        <>
            <div className="seller-header-panel for-desktop">
                <div className="container">
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                        <Link to='/seller-dashboard'><img src={logo} style={{ width: '160px' }} /></Link>
                        <h2>Welcome to the Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="seller-header-panel for-mobile" onClick={handleSidebar} style={{width: '100%'}}>
                <div className="container">
                    <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <img src={logo} style={{ width: '160px', zIndex: '99992' }} />
                        {/* <h2>Welcome to the Dashboard</h2> */}
                        <div class="mmenu-trigger" style={{ zIndex: '99992' }}>
                            <button class="hamburger hamburger--collapse" type="button">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <nav id="" class="style-1 for-mobile sidebar-panel" style={{minHeight: '400px', display: 'none'}}>
                <div className="d-flex" style={{ padding: '20px 35px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <img src="/static/media/logo-divine.82611769cc704db91909.png" style={{ width: "100px" }} />
                    <button style={{ float: 'right', border: 'none', width: '30px', height: '30px', borderRadius: "4px" }} onClick={handleSidebar}>X</button>
                </div>
                <div className="container p-4">
                    <div className="row">
                        <div className="col-6 mb-4">
                            <Link to='/seller-dashboard' style={{ color: 'black' }}>
                                <div className="seller-sidebar-panel" style={{ borderRadius: '5px', boxShadow: '0px 10px 5px 0px rgba(221, 221, 221, 1)', minHeight: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div><i style={{ color: '#C07F00' }} aria-hidden='true' ></i></div>
                                    <div style={{ color: '#C07F00', fontSize: '20px' }}>Home</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 mb-4">
                            <Link to='/agent-add-property' style={{ color: 'black' }}>
                                <div className="seller-sidebar-panel" style={{ borderRadius: '5px', boxShadow: '0px 10px 5px 0px rgba(221, 221, 221, 1)', minHeight: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div><i style={{ color: '#C07F00' }} aria-hidden='true' ></i></div>
                                    <div style={{ color: '#C07F00', fontSize: '20px' }}>Add Property</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6">
                            <Link to='/seller-listing' style={{ color: 'black' }}>
                                <div className="seller-sidebar-panel" style={{ borderRadius: '5px', boxShadow: '0px 10px 5px 0px rgba(221, 221, 221, 1)', minHeight: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div><i style={{ color: '#C07F00' }} aria-hidden='true' ></i></div>
                                    <div style={{ color: '#C07F00', fontSize: '20px' }}>Properties Listing</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6">
                            <Link to='/seller-login' style={{ color: 'black' }}>
                                <div className="seller-sidebar-panel" style={{ borderRadius: '5px', boxShadow: '0px 10px 5px 0px rgba(221, 221, 221, 1)', minHeight: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div><i style={{ color: '#C07F00' }} aria-hidden='true' ></i></div>
                                    <div style={{ color: '#C07F00', fontSize: '20px' }}>Logout</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </nav>
        </>
    )
}
export default SellerDashboardHeader
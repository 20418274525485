import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from '../axiosInstance'

const columns = [
    {
        name: "User ID",
        selector: (row) => row.id,
    },
    {
        name: "Amount",
        selector: (row) => "$" + row.Amount,
    },
    {
        name: "Date",
        selector: (row) => moment(row.createdon).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
        name: "Remarks",
        selector: (row) => (row.remarks == null ? null : row.remarks),
    },
];

const DirectIncomeUsers = () => {
    const [data, setData] = useState()

    useEffect(() => {
        const token = sessionStorage.getItem("token")
        axios
            .post(
                `api/users/reports`,
                {
                    "type": "1"
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result?.rows)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])
    return (
        <>
            <UserHeader />
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title" style={{ textAlign: 'left' }}>Direct Income Report</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive export-button-table">
                                <DataTable
                                    pagination={true}
                                    columns={columns}
                                    data={data}
                                    paginationServer={true}
                                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DirectIncomeUsers
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import img5 from "../image/5.svg";
import img6 from "../image/6.svg";
import img7 from "../image/7.svg";
import img8 from "../image/8.svg";
import naveen from "../image/naveen.jpg";
import chander from "../image/chander.jpeg";
import nitin from "../image/nitin.jpeg";
import about from "../image/about-pages.webp";
import banner from "../image/about-us-banner.webp";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";
import HomeFooter from "./HomeFooter";

const AboutUsPage = () => {
  return (
    <>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <section
            className="headings about-page-heading"
            title="divine-bricks-about-us"
            alt="divine-bricks-about-us"
            style={{
              background: "url(" + about + ") center top no-repeat",
              backgroundSize: "100%",
              height: "200px",
            }}
          >
            <div
              className="text-heading text-center"
              style={{ paddingTop: "80px" }}
            >
              <div className="container">
                <h2>About Our Company</h2>
              </div>
            </div>
          </section>
          <section className="about-us fh mb-5">
            <div className="container">
              <div className="row mt-5 mb-5">
                <div className="col-lg-6 col-md-12 who-1">
                  <div>
                    <h2 className="text-left mb-4">
                      About{" "}
                      <span style={{ color: "rgb(190, 153, 81)" }}>
                        Divine Bricks
                      </span>
                    </h2>
                  </div>
                  <div className="pftext">
                    <p>
                      Divine Bricks Real Estate is Crafting Dreams into Reality.
                      Our mission is to create exceptional living spaces that
                      inspire and elevate the quality of life.
                    </p>

                    <p>
                      Our Vision is Building communities that stand the test of
                      time, driven by innovation and sustainability. Core Values
                      Integrity, Quality, Customer-Centricity, Sustainability.
                      Service Spectrum, Exclusive property management with top
                      developers in Dubai LIKE DAMAC,SHOBHA ETC..
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-xs-12">
                  <div
                    className="wprt-image-video w50"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={banner}
                      title="about-divine-bricks"
                      alt="about-divine-bricks"
                      style={{ height: "200px", borderRadius: "5px" }}
                    />
                    <a
                      className=""
                      target="_blank"
                      href="https://www.youtube.com/channel/UC8M-SboOvquw6JKnLJk4h4w"
                      style={{ position: "absolute", top: "40%", left: "48%" }}
                    >
                      <i
                        className="fa fa-play"
                        style={{ fontSize: "40px", color: "white" }}
                      ></i>
                    </a>
                    <div className="iq-waves">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="info-help h18">
              <div className="container">
                <div className="row info-head">
                  <div className="col-lg-12 col-md-8 col-xs-8">
                    <div className="info-text">
                      <h3 className="text-center mb-0">Why Choose Us</h3>
                      <p className="text-center mb-4 p-0">
                        We offer perfect real estate services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              _ngcontent-bgi-c3=""
              className="featured-boxes-area bg-white-1"
            >
              <div _ngcontent-bgi-c3="" className="container-fluid">
                <div _ngcontent-bgi-c3="" className="featured-boxes-inner">
                  <div _ngcontent-bgi-c3="" className="row m-0">
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="250"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon color-fb7756">
                          <img
                            src={img5}
                            alt="divine-bricks-find-home"
                            title="divine-bricks-find-home"
                            width="85"
                            height="85"
                          />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          Find Your Home
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Our Mission: Your Perfect Home</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          Explore a wide range of properties, from cozy
                          apartments to spacious family homes. Our extensive
                          listings cover diverse neighborhoods, ensuring you
                          have a variety of options to choose from.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="350"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon color-facd60">
                          <img src={img6} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          Trusted by thousands
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Your Trust, Our Commitment</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          Your trust is the foundation of our success. At Divine
                          Bricks, we are dedicated to maintaining the highest
                          standards of professionalism and reliability.
                          Experience the difference of working with a team you
                          can trust.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="450"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon color-1ac0c6">
                          <img src={img7} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          Financing made easy
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Financing Made Easy with Divine Bricks</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          Buying a home is a significant investment, and at
                          Divine Bricks, we understand that navigating the
                          financial aspect can be a crucial part of your
                          journey. That's why we've made financing your dream
                          home easy and stress-free.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="550"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon">
                          <img src={img8} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          24/7 support
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Your Convenience is Our Priority</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          At Divine Bricks, we understand that your schedule and
                          needs are unique. Our commitment to 24/7 support
                          reflects our dedication to ensuring that your
                          home-finding journey is not only successful but also
                          convenient and stress-free.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <section className="team">
            <div className="container">
              <h2 style={{ color: "rgb(190, 153, 81)" }}>Our Team</h2>
              <h5>
                Meet the dedicated individuals who make Divine Bricks a leader
                in the real estate industry.
              </h5>
              <div
                className="row team-all"
                style={{
                  marginTop: "40px",
                  marginBottom: "0px",
                  justifyContent: "space-around",
                }}
              >
                <div
                  className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3"
                  data-aos-delay="250"
                >
                  <div className="inner-box team-details">
                    <div className="image team-head">
                      <a href="#">
                        <img
                          src={naveen}
                          style={{ maxHeight: "320px" }}
                          alt="divine-bricks-relationship-manager"
                          title="divine-bricks-relationship-manager"
                        />
                      </a>
                      <div className="team-hover"></div>
                    </div>
                    <div className="lower-box">
                      <h3>
                        <a href="#">Naveen Sood</a>
                      </h3>
                      <div className="designation">Relationship Manager</div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3"
                  data-aos-delay="350"
                >
                  <div className="inner-box team-details">
                    <div className="image team-head">
                      <a href="#">
                        <img
                          src={chander}
                          style={{ maxHeight: "320px", height: "320px" }}
                          alt="divine-bricks-relationship-manager"
                          title="divine-bricks-relationship-manager"
                        />
                      </a>
                      <div className="team-hover"></div>
                    </div>
                    <div className="lower-box">
                      <h3>
                        <a href="#">Chander Garg</a>
                      </h3>
                      <div className="designation">Relationship Manager</div>
                    </div>
                  </div>
                </div>
                <div
                  className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3 pb-none"
                  data-aos-delay="450"
                >
                  <div className="inner-box team-details">
                    <div className="image team-head">
                      <a href="#">
                        <img
                          src={nitin}
                          style={{ maxHeight: "320px" }}
                          alt="divine-bricks-relationship-manager"
                          title="divine-bricks-relationship-manager"
                        />
                      </a>
                      <div className="team-hover"></div>
                    </div>
                    <div className="lower-box">
                      <h3>
                        <a href="#">Nitin Garg</a>
                      </h3>
                      <div className="designation">Relationship Manager</div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default AboutUsPage;

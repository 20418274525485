import React, { useEffect, useState } from "react";
import SellerDashboardHeader from "./SellerDashboardHeader";
import SellerSidebar from "./SellerSidebar";
import userIcon from '../image/user-icon.png'
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import $ from 'jquery'

const SellerProfile = () => {
    const [name, setName] = useState()
    const [password, setPassword] = useState()
    const [mobile, setMobile] = useState()
    const [address, setAddress] = useState()
    const [city, setCity] = useState()
    const [district, setDistrict] = useState()
    const [state, setState] = useState()
    const [country, setCountry] = useState()
    const [pincode, setPincode] = useState()
    const [attachment, setAttachment] = useState()
    const [attachmentNo, setAttachmentNo] = useState()
    const [agentDetails, setAgentDetails] = useState()
    const [visible, setVisible] = useState(false)

    const token = sessionStorage.getItem("token")
    useEffect(() => {
        fetchData()
    }, [])
    function fetchData() {
        axios
        .get(
            `api/users/get-user-details`,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        )
        .then((resp) => {
            setAgentDetails(resp.data.result)
            setName(resp.data.result.name)
            setMobile(resp.data.result.mobile_no)
            setAddress(resp.data.result.address)
            setDistrict(resp.data.result.district)
            setCity(resp.data.result.city)
            setState(resp.data.result.state)
            setCountry(resp.data.result.country)
            setPincode(resp.data.result.pincode)
            setAttachment(resp.data.result.attachment)
            setAttachmentNo(resp.data.result.attachmentNo)
        })
        .catch((err) => {
            console.log(err)
        });
    }


    const update = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/agent-profile-update`,
                {
                    name: name,
                    password: password,
                    mobile_no: mobile,
                    address: address,
                    city: city,
                    district: district,
                    state: state,
                    country: country,
                    pincode: pincode,
                    attachment: attachment,
                    attachmentNo: attachmentNo,
                },{
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's1' })
                    fetchData()
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }

    const imageUpload1 = async (event) => {
        var output1 = document.getElementById('output-attachment');
        output1.src = URL.createObjectURL(event.target.files[0]);
        output1.onload = function () {
            URL.revokeObjectURL(output1.src) // free memory
        }
        await uploadFile1(event.target.files[0])
    }

    function uploadFile1(file) {
        setVisible(true)
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-document`,
            formData,
            {
                headers: {
                    "x-access-token": token,
                },
            },
            {
                timeout: 1000 * 2
            }
        ).
            then((resp) => {
                setVisible(false)
                $('#output-attachment').css('display', 'block')
                setAttachment(resp.data.result.image_url)
            }).catch((err) => { alert(err.network); setVisible(false); });
    }

    return (
        <>
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <div style={{ height: '100%', background: '#F1EFDC', paddingBottom: '20px' }}>
                <div className="row">
                    <SellerDashboardHeader />
                    <div className="d-flex" style={{ width: '100%' }}>
                        <SellerSidebar />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="seller-body-content" style={{margin: '5px'}}>
                                        <div className="row">
                                            <div className="col-md-9 col-mb-0 mb-4">
                                                <div className="card">
                                                    <div className="card-header" style={{ textAlign: 'left', fontWeight: '600' }}>
                                                        Personal Information
                                                    </div>
                                                    <div className="card-content seller-personal-info p-3" style={{ background: '#F9FBFC' }}>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-2">
                                                                <label>Name</label>
                                                                <input type="text" className="form-control" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Mobile No.</label>
                                                                <input type="text" className="form-control" placeholder="Enter Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <label>Address</label>
                                                                <input type="text" className="form-control" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>District</label>
                                                                <input type="text" className="form-control" placeholder="Enter District" value={district} onChange={(e) => setDistrict(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <label>City</label>
                                                                <input type="text" className="form-control" placeholder="Enter City" value={city} onChange={(e) => setCity(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <label>State</label>
                                                                <input type="text" className="form-control" placeholder="Enter State" value={state} onChange={(e) => setState(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Country</label>
                                                                <input type="text" className="form-control" placeholder="Enter Country" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <label>Pincode</label>
                                                                <input type="text" className="form-control" placeholder="Enter Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Attachment Details</label>
                                                                <input type="text" className="form-control" placeholder="Attachment Details" value={attachmentNo} onChange={(e) => setAttachmentNo(e.target.value)} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Attachment Image</label>
                                                                <input type="file" className="form-control" placeholder="Attachment" onChange={imageUpload1} />
                                                                <img id="output-attachment" src={ "https://api.divinebricks.com" + attachment } alt="your image" style={{ height: '50px', width: '50px', marginTop: '10px', objectFit: 'cover' }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-md-12" style={{ textAlign: 'left' }}>
                                                                <button className="btn btn-danger" onClick={update}>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="card">
                                                    <div className="card-header" style={{ textAlign: 'left', fontWeight: '600' }}>
                                                        Personal Information
                                                    </div>
                                                    <div className="card-content p-4" style={{ background: '#F9FBFC' }}>
                                                        <div className="d-flex" style={{ alignItems: 'center' }}>
                                                            <img src={userIcon} style={{ width: '100px' }} />
                                                            <div>
                                                                Edit your Photo
                                                                <div className="d-flex">
                                                                    <button style={{ background: 'none', border: 'none', color: 'red' }}>Delete</button>
                                                                    <button style={{ background: 'none', border: 'none', color: 'green' }}>Update</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <input type="file" className="form-control" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerProfile
import React from "react";
import { Link } from "react-router-dom";

const PropertyGrid = (data) => {
  return (
    <>
      <div class="agents-grid">
        <Link
          to={"/divine-bricks-properties/" + data?.data?.slug}
          title={data?.data?.name}
        >
          <div class="landscapes">
            <div class="project-single">
              <div class="project-inner project-head">
                <div class="homes">
                  <a
                    href={"/divine-bricks-properties/" + data?.data?.slug}
                    title={data?.data?.name}
                    className="homes-img"
                  >
                    {/* <div class="homes-tag button alt featured">Featured</div> */}
                    <div class="homes-tag button alt sale">
                      {data?.data?.typeArr}
                    </div>
                    <div class="homes-price">
                      AED {parseInt(data?.data?.price).toLocaleString()}
                    </div>
                    <img
                      src={"https://api.divinebricks.com/" + data?.data?.image}
                      alt={data?.data?.slug}
                      title={data?.data?.name}
                      class="img-responsive"
                      style={{ height: "194px" }}
                    />
                  </a>
                </div>
              </div>
              <div class="homes-content">
                <h3 className="name-dots">{data?.data?.name} </h3>
                <p class="homes-address mb-3">
                  <a
                    href={"/property-details?id=" + data?.data?.id}
                    title={data?.data?.name}
                    className="name-dots"
                  >
                    <i class="fa fa-map-marker"></i>
                    <span>{data?.data?.address}</span>
                  </a>
                </p>
                <ul class="homes-list clearfix pb-0">
                  <li class="the-icons">
                    <i class="flaticon-bed mr-2" aria-hidden="true"></i>
                    <span>
                      <strong>{data?.data?.min_beds}</strong> Bedrooms
                    </span>
                  </li>
                  <li class="the-icons">
                    <i class="flaticon-bathtub mr-2" aria-hidden="true"></i>
                    <span>
                      <strong>{data?.data?.min_bath}</strong> Bathrooms
                    </span>
                  </li>
                  <li class="the-icons">
                    <i class="flaticon-square mr-2" aria-hidden="true"></i>
                    <span>
                      <strong>{data?.data?.min_area}</strong> sq ft
                    </span>
                  </li>
                  <li class="the-icons">
                    <i class="flaticon-car mr-2" aria-hidden="true"></i>
                    <span>
                      Parking <strong>{data?.data?.parking}</strong>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
export default PropertyGrid;

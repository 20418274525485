import React, { useState, useEffect, useRef } from "react";
import logo from "../image/logo-divine.png";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import ReactSlider from "react-slider";
import axios from "../axiosInstance";
import Header from "./Header";
import Footer from "./Footer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropertyListingGrid from "./PropertyListingGrid";
import $ from "jquery";
import Modal from "react-modal";
import banner from "../image/banner_lagoon.webp";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";
import HomeFooter from "./HomeFooter";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 2 ? "#f00" : props.index === 1 ? "#0f0" : "#ddd"};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const StyledContainer = styled.div`
  resize: horizontal;
  overflow: auto;
  width: 50%;
  max-width: 100%;
  padding-right: 8px;
`;

const HomePropertyListing = () => {
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  const id = query.get("builderId");
  const id1 = query.get("typeArr");
  const keyword = query.get("keyword");
  const propertyType = query.get("typeArr");
  const propertyStatus = query.get("category");
  const bedroom = query.get("min_beds");
  const bathroom = query.get("min_bath");
  const size = query.get("min_area");
  const price = query.get("price");
  const amenities = query.get("ameties");
  const [propertyData, setPropertyData] = useState();
  const [keywordData, setKeywordData] = useState();
  const [propertyTypeData, setPropertyTypeData] = useState();
  const [propertyStatusData, setPropertyStatusData] = useState();
  const [bedroomData, setBedroomData] = useState();
  const [bathroomData, setBathroomData] = useState();
  const [sizeData, setSizeData] = useState();
  const [priceData, setPriceData] = useState();
  const [amenitiesData, setAmenitiesData] = useState([""]);
  const [show, setShow] = useState(false);
  const [typeList, setTypeList] = useState();
  const [showMore, setShowMore] = useState(4);
  const [viewType, setViewType] = useState("grid");
  const amenitiesList = useRef([]);
  useEffect(() => {
    fetchData();
  }, [location]);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function fetchData() {
    axios
      .get(`api/users/get-home-property-types`)
      .then((resp) => {
        setTypeList(resp.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
    setShow(true);
    axios
      .get(
        id != null
          ? `api/users/get-home-property-list?builderId=${id}`
          : `api/users/get-home-property-list?typeArr=${id1}`
      )
      .then((resp) => {
        setShow(false);
        setPropertyData(resp.data?.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`api/users/get-home-ameties-list`)
      .then((resp) => {
        amenitiesList.current = [];
        for (var i = 0; i < resp.data?.result.length; i++) {
          amenitiesList.current.push(resp.data?.result[i].name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function clearFilters() {
    axios
      .get(`api/users/get-home-property-list`)
      .then((resp) => {
        setShow(false);
        setPropertyData(resp.data?.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleView(view) {
    setViewType(view);
  }
  function search() {
    setShow(true);
    axios
      .get(
        id != null
          ? `api/users/get-home-property-list?builderId=${id ? id : ""}`
          : `api/users/get-home-property-list?keyword=${
              keywordData !== "undefined" && keywordData !== undefined
                ? keywordData
                : ""
            }&typeArr=${
              propertyTypeData !== "undefined" && propertyTypeData !== undefined
                ? propertyTypeData
                : ""
            }&category=${
              propertyStatusData !== "undefined" &&
              propertyStatusData !== undefined
                ? propertyStatusData
                : ""
            }&min_beds=${
              bedroomData !== "undefined" && bedroomData !== undefined
                ? bedroomData
                : ""
            }&min_bath=${
              bathroomData !== "undefined" && bathroomData !== undefined
                ? bathroomData
                : ""
            }&min_area=${
              sizeData !== "undefined" && sizeData !== undefined ? sizeData : ""
            }&ameties=${
              amenitiesData !== "undefined" && amenitiesData !== undefined
                ? amenitiesData
                : ""
            }&price=${
              priceData !== "undefined" && priceData !== undefined
                ? priceData
                : ""
            }`
      )
      .then((resp) => {
        setShow(false);
        setPropertyData(resp.data?.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleToggle(e) {
    const { value, checked } = e.target;
    if (checked) {
      setAmenitiesData((pre) => [...pre, value]);
    } else {
      setAmenitiesData((pre) => {
        return [...pre.filter((skill) => skill !== value)];
      });
    }
  }
  // function submitSearch() {
  //     axios
  //         .post(
  //             `api/users/login`,
  //             {
  //                 keyword: keyword,
  //                 typeArr: propertyData,
  //                 category: propertyStatus,
  //                 min_beds: bedroom,
  //                 min_bath: bathroom,
  //                 min_area: size,
  //                 ameties: amenities,
  //             },
  //         )
  //         .then((resp) => {
  //             if (!resp.data?.success) {
  //             }
  //             if (resp.data?.success) {
  //                 setTimeout(() => {
  //                     if (resp.data?.status) {
  //                     }
  //                 }, 2000);
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         });
  // }
  return (
    <>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <div className="clearfix"></div>

          <section className="properties-right featured portfolio blog pt-5">
            <div className="container">
              <section className="headings-2 pt-0 pb-4">
                <div className="pro-wrapper">
                  <div className="detail-wrapper-body w-100">
                    <div
                      className="listing-title-bar"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <div className="text-heading text-left">
                                                <p className="pb-2"><Link to='/'>Home</Link> &nbsp;/&nbsp; <span>Listings</span></p>
                                            </div> */}
                      <h3>Properties</h3>
                      <div
                        className="btn-group custom-butons-grp"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleView("grid")}
                        >
                          <i class="fas fa-grip-horizontal"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleView("list")}
                        >
                          <i className="fa fa-th-list"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="row">
                <aside className="col-lg-4 col-md-12 car for-desktop">
                  <div className="widget">
                    <div className="widget-boxed">
                      <div
                        className="widget-boxed-header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h4>Find Your House</h4>
                        <button
                          className="btn"
                          style={{ color: "white", background: "black" }}
                          onClick={clearFilters}
                        >
                          Clear Filters
                        </button>
                      </div>
                      <div className="trip-search">
                        <form className="form">
                          <div className="form-group looking">
                            <div className="first-select wide">
                              <div className="main-search-input-item">
                                <input
                                  type="text"
                                  placeholder="Enter Keyword..."
                                  value={keywordData}
                                  onChange={(e) =>
                                    setKeywordData(e.target.value)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group categories mb-3">
                            <select
                              className="form-control"
                              value={propertyStatusData}
                              onChange={(e) =>
                                setPropertyStatusData(e.target.value)
                              }
                            >
                              <option selected disabled>
                                Select Property Status
                              </option>
                              <option value="Rent">For Rent</option>
                              <option value="Sale">For Sale</option>
                            </select>
                          </div>
                          <div className="form-group categories mb-3">
                            <select
                              className="form-control"
                              value={propertyTypeData}
                              onChange={(e) =>
                                setPropertyTypeData(e.target.value)
                              }
                            >
                              <option selected disabled>
                                Property Type
                              </option>
                              {typeList?.map((data) => {
                                return (
                                  <option value={data?.name}>
                                    {data?.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="form-group beds mb-3">
                            <select
                              className="form-control"
                              value={bedroomData}
                              onChange={(e) => setBedroomData(e.target.value)}
                            >
                              <option selected disabled>
                                Select Bedrooms
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <div className="form-group bath">
                            <select
                              className="form-control"
                              value={bathroomData}
                              onChange={(e) => setBathroomData(e.target.value)}
                            >
                              <option selected disabled>
                                Select Bathroom
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </form>
                      </div>
                      <div className="main-search-field-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 py-1 pr-30 mr-5 sld d-xl-flex">
                          <div className="main-search-field-2 w-100">
                            <div className="range-slider">
                              <label>Area Size</label>
                              <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="example-thumb"
                                max={5000}
                                trackClassName="example-track"
                                onBeforeChange={(value, index) =>
                                  console.log(
                                    `onBeforeChange: ${JSON.stringify({
                                      value,
                                      index,
                                    })}`
                                  )
                                }
                                onChange={(value, index) =>
                                  console.log(
                                    `onChange: ${JSON.stringify({
                                      value,
                                      index,
                                    })}`
                                  )
                                }
                                onAfterChange={
                                  (value, index) => setSizeData(value)
                                  // console.log(`onAfterChange: ${JSON.stringify({ value, index })}`)
                                }
                                renderThumb={(props, state) => (
                                  <div {...props}>{state.valueNow}</div>
                                )}
                              />

                              <div
                                className="d-flex mt-4"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p>Min 0 Sq. Ft</p> <p>-</p>{" "}
                                <p>Max 5,000 Sq. Ft</p>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                            <br />
                            <div className="range-slider">
                              <label>Price Range</label>
                              <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="example-thumb"
                                max={10000000}
                                trackClassName="example-track"
                                onBeforeChange={(value, index) =>
                                  console.log(
                                    `onBeforeChange: ${JSON.stringify({
                                      value,
                                      index,
                                    })}`
                                  )
                                }
                                onChange={(value, index) =>
                                  console.log(
                                    `onChange: ${JSON.stringify({
                                      value,
                                      index,
                                    })}`
                                  )
                                }
                                onAfterChange={
                                  (value, index) => setPriceData(value)
                                  // console.log(`onAfterChange: ${JSON.stringify({ value, index })}`)
                                }
                                renderThumb={(props, state) => (
                                  <div {...props}>{state.valueNow}</div>
                                )}
                              />
                              <div
                                className="d-flex mt-4"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p>Min AED 0</p> <p>-</p>{" "}
                                <p>Max AED 1,00,00,000</p>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {/* <div className="range-slider mb-4">
                                                    <label>Price Range</label>
                                                    <StyledContainer style={{ resize: 'none', width: '100%' }}>
                                                        <StyledSlider defaultValue={[2, 75]} renderTrack={Track} renderThumb={Thumb} />
                                                    </StyledContainer>
                                                    <div className="clearfix"></div>
                                                </div> */}
                      </div>
                      <a
                        href="#"
                        className="more-search-options-trigger margin-bottom-10 margin-top-30"
                        data-open-title="Advanced Features"
                        data-close-title="Advanced Features"
                      ></a>

                      <div className="more-search-options relative">
                        <div className="checkboxes one-in-row margin-bottom-10">
                          {amenitiesList.current
                            ?.slice(0, showMore)
                            .map((data) => {
                              return (
                                <>
                                  <div>
                                    <input
                                      id={data}
                                      type="checkbox"
                                      name="check"
                                      value={data}
                                      onChange={handleToggle}
                                    />
                                    <label for={data}>{data}</label>
                                  </div>
                                </>
                              );
                            })}
                          {showMore == 4 ? (
                            <button
                              className="btn btn-primary mt-3"
                              onClick={() => setShowMore(20)}
                            >
                              Show More +
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary mt-3"
                              onClick={() => setShowMore(4)}
                            >
                              Show Less -
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 no-pds">
                        <div className="at-col-default-mar">
                          <button
                            className="btn btn-yellow w-100"
                            type="submit"
                            style={{ height: "40px", marginTop: "20px" }}
                            onClick={search}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <img
                      src={banner}
                      title="divine-bricks-damac-lagoons"
                      alt="divine-bricks-damac-lagoons"
                      style={{ marginTop: "20px", borderRadius: "4px" }}
                    />
                  </div>
                </aside>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div style={{ textAlign: "right", marginBottom: "10px" }}>
                    <button className="btn btn-primary" onClick={closeModal}>
                      close
                    </button>
                  </div>
                  <aside className="col-lg-4 col-md-12 car">
                    <div className="widget">
                      <div className="widget-boxed">
                        <div
                          className="widget-boxed-header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h4>Find Your House</h4>
                          <button
                            className="btn btn-warning"
                            style={{ color: "white" }}
                            onClick={clearFilters}
                          >
                            Clear Filters
                          </button>
                        </div>
                        <div className="trip-search">
                          <form className="form">
                            <div className="form-group looking">
                              <div className="first-select wide">
                                <div className="main-search-input-item">
                                  <input
                                    type="text"
                                    placeholder="Enter Keyword..."
                                    value={keywordData}
                                    onChange={(e) =>
                                      setKeywordData(e.target.value)
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group categories mb-3">
                              <select
                                className="form-control"
                                value={propertyStatusData}
                                onChange={(e) =>
                                  setPropertyStatusData(e.target.value)
                                }
                              >
                                <option selected disabled>
                                  Select Property Status
                                </option>
                                <option value="Rent">For Rent</option>
                                <option value="Sale">For Sale</option>
                              </select>
                            </div>
                            <div className="form-group categories mb-3">
                              <select
                                className="form-control"
                                value={propertyTypeData}
                                onChange={(e) =>
                                  setPropertyTypeData(e.target.value)
                                }
                              >
                                <option selected disabled>
                                  Property Type
                                </option>
                                {typeList?.map((data) => {
                                  return (
                                    <option value={data?.name}>
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group beds mb-3">
                              <select
                                className="form-control"
                                value={bedroomData}
                                onChange={(e) => setBedroomData(e.target.value)}
                              >
                                <option selected disabled>
                                  Select Bedrooms
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                            <div className="form-group bath">
                              <select
                                className="form-control"
                                value={bathroomData}
                                onChange={(e) =>
                                  setBathroomData(e.target.value)
                                }
                              >
                                <option selected disabled>
                                  Select Bathroom
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                          </form>
                        </div>
                        <div className="main-search-field-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 py-1 pr-30 mr-5 sld d-xl-flex">
                            <div className="main-search-field-2 w-100">
                              <div className="range-slider">
                                <label>Area Size</label>
                                <ReactSlider
                                  className="horizontal-slider"
                                  thumbClassName="example-thumb"
                                  max={5000}
                                  trackClassName="example-track"
                                  onBeforeChange={(value, index) =>
                                    console.log(
                                      `onBeforeChange: ${JSON.stringify({
                                        value,
                                        index,
                                      })}`
                                    )
                                  }
                                  onChange={(value, index) =>
                                    console.log(
                                      `onChange: ${JSON.stringify({
                                        value,
                                        index,
                                      })}`
                                    )
                                  }
                                  onAfterChange={
                                    (value, index) => setSizeData(value)
                                    // console.log(`onAfterChange: ${JSON.stringify({ value, index })}`)
                                  }
                                  renderThumb={(props, state) => (
                                    <div {...props}>{state.valueNow}</div>
                                  )}
                                />

                                <div
                                  className="d-flex mt-4"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <p>Min 0 Sq. Ft</p> <p>-</p>{" "}
                                  <p>Max 5000 Sq. Ft</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <br />
                              <div className="range-slider">
                                <label>Price Range</label>
                                <ReactSlider
                                  className="horizontal-slider"
                                  thumbClassName="example-thumb"
                                  max={100000}
                                  trackClassName="example-track"
                                  onBeforeChange={(value, index) =>
                                    console.log(
                                      `onBeforeChange: ${JSON.stringify({
                                        value,
                                        index,
                                      })}`
                                    )
                                  }
                                  onChange={(value, index) =>
                                    console.log(
                                      `onChange: ${JSON.stringify({
                                        value,
                                        index,
                                      })}`
                                    )
                                  }
                                  onAfterChange={(value, index) =>
                                    setPriceData(value)
                                  }
                                  renderThumb={(props, state) => (
                                    <div {...props}>{state.valueNow}</div>
                                  )}
                                />
                                <div
                                  className="d-flex mt-4"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <p>Min AED0</p> <p>-</p> <p>Max AED100000</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                            </div>
                          </div>
                          <br />
                          {/* <div className="range-slider mb-4">
                                                    <label>Price Range</label>
                                                    <StyledContainer style={{ resize: 'none', width: '100%' }}>
                                                        <StyledSlider defaultValue={[2, 75]} renderTrack={Track} renderThumb={Thumb} />
                                                    </StyledContainer>
                                                    <div className="clearfix"></div>
                                                </div> */}
                        </div>
                        <a
                          href="#"
                          className="more-search-options-trigger margin-bottom-10 margin-top-30"
                          data-open-title="Advanced Features"
                          data-close-title="Advanced Features"
                        ></a>

                        <div className="more-search-options relative">
                          <div className="checkboxes one-in-row margin-bottom-10">
                            {amenitiesList.current?.map((data) => {
                              return (
                                <>
                                  <div>
                                    <input
                                      id={data}
                                      type="checkbox"
                                      name="check"
                                      value={data}
                                      onChange={handleToggle}
                                    />
                                    <label for={data}>{data}</label>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-lg-12 no-pds">
                          <div className="at-col-default-mar">
                            <button
                              className="btn btn-yellow w-100"
                              type="submit"
                              style={{ height: "40px", marginTop: "20px" }}
                              onClick={search}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </Modal>

                <div className="col-lg-8 col-md-12 blog-pots">
                  <section className="headings-2 pt-0 pb-2">
                    <div className="pro-wrapper">
                      <div className="detail-wrapper-body">
                        <div className="listing-title-bar">
                          <div className="text-heading text-left">
                            <p className="font-weight-bold mb-0">
                              {propertyData?.length} Search results
                            </p>
                            <div
                              className="for-mobile"
                              style={{ textAlign: "right", width: "100%" }}
                            >
                              <button
                                className="btn btn-primary"
                                onClick={openModal}
                                style={{ float: "right", marginRight: "16px" }}
                              >
                                Filters
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="cod-pad single detail-wrapper mr-2 mt-0 d-flex justify-content-md-end align-items-center grid">
                                    <div className="input-group border rounded input-group-lg w-auto mr-4">
                                        <label className="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3" for="inputGroupSelect01"><i className="fas fa-align-left fs-16 pr-2"></i>Sortby:</label>
                                        <select className="form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby" data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3" id="inputGroupSelect01" name="sortby" style={{display: "none"}}>
                                            <option selected="">Top Selling</option>
                                            <option value="1">Most Viewed</option>
                                            <option value="2">Price(low to high)</option>
                                            <option value="3">Price(high to low)</option>
                                        </select><div className="nice-select form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby" tabIndex="0"><span className="current">Top Selling</span><ul className="list"><li data-value="Top Selling" className="option selected">Top Selling</li><li data-value="1" className="option">Most Viewed</li><li data-value="2" className="option">Price(low to high)</li><li data-value="3" className="option">Price(high to low)</li></ul></div>
                                    </div>
                                    <div className="sorting-options">
                                        <a href="properties-list-1.html" className="change-view-btn lde"><i className="fa fa-th-list"></i></a>
                                        <a href="#" className="change-view-btn active-view-btn"><i className="fa fa-th-large"></i></a>
                                    </div>
                                </div> */}
                    </div>
                  </section>
                  {show ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card p-4">
                            <Skeleton count="1" width="100%" />
                            <div
                              className="card-body pt-2 pl-0"
                              style={{ textAlign: "left" }}
                            >
                              <Skeleton count="1" width="100%" />
                              <Skeleton count="1" width="100%" />
                              <Skeleton count="1" width="100%" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        {propertyData?.map((data) => {
                          return (
                            <PropertyListingGrid data={data} view={viewType} />
                          );
                        })}
                      </div>{" "}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default HomePropertyListing;

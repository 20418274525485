import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import builders from "../image/builders.svg";
import sidebanner from "../image/divine_ad.webp";
import axios from "../axiosInstance.js";
import { Link } from "react-router-dom";

const HomePageBuilder = () => {
  const [builderData, setBuilderData] = useState();
  useEffect(() => {
    axios
      .get(`api/users/get-home-builder-list`)
      .then((resp) => {
        setBuilderData(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const CustomButtonGroupAsArrows = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <div
        className="carousel-button-group"
        style={{ position: "absolute", top: 0, right: "85px" }}
      >
        <button
          aria-label="Go to previous slide"
          className={
            "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
          }
          onClick={() => previous()}
        ></button>
        <button
          aria-label="Go to next slide"
          className={
            "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          }
          onClick={() => next()}
        ></button>
      </div>
    );
  };
  return (
    <>
      <section
        className="builders-section"
        style={{ background: "#FDFAFA", padding: "40px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-9" style={{ textAlign: "left" }}>
              <h2 style={{ color: "rgb(92, 89, 89)" }}>Popular Builders</h2>
              <p>in Dubai, UAE</p>
              <div>
                {builderData ? (
                  <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className="carousel-item-padding-30-px"
                    containerClass="container-padding-bottom"
                    customButtonGroup={<CustomButtonGroupAsArrows />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 1,
                        partialVisibilityGutter: 30,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    {builderData?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="card homepage-builders glow-on-hover"
                        >
                          <img
                            src={"https://api.divinebricks.com" + data?.logo}
                            alt={data?.slug}
                            title={data?.name}
                            style={{ width: "80px", height: "80px" }}
                          />
                          <Link
                            to={
                              "/divine-bricks-property-listing?builderId=" +
                              data.slug
                            }
                            style={{ textDecoration: "none", color: "#5c5959" }}
                          >
                            <div>
                              <h4>{data?.name}</h4>
                              {data?.about}
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <img
                className="floating-image"
                title="divine-bricks"
                alt="divine-bricks"
                src={"/divine-bricks-side-banner.png"}
                style={{ borderRadius: "4px" }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomePageBuilder;

import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from "../axiosInstance";

const columns = [
    {
        name: "Property ID",
        selector: (row) => row.propertyId,
    },
    {
        name: "Property Name",
        selector: (row) => row.propertyData.name,
    },
    {
        name: "Amount",
        selector: (row) => "$" + row.amount,
    },
    {
        name: "EMI Date",
        selector: (row) => row.emi_date,
    },
    {
        name: "Date",
        selector: (row) => moment(row.createdon).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
        name: "Status",
        selector: (row) => <div className={row.is_status == 'Pending'? "badge badge-warning p-2" : row.is_status == 'Ontime'? "badge badge-success p-2" :  "badge badge-danger p-2" }>{row.is_status}</div>,
        width: 'auto'
    },
];

const EmiTransactions = () => {
    const [data, setData] = useState()
    const [page, setPage] = useState(1);
    const [perpage, setperPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const[pending, setPending] = useState(true)

    useEffect(() => {
        setPending(true)
        const token = sessionStorage.getItem("token")
        axios
            .post(
                `api/users/get-emi-list?page=${page}`,
                {
                    propertyId: null
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setPending(false)
                setData(resp.data.result.rows)
                setTotalRows(resp.data?.result?.count);
            })
            .catch((err) => {
                setPending(false)
                console.log(err)
            });
    }, [page, perpage])

    const handleRowsPerPageChange = (per_page) => {
        setperPage(per_page);
        setPage(1);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    return (
        <>
            <UserHeader />
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title" style={{ textAlign: 'left' }}>EMI Transaction Report for Property Id - {sessionStorage.getItem("myProperties")}</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive export-button-table">
                                <DataTable
                                    pagination={true}
                                    columns={columns}
                                    data={data}
                                    paginationServer={true}
                                    paginationTotalRows={totalRows}
                                    progressPending={pending}
                                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmiTransactions
import React from "react";
import email from "../image/email.webp";

const HomeSubscribe = () => {
  return (
    <>
      <section className="subscribe-section" style={{ background: "rgb(252, 229, 179)", padding: "40px" }}>
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-3 col-4">
              {/* <div class="envelope open">
                <div class="flap front"></div>
                <div class="flap top"></div>
                <div class="letter">Subscribe</div>
              </div> */}
              <img src={email} style={{ maxWidth: "200px" }} />
            </div>
            <div className="col-md-5 col-8" style={{ textAlign: "left" }}>
              <p className="mobile-font-20" style={{ color: "#000", fontSize: "35px", fontWeight: "700" }}>
                Real Estate Updates
              </p>
              <p
                style={{
                  color: "rgb(143, 87, 38)",
                  fontSize: "23px",
                  fontWeight: "400",
                  marginBottom: '5px'
                }}
              >
                Be the first to know
              </p>
              <p
                style={{
                  color: "rgb(143, 87, 38)",
                  fontSize: "23px",
                  fontWeight: "400",
                }}
              >
                Get latest updates from Divine Bricks
              </p>
            </div>
            <div className="col-md-4">
              <div style={{border: '1px solid', padding: '5px 10px', borderRadius: '25px', color: '#000'}}>
                <form>
                  <div style={{display: 'flex'}}>
                    <input className="subscribe-input" type="email" placeholder="Enter your email address" style={{background: 'transparent', border: 'none', color: '#000', width: '100%'}} required />
                    <button style={{background: 'white', border: 'none', color: '#000', borderRadius: '25px', padding: '5px 10px'}}>Subscribe</button>
                  </div>
                </form>
              </div>
              {/* margin-on-hover */}
              {/* <button
                className="button-hover-style"
                style={{
                  background: "#9E6B3D",
                  color: "#fff",
                  borderRadius: "35px",
                  padding: "10px 40px",
                  border: "1px solid #000",
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Subscribe Now &rarr;
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeSubscribe;

import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import axios from "../../axiosInstance";
import UserHeader from "./../UserHeader";
import { toast } from "react-toastify";
import $ from "jquery";
import { ColorRing } from "react-loader-spinner";
import EditIcon from "@mui/icons-material/Edit";
const CategoryManager = () => {
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="">
          <img
            src={"https://api.divinebricks.com" + row?.img_url}
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <button
            data-toggle="modal"
            data-target="#exampleModal1"
            onClick={() => {
              setCurrentRow(row);
              setUpdateName("");
              setStatus("");
            }}
            style={{ border: "none", background: "transparent" }}
          >
            <EditIcon />
          </button>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Image
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "left" }}>
                      <input
                        className="form-control"
                        type="file"
                        id="myfile"
                        name="myfile"
                        required
                        onChange={imageUpload1}
                      />
                      <p className="text-danger">
                        <span>Allowed formats jpg|jpeg|webp|png</span>
                        <br />
                        <span>Image dimension:: 400x400px</span>
                        <br />
                        <span>Image size less than 1MB</span>
                      </p>
                      <img
                        id="output2"
                        src={
                          "https://api.divinebricks.com" + currentRow?.img_url
                        }
                        alt="your image"
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "10px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => editType(row)}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      width: "auto",
    },
  ];

  const [data, setData] = useState();
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [logo, setLogo] = useState();
  const [updateName, setUpdateName] = useState();
  const [updateImage, setUpdateImage] = useState();
  const [updateLogo, setUpdateLogo] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [status, setStatus] = useState();
  const [visible, setVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [formData, setFormData] = useState({
    perpage: 10,
    page: 1,
  });
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    fetchData();
  }, [formData.perpage, formData.page]);

  function fetchData() {
    axios
      .get(
        `api/admins/get-admin-gallery-list?type=Event&page=${formData.page}&perpage=${formData.perpage}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setData(resp.data.result.docs);
        setTotalRecords(resp.data.result.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const addType = () => {
    setVisible(true);
    axios
      .post(
        `api/admins/add-gallery`,
        {
          image: image,
          type: "Event",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editType = () => {
    setVisible(true);
    axios
      .post(
        `api/admins/update-gallery`,
        {
          galleryId: currentRow?.id.toString(),
          image: image != undefined ? image : currentRow?.img_url,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const imageUpload1 = async (event) => {
    let image = event.target.files[0];
    if (!image) {
      toast.error("Image is required..");
      return false;
    } else if (!image.name.match(/\.(jpg|jpeg|webp|png)$/)) {
      toast.error("Allow only jpg|jpeg|webp|png format images..");
      return false;
    } else {
      await uploadFile2(image);
    }
  };
  const imageUpload2 = async (event) => {
    let image = event.target.files[0];
    if (!image) {
      toast.error("Image is required..");
      return false;
    } else if (!image.name.match(/\.(jpg|jpeg|webp|png)$/)) {
      toast.error("Allow only jpg|jpeg|webp|png format images..");
      return false;
    } else {
      await uploadFile2(image);
    }
  };

  const handlePageChange = (page) => {
    setFormData({ ...formData, page: page });
  };

  const handlePerRowsChange = (newPerPage) => {
    setFormData({ ...formData, perpage: newPerPage, page: 1 });
  };

  function uploadFile2(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    axios
      .post(`api/admins/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          setImage(resp.data.result?.image_url);
        } else {
          toast.error(resp.data.msg);
        }
      })
      .catch((err) => {
        console.log("eerr", err);
        toast.error(err.message);
      });
  }
  return (
    <>
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <UserHeader />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div
              className="card-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Gallery List
              </h4>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Add Image
              </button>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationPerPage={formData.perpage}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  paginationTotalRows={totalRecords}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Image
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="file"
                      id="myfile"
                      name="myfile"
                      className="form-control"
                      required
                      onChange={imageUpload2}
                    />
                  </div>
                  <div className="col-md-12" style={{ textAlign: "left" }}>
                    <p className="text-danger">
                      <span>Allowed formats jpg|jpeg|webp|png</span>
                      <br />
                      <span>Image dimension:: 400x400px</span>
                      <br />
                      <span>Image size less than 1MB</span>
                    </p>
                    <img
                      id="output1"
                      src={"https://api.divinebricks.com" + image}
                      alt="your image"
                      className="form-control"
                      style={{
                        height: "100px",
                        width: "100px",
                        marginTop: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addType}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryManager;

import React, { useEffect, useState } from "react";
import logo from "../image/logo-divine.png";
import { Link } from "react-router-dom";
import axios from "../axiosInstance.js";
import $ from "jquery";
import MobileHeader from "./MobileHeader.js";

const HomePageHeader = () => {
  const [builderData, setBuilderData] = useState();
  const [typeList, setTypeList] = useState();
  useEffect(() => {
    axios
      .get(`api/users/get-home-builder-list`)
      .then((resp) => {
        setBuilderData(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-home-property-types`)
      .then((resp) => {
        setTypeList(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function toggleSidebar() {
    $(".header-for-mobile-ul").toggle();
  }

  $(window).scroll(function () {
    var sticky = $(".sticky-top-header"),
      scroll = $(window).scrollTop();
    ///console.log(scroll, "scroll test");
    if (scroll > 100) {
      sticky.addClass("fixed-header-top");
    } else if (scroll < 80) {
      sticky.removeClass("fixed-header-top");
    }
  });

  // document.addEventListener('DOMContentLoaded', function () {
  //   var header = $('.sticky-top-header');
  //   var headerOffset = 100; // Threshold to add the class
  //   var isFixed = false;

  //   function stickyHeader() {
  //     console.log('hi')
  //     if (window.scrollY >= headerOffset && !isFixed) {
  //       header.classList.add('fixed-header-top');
  //       isFixed = true;
  //     } else if (window.scrollY < headerOffset && isFixed) {
  //       header.classList.remove('fixed-header-top');
  //       isFixed = false;
  //     }
  //   }

  //   window.addEventListener('scroll', stickyHeader);
  // });

  return (
    <>
      <section
        style={{
          background: "white",
          height: "70px",
          display: "flex",
          alignItems: "center",
        }}
        id="sticky-top-header"
        className="sticky-top-header for-desktop"
      >
        <div className="container">
          <ul
            style={{
              listStyleType: "none",
              display: "flex",
              marginLeft: "auto",
            }}
            className="homepage-header"
          >
            <li>
              <Link to="/">
                <img
                  src={logo}
                  title="divine-bricks"
                  alt="divine-bricks"
                  style={{ width: "125px", height: "43px" }}
                />
              </Link>
            </li>
            <li className="custom-dropdown" style={{ marginLeft: "auto" }}>
              <button className="custom-dropbtn">Buyer</button>
              <div className="custom-dropdown-content">
                {typeList?.map((data, index) => {
                  return (
                    <Link
                      key={index}
                      to={
                        "/divine-bricks-property-listing?typeArr=" + data?.slug
                      }
                      style={{ width: "50%" }}
                    >
                      {data?.name}
                    </Link>
                  );
                })}
              </div>
            </li>
            <li className="custom-dropdown">
              <button className="custom-dropbtn">Seller</button>
              <div className="custom-dropdown-content">
                <Link to={"/seller-register"}>Create Account</Link>
                <Link to={"/seller-login"}>Login to your account</Link>
              </div>
            </li>
            <li>
              <Link
                to="/about-us"
                style={{ textDecoration: "none", color: "#191717" }}
              >
                About Us
              </Link>
            </li>
            <li className="custom-dropdown">
              <button className="custom-dropbtn">Builders</button>
              <div className="custom-dropdown-content">
                {builderData?.map((data, index) => {
                  return (
                    <Link
                      key={index}
                      to={
                        "/divine-bricks-property-listing?builderId=" +
                        data?.slug
                      }
                    >
                      {data?.name}
                    </Link>
                  );
                })}
              </div>
            </li>
            <li>
              <Link
                to="/seller-login"
                style={{ textDecoration: "none", color: "#191717" }}
              >
                Agent Portal
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                style={{ textDecoration: "none", color: "#191717" }}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <button
                className="button-hover-style"
                style={{
                  background: "#8F5726",
                  border: "none",
                  color: "white",
                  padding: "4px 13px",
                  borderRadius: "5px",
                }}
              >
                <Link
                  to="/seller-login"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Post Your Property <sup>Free</sup>
                </Link>
              </button>
            </li>
            <li style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0ZM8.45 9.75C8.45 9.15249 8.56769 8.56082 8.79635 8.00879C9.02501 7.45676 9.36016 6.95517 9.78266 6.53266C10.2052 6.11016 10.7068 5.77501 11.2588 5.54635C11.8108 5.31769 12.4025 5.2 13 5.2C13.5975 5.2 14.1892 5.31769 14.7412 5.54635C15.2932 5.77501 15.7948 6.11016 16.2173 6.53266C16.6398 6.95517 16.975 7.45676 17.2037 8.00879C17.4323 8.56082 17.55 9.15249 17.55 9.75C17.55 10.9567 17.0706 12.114 16.2173 12.9673C15.364 13.8206 14.2067 14.3 13 14.3C11.7933 14.3 10.636 13.8206 9.78266 12.9673C8.92937 12.114 8.45 10.9567 8.45 9.75ZM21.1354 19.4792C20.1623 20.7033 18.9251 21.6917 17.5164 22.3707C16.1077 23.0496 14.5638 23.4015 13 23.4C11.4362 23.4015 9.8923 23.0496 8.48358 22.3707C7.07486 21.6917 5.83773 20.7033 4.8646 19.4792C6.9719 17.9673 9.8475 16.9 13 16.9C16.1525 16.9 19.0281 17.9673 21.1354 19.4792Z"
                  fill="#8F5726"
                />
              </svg>
            </li>
          </ul>
        </div>
      </section>

      <section className="for-mobile">
        <div className="container">
          <div
            className="py-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              style={{ width: "160px" }}
              alt="divine-bricks-logo"
              title="mobile-logo"
            />
            <i
              className="fa fa-bars"
              style={{ fontSize: "25px" }}
              onClick={toggleSidebar}
            ></i>
          </div>
        </div>
        <div>
          <ul
            className="header-for-mobile-ul"
            style={{ paddingLeft: 0, listStyleType: "none", display: "none" }}
          >
            <li style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/">
                <img
                  src={logo}
                  title="divine-bricks"
                  alt="divine-bricks"
                  style={{ width: "125px", height: "43px" }}
                />
              </Link>
              <button
                style={{
                  border: "none",
                  background: "rgb(143, 87, 38)",
                  fontSize: "20px",
                  borderRadius: "50%",
                  height: "35px",
                  width: "35px",
                  color: "white",
                  marginTop: "7px",
                }}
                onClick={toggleSidebar}
              >
                X
              </button>
            </li>
            <li className="custom-dropdown" style={{ marginLeft: "auto" }}>
              <button className="custom-dropbtn">Buyer</button>
              <div className="custom-dropdown-content">
                {typeList?.map((data, index) => {
                  return (
                    <Link
                      key={index}
                      to={
                        "/divine-bricks-property-listing?typeArr=" + data?.slug
                      }
                      style={{ width: "50%" }}
                    >
                      {data?.name}
                    </Link>
                  );
                })}
              </div>
            </li>
            <li className="custom-dropdown">
              <button className="custom-dropbtn">Seller</button>
              <div className="custom-dropdown-content">
                <Link to={"/seller-register"}>Create Account</Link>
                <Link to={"/seller-login"}>Login to your account</Link>
              </div>
            </li>
            <li>
              <Link
                to="/about-us"
                style={{ textDecoration: "none", color: "#191717" }}
              >
                About Us
              </Link>
            </li>
            <li className="custom-dropdown">
              <button className="custom-dropbtn">Builders</button>
              <div className="custom-dropdown-content">
                {builderData?.map((data, index) => {
                  return (
                    <Link
                      key={index}
                      to={
                        "/divine-bricks-property-listing?builderId=" +
                        data?.slug
                      }
                    >
                      {data?.name}
                    </Link>
                  );
                })}
              </div>
            </li>
            <li>
              <Link
                to="/seller-login"
                style={{ textDecoration: "none", color: "#191717" }}
              >
                Agent Portal
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                style={{ textDecoration: "none", color: "#191717" }}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <button
                className="button-hover-style"
                style={{
                  background: "#8F5726",
                  border: "none",
                  color: "white",
                  padding: "4px 13px",
                  borderRadius: "5px",
                }}
              >
                <Link
                  to="/seller-login"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Post Your Property <sup>Free</sup>
                </Link>
              </button>
            </li>
            <li style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0ZM8.45 9.75C8.45 9.15249 8.56769 8.56082 8.79635 8.00879C9.02501 7.45676 9.36016 6.95517 9.78266 6.53266C10.2052 6.11016 10.7068 5.77501 11.2588 5.54635C11.8108 5.31769 12.4025 5.2 13 5.2C13.5975 5.2 14.1892 5.31769 14.7412 5.54635C15.2932 5.77501 15.7948 6.11016 16.2173 6.53266C16.6398 6.95517 16.975 7.45676 17.2037 8.00879C17.4323 8.56082 17.55 9.15249 17.55 9.75C17.55 10.9567 17.0706 12.114 16.2173 12.9673C15.364 13.8206 14.2067 14.3 13 14.3C11.7933 14.3 10.636 13.8206 9.78266 12.9673C8.92937 12.114 8.45 10.9567 8.45 9.75ZM21.1354 19.4792C20.1623 20.7033 18.9251 21.6917 17.5164 22.3707C16.1077 23.0496 14.5638 23.4015 13 23.4C11.4362 23.4015 9.8923 23.0496 8.48358 22.3707C7.07486 21.6917 5.83773 20.7033 4.8646 19.4792C6.9719 17.9673 9.8475 16.9 13 16.9C16.1525 16.9 19.0281 17.9673 21.1354 19.4792Z"
                  fill="#8F5726"
                />
              </svg>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default HomePageHeader;

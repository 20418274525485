import React, { useState } from "react";
import SellerSidebar from "./SellerSidebar";
import SellerDashboardHeader from "./SellerDashboardHeader";
import userIcon from '../image/user-icon.png'
const chatData = [
    {
        id: 1,
        name: 'Kshitiz',
        img: userIcon,
        subject: 'Pricing Details',
        desc: 'Some details on pricing',
        time: '10m ago'
    },
    {
        id: 2,
        name: 'Suresh',
        img: userIcon,
        subject: 'Area Details',
        desc: 'Some details on Area',
        time: '20m ago'
    },
    {
        id: 3,
        name: 'Rama',
        img: userIcon,
        subject: 'Amenities Details',
        desc: 'Some details on Amenities',
        time: '10m ago'
    },

    {
        id: 4,
        name: 'Akash',
        img: userIcon,
        subject: 'Brochure Details',
        desc: 'Some details on Brochure',
        time: '10m ago'
    },

    {
        id: 5,
        name: 'Gautaam',
        img: userIcon,
        subject: 'Brochure Details',
        desc: 'Some details on Brochure',
        time: '10m ago'
    }
]
var arr = ['How May I help you?', 'Just relax I will assist you Sir']
const receiverMsg = ['How May I help you?', 'Just relax I will assist you Sir']

const SellerQueries = () => {
    const [myMsg, setMyMsg] = useState()
    return (
        <>
            <div style={{ height: '100vh', background: '#F1EFDC' }}>
                <div className="row">
                    <SellerDashboardHeader />
                    <div className="d-flex" style={{ width: '100%' }}>
                        <SellerSidebar />
                        <div className="seller-body-content" style={{ width: '88%' }}>
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-md-12">
                                    <ul class="nav nav-tabs rld-banner-tab">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-toggle="tab" href="#tabs_1">New Queries</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#tabs_2">Read Queries</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content seller-queries-chat">
                                        <div class="tab-pane fade show active" id="tabs_1">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div style={{ maxHeight: '320px', overflowY: 'auto', padding: '10px' }}>
                                                        {chatData.map((data) => {
                                                            return (
                                                                <>
                                                                    <div style={{ border: '1px solid #DDDDDD', marginBottom: '10px', borderRadius: '25px' }}>
                                                                        <li style={{ listStyleType: 'none', display: 'flex', alignItems: 'center', borderRadius: 'inherit' }}>
                                                                            <img src={data.img} style={{ width: '80px', borderRadius: 'inherit' }} />
                                                                            <div>
                                                                                <p style={{ marginBottom: '5px' }}>{data.name}</p>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-md-8" style={{ borderLeft: '1px solid' }}>
                                                    <div style={{ border: '1px solid #DDDDDD', marginBottom: '10px', borderRadius: '4px' }}>
                                                        <li style={{ listStyleType: 'none', display: 'flex', alignItems: 'center', borderRadius: 'inherit' }}>
                                                            <img src={chatData[0].img} style={{ width: '80px', borderRadius: 'inherit' }} />
                                                            <div>
                                                                <p style={{ marginBottom: '5px' }}>{chatData[0].name}</p>
                                                            </div>
                                                        </li>
                                                    </div>
                                                    <div className="chat-query-box">
                                                        <p className="senders-msg">
                                                            Hi My name is Kshitiz
                                                            <span style={{ float: 'right', fontSize: '14px' }}>20m ago</span>
                                                        </p>
                                                        {arr?.map((data) => {
                                                            return (
                                                                <p className="recievers-msg">
                                                                    {data}
                                                                    <span style={{ float: 'right', fontSize: '14px' }}>10m ago</span>
                                                                </p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div class="chat-footer" style={{ width: '92%', bottom: '0' }}>
                                                        <div class="position-relative">
                                                            <input type="text" class="form-control" value={myMsg} onChange={(e) => setMyMsg(e.target.value)} />
                                                            <button class="btn btn-danger" onClick={() => { arr.push(myMsg); setMyMsg(''); console.log(arr) }}>Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tabs_2">
                                            <h2>Hello</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerQueries
import React, { useEffect, useState } from "react";
import SellerHeader from "./SellerHeader";
import { Link, redirect, useNavigate } from 'react-router-dom'
import register from '../image/seller-login.webp'
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import eyes from '../image/eyes.png'
import Header from "./Header";
import HomePageHeader from "./HomePageHeader";
import TopHeader from "./TopHeader";

const SellerLogin = () => {



    useEffect(() => {
        setEmail(localStorage.getItem('email'))
        setPassword(localStorage.getItem('password'))
    }, [])

    const [name, setName] = useState()
    const [username, setUserName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [referral, setReferral] = useState()
    const [visible, setVisible] = useState(false)
    const [check, setCheck] = useState(false)
    const [otp, setOtp] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()
    const login = (e) => {

        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/login`,
                {
                    username: email,
                    password: password,
                    isAdmin: '2'
                },
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    if (check) {
                        localStorage.setItem('email', email)
                        localStorage.setItem('password', password)
                    }
                    toast(resp.data.msg, { toastId: 's1' })
                    setTimeout(() => {
                        navigate('/seller-dashboard')
                    }, 2000);
                    sessionStorage.setItem("token", resp.data?.token)
                    sessionStorage.setItem("isAdmin", resp.data?.result?.isAdmin)
                    sessionStorage.setItem("id", resp.data?.result?.id)
                }
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }
    const handleShow = () => {
        setShowPassword(!showPassword)
    }

    return (
        <>
            {visible ? <div className="screen-overlay"></div> : ''}
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <TopHeader />
            <HomePageHeader />
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <img src={register} />
                    </div>
                    <div className="col-md-5" style={{ margin: 'auto' }}>
                        <form className="seller-login-panel">
                            <h2 style={{ textAlign: 'left', marginBottom: '20px' }}>Login</h2>
                            <input type="email" className="form-control mb-3" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <div className='position-relative'>
                                <input className='form-control mb-4' placeholder='Password' id='form2' type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                                <img className='password-eyes' src={eyes} onClick={handleShow} style={{ top: '11px', right: '11px' }} />
                            </div>
                            <div className="d-flex mt-3 mb-3 short-font-12" style={{ alignItems: 'center' }}> <input type="checkbox" className="form-control" style={{ marginTop: '0' }} onClick={() => setCheck(!check)} /> Remember Me</div>
                            {/* <div className="d-flex mt-3 mb-3" style={{alignItems: 'center'}}> <input type="checkbox" className="form-control" /> I have read and agree to the Terms & Conditions</div> */}
                            <div className="d-flex">
                                <Link className='w-100' to='/' style={{ color: 'white' }}><button type="button" className="btn btn-danger">&larr; Go Back</button></Link>
                                <Link className='w-100 mx-2' to='/seller-dashboard' style={{ color: 'white' }}><button type="submit" className="btn btn-primary" onClick={login}>Login</button></Link>
                            </div>
                            <div className="mt-2">Don't have an Account? <Link to='/seller-register'>Register</Link></div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerLogin
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import UserHeader from "./UserHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import axios from "../axiosInstance";
import $ from "jquery";
import EditIcon from "@mui/icons-material/Edit";

const ApproveDeposit = () => {
  const [data, setData] = useState();
  const [message, setMessage] = useState("");

  const fetchData = () => {
    const token = sessionStorage.getItem("token");
    axios
      .get(`api/users/get-payment-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        console.log(resp.data.result);
        setData(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const token = sessionStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState();
  const [currentRow, setCurrentRow] = useState();
  function handleStatus(data) {
    setVisible(true);
    axios
      .post(
        `api/users/payment-action-by-admin `,
        {
          paymentId: currentRow.id.toString(),
          is_status: status, ///"Success", "Failed"
          remark: message,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (!resp.data.success) {
          toast.error(resp.data.msg, { toastId: "e1" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
        }
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "s1" });
          fetchData();
          $("[data-dismiss=modal]").trigger({ type: "click" });
          // $('#exampleModal1').hide();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    {
      name: "Property ID",
      selector: (row) => row.propertyData.name,
      width: "200px",
    },
    {
      name: "Username",
      selector: (row) => row.userData?.name,
    },
    {
      name: "Amount",
      selector: (row) => "$" + row.amount,
    },
    {
      name: "Fee",
      selector: (row) => "$" + row.fee,
    },
    {
      name: "Final Amount",
      selector: (row) => "$" + row.final_amount,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.is_status == "Pending"
              ? "badge badge-warning p-2"
              : row.is_status == "Success"
              ? "badge badge-success p-2"
              : "badge badge-danger p-2"
          }
        >
          {row.is_status}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <button
            type="button"
            data-toggle="modal"
            data-target="#exampleModal1"
            onClick={(e) => setCurrentRow(row)}
            style={{ border: "none", background: "transparent" }}
          >
            <EditIcon />
          </button>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Approve/Reject Payment
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container my-3">
                    <div className="row">
                      <div className="col-md-12" style={{ textAlign: "left" }}>
                        <label>Change Status</label>
                        <select
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option disabled selected>
                            Select Status
                          </option>
                          <option value="Success">Success</option>
                          <option value="Failed">Failed</option>
                        </select>
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Write Remarks</label>
                        {/* <input className='form-control' placeholder='Remarks' type='text' value={message} onChange={(e) => setMessage(e.target.value)} /> */}
                        <textarea
                          className="form-control"
                          placeholder="Remarks"
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleStatus(row)}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <UserHeader />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Approve/Decline Deposit
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ApproveDeposit;

import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import axios from "../axiosInstance";
import UserHeader from "./UserHeader";
import { toast } from "react-toastify";
import $ from "jquery";
import { ColorRing } from "react-loader-spinner";
import EditIcon from "@mui/icons-material/Edit";

const AmenitiesManager = () => {
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      width: "250px",
    },
    {
      name: "Logo",
      selector: (row) => (
        <div className="">
          <img
            src={"https://api.divinebricks.com" + row?.image}
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.is_status == "inactive"
              ? "badge badge-danger p-2"
              : "badge badge-success p-2"
          }
        >
          {row.is_status}
        </div>
      ),
      width: "auto",
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <button
            data-toggle="modal"
            data-target="#exampleModal1"
            onClick={() => {
              setCurrentRow(row);
              setUpdateName("");
              setStatus("");
            }}
            style={{ border: "none", background: "transparent" }}
          >
            <EditIcon />
          </button>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Amenities
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container my-3">
                    <div className="row">
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Name</label>
                        <input
                          className="form-control"
                          placeholder="Name"
                          type="text"
                          value={
                            updateName != "" ? updateName : currentRow.name
                          }
                          onChange={(e) => setUpdateName(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Edit Logo</label>
                        <input
                          className="form-control"
                          type="file"
                          id="myfile"
                          name="myfile"
                          required
                          onChange={imageUpload4}
                        />
                        <img
                          id="output5"
                          src={
                            currentRow?.image
                              ? "https://api.divinebricks.com" +
                                currentRow?.image
                              : null
                          }
                          alt="your image"
                          style={{
                            height: "100px",
                            width: "100px",
                            marginTop: "10px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Status</label>
                        <select
                          className="form-control"
                          value={status != "" ? status : currentRow.is_status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value={""} selected disabled>
                            Select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => editType(row)}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      width: "auto",
    },
  ];

  const [data, setData] = useState();
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [logo, setLogo] = useState();
  const [updateName, setUpdateName] = useState();
  const [updateLogo, setUpdateLogo] = useState();
  const [status, setStatus] = useState();
  const [visible, setVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [page, setPage] = useState(1);
  const [perpage, setperPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const token = sessionStorage.getItem("token");
  useEffect(() => {
    fetchData();
  }, [page, perpage]);

  const handleRowsPerPageChange = (per_page) => {
    setperPage(per_page);
    setPage(1);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  function fetchData() {
    axios
      .get(`api/admins/get-admin-amenties-list?page=${page}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result.docs);
        setTotalRows(resp.data?.data?.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addType = () => {
    setVisible(true);
    axios
      .post(
        `api/admins/add-amenties`,
        {
          name: name,
          image: logo,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editType = (row) => {
    setVisible(true);
    axios
      .post(
        `api/admins/update-amenties`,
        {
          name: updateName == "" ? currentRow.name : updateName,
          amityId: currentRow.id.toString(),
          image: updateLogo != undefined ? updateLogo : currentRow?.image,
          is_status: status == "" ? currentRow.is_status : status,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageUpload4 = async (event) => {
    var output1 = document.getElementById("output5");
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile4(event.target.files[0]);
  };

  function uploadFile4(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`api/users/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        setUpdateLogo(resp.data.result?.image_url);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }

  const imageUpload3 = async (event) => {
    var output1 = document.getElementById("output3");
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile3(event.target.files[0]);
  };

  function uploadFile3(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`api/users/upload-a-document`, formData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setVisible(false);
        setLogo(resp.data.result?.image_url);
        console.log(resp.data);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }
  return (
    <>
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <UserHeader />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div
              className="card-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Amenities List
              </h4>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Add Amenities
              </button>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[15, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Amenities
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container my-3">
                <div className="row">
                  <div className="col-md-12 mt-3" style={{ textAlign: "left" }}>
                    <label>Name</label>
                    <input
                      className="form-control"
                      placeholder="Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 mt-3" style={{ textAlign: "left" }}>
                    <label>Add Logo</label>
                    <input
                      className="form-control"
                      type="file"
                      id="myfile"
                      name="myfile"
                      required
                      onChange={imageUpload3}
                    />
                    <img
                      id="output3"
                      src={logo ? "https://api.divinebricks.com" + logo : ""}
                      alt="your image"
                      style={{
                        height: "100px",
                        width: "100px",
                        marginTop: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addType}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AmenitiesManager;

import React, { useState, useEffect } from "react";
import UserHeader from "./UserHeader";
import img1 from '../image/property-details-1.webp'
import img2 from '../image/property-details-2.webp'
import img3 from '../image/property-details-3.webp'
import { Link, useNavigate } from "react-router-dom";
import axios from '../axiosInstance'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import qr from '../image/qr-code.png'
import $ from 'jquery'



const Payment = () => {

    const token = sessionStorage.getItem("token")
    useEffect(() => {
        axios
            .post(
                `api/users/get-property-details`,
                { "propertyId": sessionStorage.getItem("propertyId") },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                var string = resp.data.result?.propertyDetails?.planData?.emiArr.split(',')[0]
                setPropertyId(resp.data?.result?.propertyDetails?.id)
                setAmount((parseInt(string) * resp.data.result?.propertyDetails?.price) / 100)
                setPropertyData(resp.data.result.propertyDetails)
                var emiData = resp.data.result?.propertyDetails?.planData?.emiArr.split(',')
                setEmiArr(emiData)
            })
            .catch((err) => {
                console.log(err)
                setVisible(false)
            });
    }, [])

    const [propertyId, setPropertyId] = useState()
    const [amount, setAmount] = useState()
    const [fee, setFee] = useState()
    const [transId, setTransId] = useState()
    const [attachment, setAttachment] = useState()
    const [visible, setVisible] = useState(false)
    const [seeImg2, setSeeImg2] = useState('hidden')
    const [propertyData, setPropertyData] = useState()
    const [emiArr, setEmiArr] = useState()

    const imageUpload2 = async (event) => {
        setSeeImg2('visible')
        var output1 = document.getElementById('output1');
        output1.src = URL.createObjectURL(event.target.files[0]);
        output1.onload = function () {
            URL.revokeObjectURL(output1.src) // free memory
        }
        await uploadFile2(event.target.files[0])
    }

    function forexTransfer() {
        $('.forex-transfer').css('display', 'block')
        $('.bank-transfer').css('display', 'none')
    }
    function bankTransfer() {
        $('.bank-transfer').css('display', 'block')
        $('.forex-transfer').css('display', 'none')
    }


    function uploadFile2(file) {
        setVisible(true)
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-document`,
            formData,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        ).
            then((resp) => {
                setVisible(false)
                setAttachment(resp.data.result.image_url)
            }).catch((err) => { console.log('eerr', err); setVisible(false) });
    }

    const navigate = useNavigate()

    const payment = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/buy-property`,
                {
                    propertyId: propertyId.toString(),
                    amount: amount.toString(),
                    fee: '0',
                    transId: transId,
                    attachmenet: attachment,
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    toast(
                        <div>
                            Your Property buy request has been submitted. Please wait for the admin to approve your request. Thank you for your Purchase.
                        </div>,
                        {
                            autoClose: false,
                            closeOnClick: false,
                            draggable: false,
                            position: "bottom-right",
                            toastId: "success53",
                        }
                    );
                    setTimeout(() => {
                        navigate('/user-dashboard')
                    }, 3000);
                }
            })
            .catch((err) => {
                setVisible(false)
                console.log(err)
            });
    }
    return (
        <>
            {visible ? <div className="screen-overlay"></div> : ''}
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="row mt-5">
                            <div className="col-md-12" style={{ textAlign: 'left' }}>
                                <Link to='/properties-details'><button className="btn btn-danger mb-4">&larr; Go Back</button></Link>
                                <p><h3>Order Summary</h3>
                                    {/* <select><option disabled selected>Select Currency</option><option>$</option><option>AED</option><option>INR</option></select> */}
                                </p>
                                <hr />
                            </div>
                        </div>
                        <h6 style={{ textAlign: 'left' }}>Billing Plan</h6>
                        <div className="mt-4" style={{ display: 'flex', overflowX: 'auto' }}>
                            {emiArr?.map((data, key) => {
                                return (
                                    <div className="" style={{ textAlign: 'left', minWidth: '150px', margin: '0 5px' }}>
                                        <div className="card p-2" style={{ border: '2px solid green' }}>
                                            <p><strong>{key + 1} Month</strong></p>
                                            <h4 style={{ color: '#315745' }}>AED {(data * amount) / 100}</h4>
                                            <p>{data}% of Total Amount</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row mt-5">
                            <div className="form card p-4" style={{ background: 'white', flexGrow: '1' }}>
                                <div className="col-md-12" style={{ textAlign: 'left' }}>
                                    <div className="d-flex">
                                        <button style={{ background: 'linear-gradient(90deg, rgba(192, 127, 0, 1) 0%, rgba(203, 170, 92, 1) 56%)', border: 'none', color: 'white', borderRadius: '5px', padding: '10px' }} onClick={forexTransfer}>Funds with USD Tether (TRC20)</button>
                                        <button style={{ background: 'linear-gradient(90deg, rgba(192, 127, 0, 1) 0%, rgba(203, 170, 92, 1) 56%)', border: 'none', color: 'white', borderRadius: '5px', padding: '10px' }} onClick={bankTransfer} className="mx-4">Bank Transfer</button>
                                    </div>
                                    <hr />
                                    <div className="forex-transfer">
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <p>Fee</p>
                                            <div style={{ textAlign: 'right' }}>
                                                <p>0 USD</p>
                                                <p>0% + 0 USD</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <p>Amount to receive</p>
                                            <div style={{ textAlign: 'right' }}>
                                                <p>1000 USD</p>
                                                <p>(1 USD = 1 USDT)</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <p>Amount to send</p>
                                            <div>
                                                <p>1000 USDT</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex" style={{ alignItems: 'center' }}>
                                            <p><img style={{ width: '100px' }} src={qr} /></p>
                                            <div style={{ paddingLeft: '10px' }}>
                                                <p><strong>TR11pXyEYW6zG4CT6sMRveytNVQ5xT7YDw</strong></p>
                                                <p>The Amount will be deducted after payment</p>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Payable Amount <sup className="text-danger">*</sup></label>
                                            <input type="text" className="form-control mb-3" value={amount} onChange={(e) => setAmount(e.target.value)} required />
                                            <label>Transaction Hash <sup className="text-danger">*</sup></label>
                                            <input type="text" className="form-control mb-3" value={transId} onChange={(e) => setTransId(e.target.value)} required />
                                            <label>Transaction Attachment <sup className="text-danger">*</sup></label><br />
                                            <input type="file" id="myfile" name="myfile" required onChange={imageUpload2} />
                                            <img id="output1" src="#" alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover', visibility: seeImg2 }} />
                                        </div>
                                    </div>
                                    <div className="bank-transfer" style={{ display: 'none' }}>
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <p>Account Holder's Name</p>
                                            <div>
                                                <p>Divine Bricks</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <p>Account Holder's Account Number</p>
                                            <div>
                                                <p>TEST1234567890</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <label>Payable Amount <sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control mb-3" value={amount} onChange={(e) => setAmount(e.target.value)} required />

                                        <label>Transaction ID <sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control mb-3" value={transId} onChange={(e) => setTransId(e.target.value)} required />
                                        <label>Transaction Attachment <sup className="text-danger">*</sup></label><br />
                                        <input type="file" id="myfile" name="myfile" required onChange={imageUpload2} />
                                        <img id="output1" src="#" alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover', visibility: seeImg2 }} />
                                    </div>
                                    <button className="btn btn-primary mt-4" onClick={payment}>Complete Transaction</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5" style={{ textAlign: 'left', borderLeft: '1px solid' }}>
                        <h4 className="mt-5">Order Details</h4>
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                {propertyData?.galleryData?.map((data1, key) => {
                                    return (
                                        <div class={key == 0 ? "carousel-item active" : "carousel-item"}>
                                            <img src={"https://api.divinebricks.com" + data1?.img_url}
                                                alt="" style={{ width: '100%', maxHeight: '300px' }} />
                                        </div>
                                    )
                                })}
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                        <h3 className="primary-color font-600 mt-2">{propertyData?.name}</h3>
                        <ul className="payment-page">
                            <li>Square Feet : {propertyData?.max_area}</li>
                            <li>Bedrooms : {propertyData?.min_beds}</li>
                            <li>Bathrooms : {propertyData?.min_bath}</li>
                            <li>House Type : {propertyData?.typeArr}</li>
                            <li>Location : {propertyData?.address}</li>
                        </ul>
                        <h3 className="primary-color">AED {propertyData?.price}</h3>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Payment
import React, { useState } from "react";
import axios from "../axiosInstance.js";
import { ColorRing } from "react-loader-spinner";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { RMIUploader } from "react-multiple-image-uploader";

const AddProperty = () => {
  var imageType;
  const token = sessionStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [brochureImage, setBrochureImage] = useState();
  const [data, setData] = useState([]);

  const hideModal = () => {
    setVisible(true);
  };

  const onUpload = (data) => {
    console.log("Upload files", data);
    setData(data);
  };
  const onSelect = (data) => {
    console.log("Select files", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };

  const uploadFile = async (event) => {
    imageType = $(event.target).attr("imageType");
    var id = $(event.target).parent().parent().find("img").attr("id");
    var output1 = document.getElementById(id);
    output1.src = URL.createObjectURL(event.target.files[0]);
    output1.onload = function () {
      URL.revokeObjectURL(output1.src); // free memory
    };
    await uploadFile1(event.target.files[0]);
  };

  function uploadFile1(file) {
    setVisible(true);
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(
        `api/users/upload-a-document`,
        formData,
        {
          headers: {
            "x-access-token": token,
          },
        },
        {
          timeout: 1000 * 2,
        }
      )
      .then((resp) => {
        setVisible(false);
        setImgUrl(resp.data.result.image_url);

        if (imageType == "banner-image") {
          setBannerImage(resp.data.result.image_url);
        }
        if (imageType == "brochure-image") {
          setBrochureImage(resp.data.result.image_url);
        }
      })
      .catch((err) => {
        setVisible(false);
      });
  }

  return (
    <>
      {visible ? <div className="screen-overlay"></div> : ""}
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <div className="container">
        <h4>Add Property Details</h4>
        <form>
          <div className="add-property-first-section">
            <div className="card">
              <div className="card-header text-left">Basic Details</div>
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-md-4">
                    <label>Select Plan</label>
                    <select className="form-control">
                      <option>1</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Select Builder</label>
                    <select className="form-control">
                      <option>1</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Select Property Type</label>
                    <select className="form-control">
                      <option>1</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Enter Property Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="add-property-second-section">
            <div className="card">
              <div className="card-header text-left">Upload Images</div>
              <div className="card-body p-4">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          dataToggle="collapse"
                          dataTarget="#collapseOne"
                          ariaExpanded="true"
                          ariaControls="collapseOne"
                          style={{ background: "transparent" }}
                        >
                          Upload Property Banner Image
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            <label className="file-input-label">
                              Select Property Banner Image
                              <br />
                              <input
                                type="file"
                                className="file-input"
                                imageType="banner-image"
                                id="fileInput"
                                accept="image/*"
                                onChange={uploadFile}
                              />
                              <span className="plus-button mt-2">
                                + Select Image
                              </span>
                            </label>
                            {/* <button className="upload-button">Upload</button> */}
                            <img
                              src={"https://api.divinebricks.com/" + imgUrl}
                              id="imagePreview"
                              className="image-preview"
                              style={{
                                display: imgUrl !== "" ? "block" : "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          dataTarget="#collapseTwo"
                          ariaExpanded="false"
                          ariaControls="collapseTwo"
                          style={{ background: "transparent" }}
                        >
                          Upload Property Brochure
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 text-left">
                              <label className="file-input-label">
                                Select Property Banner Image
                                <br />
                                <input
                                  type="file"
                                  className="file-input"
                                  id="fileInput"
                                  imageType="brochure-image"
                                  accept="image/*"
                                  onChange={uploadFile}
                                />
                                <span className="plus-button mt-2">
                                  + Select Image
                                </span>
                              </label>
                              {/* <button className="upload-button">Upload</button> */}
                              <img
                                src={"https://api.divinebricks.com/" + imgUrl}
                                id="imagePreview"
                                className="image-preview"
                                style={{
                                  display: imgUrl !== "" ? "block" : "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          dataTarget="#collapseThree"
                          ariaExpanded="false"
                          ariaControls="collapseThree"
                          style={{ background: "transparent" }}
                        >
                          Collapsible Group Item #3
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <RMIUploader
                          isOpen={visible}
                          hideModal={hideModal}
                          onSelect={onSelect}
                          onUpload={onUpload}
                          onRemove={onRemove}
                          dataSources={data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddProperty;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../axiosInstance";
import user from "../image/user.png";
import Footer from "./Footer";
import $ from "jquery";
import UserHeader from "./UserHeader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Properties = () => {
  const [propertyData, setPropertyData] = useState();
  const [load, setLoad] = useState(true);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    axios
      .get(`api/users/get-property-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setLoad(false);
        setPropertyData(resp.data.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [searchText, setSearchText] = useState("");
  function openMenu(e) {
    e.preventDefault();
    $("div#nav-mobile").toggleClass("open");
  }
  $(".bedroom-btns-tag").on("click", function () {
    $(".bedroom-btns-tag").removeClass("heelo");
    $(this).addClass("heelo");
  });
  const handleChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const filteredData = propertyData?.filter((el) => {
    //if no input the return the original
    if (searchText === "") {
      return el;
    }
    //return the item which contains the user input
    else if (el.name.toLowerCase().includes(searchText)) {
      return el;
    } else if (el.address.toLowerCase().includes(searchText)) {
      return el;
    } else if (el.min_beds.toString().includes(searchText)) {
      return el;
    } else if (el.min_bath.toString().includes(searchText)) {
      return el;
    }
  });
  return (
    <>
      <main id="main-wrap" class="main-wrap">
        <UserHeader />
        <div className="container">
          <div className="row pt-5 properties-filter">
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <div className="card properties-filters-card p-4">
                <h4>
                  <strong>Filters</strong>
                </h4>
                <div className="mt-4">
                  <h6>For Sale</h6>
                  <input
                    type="radio"
                    id="Sale"
                    name="fav_language"
                    value="Sale"
                  />
                  <label className="mx-2" htmlFor="Sale">
                    For Sale
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="Sold"
                    name="fav_language"
                    value="Sold"
                  />
                  <label className="mx-2" htmlFor="Sold">
                    Sold
                  </label>
                </div>
                <div className="mt-4">
                  <h6>Price</h6>
                  <div className="d-flex">
                    <div style={{ flexGrow: "1" }}>
                      <label>Minimum</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Min Price"
                      />
                    </div>
                    <div style={{ flexGrow: "1" }}>
                      <label>Maximum</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Max Price"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Bedrooms</h6>
                  <div
                    class="btn-group mt-2 bedroom-btns"
                    id="bedroom-btns"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button class="btn bedroom-btns-tag btn-primary">
                      Any
                    </button>
                    <button class="btn bedroom-btns-tag btn-primary">1+</button>
                    <button class="btn bedroom-btns-tag btn-primary">2+</button>
                    <button class="btn bedroom-btns-tag btn-primary">3+</button>
                    <button class="btn bedroom-btns-tag btn-primary">4+</button>
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Home Type</h6>
                  <div class="mt-2">
                    <input
                      type="checkbox"
                      id="Houses"
                      name="Houses"
                      value="Houses"
                    />
                    <label className="mx-2" htmlFor="Houses">
                      {" "}
                      Houses
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="TownHomes"
                      name="TownHomes"
                      value="TownHomes"
                    />
                    <label className="mx-2" htmlFor="TownHomes">
                      {" "}
                      TownHomes
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="Multi-Family"
                      name="Multi-Family"
                      value="Multi-Family"
                    />
                    <label className="mx-2" htmlFor="Multi-Family">
                      {" "}
                      Multi-Family
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="Apartments"
                      name="Apartments"
                      value="Apartments"
                    />
                    <label className="mx-2" htmlFor="Apartments">
                      {" "}
                      Apartments
                    </label>
                    <br />
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Parking Slots</h6>
                  <div
                    class="btn-group mt-2 bedroom-btns"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button type="button" class="btn btn-primary">
                      Required
                    </button>
                    <button type="button" class="btn btn-primary">
                      Not Required
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Square Feet</h6>
                  <div className="d-flex">
                    <div style={{ flexGrow: "1" }}>
                      <label>Minimum</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Min Area"
                      />
                    </div>
                    <div style={{ flexGrow: "1" }}>
                      <label>Maximum</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Max Area"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <button className="btn btn-danger w-100">Reset All</button>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100">
                      Search Results
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                placeholder="Search here"
                className="form-control"
                onChange={handleChange}
              />
              <div className="row mt-3">
                {load ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card p-4">
                          <Skeleton count="1" width="100%" />
                          <div
                            class="card-body pt-2 pl-0"
                            style={{ textAlign: "left" }}
                          >
                            <Skeleton count="1" width="100%" />
                            <Skeleton count="1" width="100%" />
                            <Skeleton count="1" width="100%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {filteredData?.map((data) => {
                      return (
                        <div className="col-md-6 mb-3">
                          <Link
                            to="/properties-details"
                            onClick={() =>
                              sessionStorage.setItem("propertyId", data.id)
                            }
                          >
                            <div class="card" style={{ borderRadius: "10px" }}>
                              {data.is_status == "booked" ? (
                                <div class="ribbon blue">
                                  <span>Booked</span>
                                </div>
                              ) : (
                                ""
                              )}
                              <img
                                class="card-img-top properties-list-image"
                                src={
                                  "https://api.divinebricks.com" + data?.image
                                }
                                alt="Card image cap"
                                style={{
                                  borderRadius: "10px",
                                  height: "250px",
                                  borderBottomLeftRadius: "0",
                                  borderBottomRightRadius: "0",
                                }}
                              />
                              <div
                                class="card-body p-2"
                                style={{ textAlign: "left" }}
                              >
                                <h5
                                  class="card-title properties-card-title mb-1"
                                  style={{ color: "#315745" }}
                                >
                                  {data.name}
                                </h5>
                                <p
                                  class="card-text mb-1"
                                  style={{ color: "#315745" }}
                                >
                                  {data.address}
                                </p>
                                <p
                                  class="card-text"
                                  style={{ color: "#484848" }}
                                >
                                  <strong>
                                    {data.min_beds} beds {data.min_bath}{" "}
                                    bathrooms{" "}
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}{" "}
                  </>
                )}
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Properties;

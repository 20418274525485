import React, { useState, useEffect } from "react";
import HomePageHeader from "../HomePageHeader";
import TopHeader from "../TopHeader";
import HomeFooter from "../HomeFooter";
import axios from "../../axiosInstance";
import { toast } from "react-toastify";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const EventGallery = () => {
  const [galleryArr, setGalleryArr] = useState([]);
  useEffect(() => {
    fatchGallery();
  }, []);
  const fatchGallery = () => {
    try {
      axios
        .get(`api/users/get-event-gallery-list`)
        .then((resp) => {
          if (resp?.data?.success) {
            setGalleryArr(resp?.data?.result);
          } else {
            toast.error(resp?.data?.msg);
          }
        })
        .catch((err) => {
          console.log("eerr", err);
        });
    } catch (err) {
      toast.error(err.message);
      console.error("Error uploading image", err);
    }
  };

  return (
    <>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <TopHeader />
          <HomePageHeader />
          <section
            className="headings about-page-heading cImg"
            title="divine-bricks-about-us"
            alt="divine-bricks-about-us"
            style={{
              background: "url('/join-dubai-event.jpg') center top no-repeat",
              backgroundSize: "100%",
              height: "220px",
            }}
          ></section>
          <section className="contact-us mt-5">
            <div className="container">
              <div className="property-location mb-5 mt-md-0 mt-3">
                <div className="row">
                  {galleryArr.length > 0 ? (
                    <>
                      <SlideshowLightbox className="col-lg-12 col-md-12">
                        {galleryArr.length > 0
                          ? galleryArr.map((gallery, index) => {
                              return (
                                <img
                                  key={index}
                                  className="w-full rounded col-md-3"
                                  src={
                                    "https://api.divinebricks.com" +
                                    gallery.img_url
                                  }
                                  title={"divine-bricks-event-" + gallery.id}
                                  alt={"divine-bricks-event-" + gallery.id}
                                />
                              );
                            })
                          : null}
                      </SlideshowLightbox>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
          <HomeFooter />
        </div>
      </body>
    </>
  );
};
export default EventGallery;

import React from "react";
import homebanner2 from '../image/home-banner2.webp'
import { Link } from "react-router-dom";

const HomePageBanner = () => {
    return (
        <>
            <section className="first-banner-section" style={{background: '#fff', padding: '40px'}}>
                <div className="container">
                    <div className="position-relative">
                        <div className="banner-text1">
                            <p>Luxury Town House</p>
                            <p>Find your dream luxurious house with Divine Bricks.</p>
                            <button className="button-hover-style"><Link to='/divine-bricks-property-listing?typeArr=townhouse' style={{color: '#fff'}}>View More &rarr;</Link></button>
                        </div>
                        <img src={homebanner2} alt="divine-bricks-advertisement-banner" />
                    </div>
                </div>
            </section>
        </>
    )
} 
export default HomePageBanner
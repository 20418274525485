import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import axios from "../../axiosInstance";
import UserHeader from "../UserHeader";
import { ColorRing } from "react-loader-spinner";

const CategoryManager = () => {
  const columns = [
    {
      name: "S No.",
      cell: (row, index) => perpage * (page - 1) + index + 1, //RDT provides index by default
      width: "90px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "120px",
    },
    {
      name: "Mobile No",
      selector: (row) => row.mobile_no,
      width: "120px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "250px",
    },
    {
      name: "Referral Name",
      selector: (row) => row.referralName,
      width: "120px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      width: "180px",
    },

    {
      name: "Image",
      selector: (row) => (
        <div className="">
          <img
            src={"http://localhost:2002" + row?.image}
            style={{ width: "100px", height: "100px" }}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "QR Code",
      selector: (row) => (
        <div className="">
          <a href={row.url} target="_blank">
            <img
              src={
                "https://api.divinebricks.com/images/" +
                row?.qrCodeImage +
                ".png"
              }
              style={{ width: "100px", height: "100px" }}
            />
          </a>
        </div>
      ),
      width: "150px",
    },

    {
      name: "Date",
      selector: (row) =>
        moment(row.createdAt).format("DD MMM, YYYY  HH:mm:ss A"),
    },
  ];

  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(100);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [logo, setLogo] = useState();
  const [updateName, setUpdateName] = useState();
  const [updateImage, setUpdateImage] = useState();
  const [updateLogo, setUpdateLogo] = useState();
  const [status, setStatus] = useState();
  const [visible, setVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const token = sessionStorage.getItem("token");
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`api/admins/get-joiners-list?page=${page}&perpage=${perpage}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <UserHeader />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div
              className="card-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Event Joiners List
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationRowsPerPageOptions={[100, 500, 1000]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryManager;

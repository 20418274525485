import React from "react";
import bannerAd from "../image/Banner Ad (1).webp";

const HomeBanner3 = () => {
  return (
    <>
      <section className="banner-two-section" style={{ marginTop: "-40px", marginBottom: "-40px" }}>
        <div className="container">
          <div className="position-relative">
            <img src={bannerAd} title="divine-bricks-list-your-property" alt="divine-bricks-list-your-property" />
            <div
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: "5%",
                textAlign: "left",
              }}
            >
              <h3
                style={{
                  color: "#5C5959",
                  fontSize: "28px",
                  fontWeight: "700",
                  fontFamily: "lato",
                }}
              >
                Start Listing or Buying a <br /> Property With Divine Bricks
              </h3>
              <p style={{ color: "#8F5726", fontSize: "14px" }}>
                Talk to our experts or Browse through more properties.
              </p>
              <button
                className="bta"
                style={{
                  border: "none",
                  background: "transparent",
                  padding: "4px 10px",
                }}
              >
                <span>View Details &rarr;</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeBanner3;

import React, { useEffect, useRef, useState } from "react";
import logo from "../image/logo-divine.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "../axiosInstance";
import img1 from "../image/1.jpg";
import img2 from "../image/2.jpg";
import img3 from "../image/3.jpg";
import img4 from "../image/4.jpg";
import img5 from "../image/5.svg";
import img6 from "../image/6.svg";
import img7 from "../image/7.svg";
import img8 from "../image/8.svg";
import ReactSlider from "react-slider";
import FadeIn from "react-fade-in";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
// import { useJsApiLoader, Autocomplete, google, LoadScript } from '@react-google-maps/api'
// import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { TypeAnimation } from "react-type-animation";
import banner from "../image/banner.webp";
import mic from "../image/mic.png";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import PropertyGrid from "./PropertyGrid";
import banner1 from "../image/ad-1.webp";
import banner2 from "../image/ad-2.webp";
import banner3 from "../image/ad-3.webp";
import banner4 from "../image/banner4.webp";
import userIcon from "../image/user-icon.png";
import homeBackground from "../image/home-bg.webp";
import devender from "../image/dev.jpeg";
import naveen from "../image/naveen.jpg";
import chander from "../image/chander.jpeg";
import nitin from "../image/nitin.jpeg";
import Blogs from "./Blogs";
import AboutUs from "./AboutUs";
import gallery1 from "../image/gallery/1.webp";
import gallery2 from "../image/gallery/2.webp";
import gallery3 from "../image/gallery/3.webp";
import gallery4 from "../image/gallery/4.webp";
import gallery5 from "../image/gallery/5.webp";
import gallery6 from "../image/gallery/6.webp";
import gallery7 from "../image/gallery/7.webp";
import gallery8 from "../image/gallery/8.webp";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home = () => {
  // const google = window.google
  // const { isLoaded } = useJsApiLoader({
  //     googleMapsApiKey: 'AIzaSyArqlT_3Q9fHcisw6lvvUGTcObXGz3GEJk',
  //     libraries: ['places'],
  // })
  const [propertyData, setPropertyData] = useState();
  const [builderData, setBuilderData] = useState();
  const [keyword, setKeyword] = useState("");
  const [propertyType, setPropertyType] = useState();
  const [propertyStatus, setPropertyStatus] = useState();
  const [bedroom, setBedroom] = useState();
  const [bathroom, setBathroom] = useState();
  const [size, setSize] = useState();
  const [price, setPrice] = useState();
  const [amenities, setAmenities] = useState("");
  const amenitiesList = useRef([]);
  const [typeList, setTypeList] = useState();
  const [websiteData, setWebsiteData] = useState();
  const [popularProperties, setPopularProperties] = useState();
  const [recentProperties, setRecentProperties] = useState();

  const navigate = useNavigate();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    setKeyword(transcript);
  }, [transcript]);

  useEffect(() => {
    axios
      .get(`api/users/get-home-property-list?recent_property=Yes`)
      .then((resp) => {
        setRecentProperties(resp.data.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-home-property-list?populer_property=Yes`)
      .then((resp) => {
        setPopularProperties(resp.data.result?.propertyList);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`api/users/get-type-list`, {
        headers: {
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzAzMDUyOTAxLCJleHAiOjE3MTA4Mjg5MDF9.CuxMVpJvCE1asp0153mTq7HGX7PXQeXSSBA6oJhrh20",
        },
      })
      .then((resp) => {
        setTypeList(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`api/users/get-settings`)
      .then((resp) => {
        setWebsiteData(resp.data.result.settingData);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`api/users/get-home-builder-list`)
      .then((resp) => {
        setBuilderData(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`api/users/get-home-ameties-list`)
      .then((resp) => {
        amenitiesList.current = [];
        for (var i = 0; i < resp.data.result.length; i++) {
          amenitiesList.current.push(resp.data.result[i].name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!browserSupportsSpeechRecognition) {
    alert("Voice Recognition not supported on this browser");
  }
  function submitSearch() {
    navigate(
      `/property-listing?keyword=${keyword}&typeArr=${propertyType}&category=${propertyStatus}&min_beds=${bedroom}&min_bath=${bathroom}&min_area=${size}&price=${price}&ameties=${amenities}`
    );
  }
  function handleSpeech() {
    SpeechRecognition.startListening();
  }
  function handleToggle(e) {
    const { value, checked } = e.target;
    if (checked) {
      setAmenities((pre) => [...pre, value]);
    } else {
      setAmenities((pre) => {
        return [...pre.filter((skill) => skill !== value)];
      });
    }
  }
  return (
    <>
      <body className="maxw1600 m0a homepage-2 the-search hd-white">
        <div id="wrapper">
          <Header />
          <div className="clearfix"></div>

          <section
            className="home17 overlay home-three bg-img3"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner}) no-repeat center top`,
              maxHeight: "600px",
            }}
          >
            <div className="hero-main">
              <div className="container">
                <FadeIn transitionDuration={3000}>
                  <div className="row">
                    <div className="col-12">
                      <div className="banner-inner">
                        <div
                          className="banner-text"
                          style={{ fontSize: "4rem", color: "white" }}
                        >
                          Find Your <br /> Dream&nbsp;
                          <TypeAnimation
                            sequence={[
                              `Home`,
                              2000,
                              "Apartment",
                              2000,
                              "House",
                              2000,
                              "Villa",
                              2000,
                            ]}
                            wrapper="span"
                            speed={50}
                            style={{
                              whiteSpace: "pre-line",
                              display: "inline-block",
                              color: "black",
                              marginBottom: "40px",
                              fontWeight: "600",
                              fontFamily: "Montserrat",
                            }}
                            repeat={Infinity}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="banner-search-wrap">
                        <ul className="nav nav-tabs rld-banner-tab">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#tabs_1"
                              onClick={() => setPropertyStatus("Sale")}
                            >
                              For Sale
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#tabs_2"
                              onClick={() => setPropertyStatus("Rent")}
                            >
                              For Buy
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="tabs_1"
                          >
                            <div className="rld-main-search">
                              <img
                                src={mic}
                                style={{
                                  width: "40px",
                                  position: "absolute",
                                  right: "50px",
                                  bottom: "7px",
                                  cursor: "pointer",
                                }}
                                onClick={handleSpeech}
                              />
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Property Name or Location..."
                                    style={{ height: "100%" }}
                                    value={
                                      keyword !== "undefined" &&
                                      keyword !== null &&
                                      keyword !== undefined &&
                                      keyword !== ""
                                        ? keyword
                                        : transcript
                                    }
                                    onChange={(e) => setKeyword(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <select
                                    className="form-control"
                                    value={propertyType}
                                    onChange={(e) =>
                                      setPropertyType(e.target.value)
                                    }
                                  >
                                    <option selected disabled>
                                      Property Type
                                    </option>
                                    {typeList?.map((data) => {
                                      return (
                                        <option value={data.name}>
                                          {data.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <button
                                    type="button"
                                    className="btn btn-yellow"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                  >
                                    Advanced Search
                                  </button>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <a
                                    className="btn btn-primary hover-button-effects"
                                    href="#"
                                    style={{
                                      height: "100%",
                                      border: "none",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={submitSearch}
                                  >
                                    Search Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="tabs_2">
                            <div className="rld-main-search">
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Property Name or Location..."
                                    style={{ height: "100%" }}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <select
                                    className="form-control"
                                    value={propertyType}
                                    onChange={(e) =>
                                      setPropertyType(e.target.value)
                                    }
                                  >
                                    <option value="1">Property Type</option>
                                    {typeList?.map((data) => {
                                      return (
                                        <option value={data.name}>
                                          {data.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <button
                                    type="button"
                                    className="btn btn-yellow"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                  >
                                    Advanced Search
                                  </button>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <a
                                    className="btn btn-primary"
                                    href="#"
                                    style={{ height: "100%", border: "none" }}
                                    onClick={submitSearch}
                                  >
                                    Search Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeIn>
              </div>
            </div>
          </section>

          <section style={{ background: "#F5F7FB" }}>
            <div className="container">
              <h2></h2>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </section>

          <section
            className="featured portfolio home18"
            style={{ background: "url(" + homeBackground + ") 0 0 repeat" }}
          >
            <div className="container-fluid">
              <div
                className="flex-for-desktop d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="section-title ml-3">
                  <h3>Recent</h3>
                  <h2>Properties</h2>
                </div>
                <Link to="/property-listing?recent_property">
                  <button className="btn btn-primary hover-button-effects">
                    View All
                  </button>
                </Link>
              </div>
              <ScrollAnimation
                animateIn="fadeIn"
                transitionDuration={1000}
                animateOnce
              >
                <Carousel responsive={responsive}>
                  {recentProperties ? (
                    recentProperties?.map((data) => {
                      return <PropertyGrid data={data} />;
                    })
                  ) : (
                    <div></div>
                  )}
                </Carousel>
              </ScrollAnimation>
            </div>
          </section>
          <AboutUs />
          <Link to="/property-listing?id=3">
            <div className="position-relative mt-5">
              <img
                className="banner-one"
                src={banner1}
                style={{ width: "100%", maxHeight: "200px" }}
              />
              <div className="container">
                <div className="ad-texts">
                  <h3 style={{ color: "black", fontWeight: "700" }}>DAMAC</h3>
                  <h3 style={{ color: "black" }}>
                    The premier luxury property developer in Dubai
                  </h3>
                  <button
                    className="btn btn-primary mt-4"
                    style={{ border: "none" }}
                  >
                    View Properties
                  </button>
                </div>
              </div>
            </div>
          </Link>

          <section className="popular-places home18">
            <div className="container-fluid">
              <div className="section-title">
                <h3>Popular</h3>
                <h2>Builders</h2>
              </div>
              <ScrollAnimation
                animateIn="fadeIn"
                transitionDuration={1000}
                animateOnce
              >
                <div className="row">
                  {builderData?.map((data, key) => {
                    return (
                      <div
                        className="col-sm-6 col-md-6 col-lg-3 col-xl"
                        data-aos-delay="150"
                      >
                        <Link to={"/property-listing?id=" + data.id}>
                          <a href="#" className="img-box hover-effect">
                            <img
                              src={
                                "https://api.divinebricks.com/" + data?.image
                              }
                              className="img-fluid w100"
                              alt=""
                            />
                            <div className="listing-badges"></div>
                            <div className="img-box-content visible">
                              <h4>{data?.name}</h4>
                              <span>
                                {data?.propertyData?.length} Properties
                              </span>
                            </div>
                          </a>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </ScrollAnimation>
            </div>
          </section>

          <section className="my-5">
            <div className="section-title ml-3 ">
              <h3>Our</h3>
              <h2>Gallery</h2>
            </div>
            <div className="container">
              <div className="row g-1 divine-img-gallery">
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery1}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery2}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery3}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery4}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery5}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery6}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery7}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <img
                    src={gallery8}
                    data-toggle="modal"
                    data-target="#exampleModal10"
                  />
                </div>
              </div>
            </div>
          </section>

          <section
            className="featured portfolio home18"
            style={{ background: "url(" + homeBackground + ") 0 0 repeat" }}
          >
            <div className="container-fluid">
              <div
                className="flex-for-desktop d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="section-title ml-3">
                  <h3>Popular</h3>
                  <h2>Properties</h2>
                </div>
                <Link to="/property-listing?populer_property">
                  <button className="btn btn-primary hover-button-effects">
                    View All
                  </button>
                </Link>
              </div>
              <ScrollAnimation
                animateIn="fadeIn"
                transitionDuration={1000}
                animateOnce
              >
                <Carousel responsive={responsive}>
                  {popularProperties ? (
                    popularProperties?.map((data) => {
                      return <PropertyGrid data={data} />;
                    })
                  ) : (
                    <div></div>
                  )}
                </Carousel>
              </ScrollAnimation>
            </div>
          </section>
          <Link to="/property-listing?id=1">
            <div className="position-relative mb-5">
              <img
                className="banner-one"
                src={banner4}
                style={{ width: "100%", maxHeight: "200px" }}
              />
              <div className="container">
                <div className="ad-texts-two">
                  <h3 style={{ color: "black", fontWeight: "700" }}>SOBHA</h3>
                  <h3 style={{ color: "black" }}>
                    The premier luxury property developer in Dubai
                  </h3>
                  <button
                    className="btn btn-primary mt-4"
                    style={{ border: "none" }}
                  >
                    View Properties
                  </button>
                </div>
              </div>
            </div>
          </Link>
          <section className="info-help h18">
            <div className="container">
              <div className="row info-head">
                <div className="col-lg-12 col-md-8 col-xs-8">
                  <div className="info-text">
                    <h3 className="text-center mb-0">Why Choose Us</h3>
                    <p className="text-center mb-4 p-0">
                      We offer perfect real estate services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ScrollAnimation
            animateIn="fadeIn"
            transitionDuration={2000}
            animateOnce
          >
            <section
              _ngcontent-bgi-c3=""
              className="featured-boxes-area bg-white-1"
            >
              <div _ngcontent-bgi-c3="" className="container-fluid">
                <div _ngcontent-bgi-c3="" className="featured-boxes-inner">
                  <div _ngcontent-bgi-c3="" className="row m-0">
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="250"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon color-fb7756">
                          <img src={img5} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          Find Your Home
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Our Mission: Your Perfect Home</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          Explore a wide range of properties, from cozy
                          apartments to spacious family homes. Our extensive
                          listings cover diverse neighborhoods, ensuring you
                          have a variety of options to choose from.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="350"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon color-facd60">
                          <img src={img6} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          Trusted by thousands
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Your Trust, Our Commitment</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          Your trust is the foundation of our success. At Divine
                          Bricks, we are dedicated to maintaining the highest
                          standards of professionalism and reliability.
                          Experience the difference of working with a team you
                          can trust.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="450"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon color-1ac0c6">
                          <img src={img7} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          Financing made easy
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Financing Made Easy with Divine Bricks</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          Buying a home is a significant investment, and at
                          Divine Bricks, we understand that navigating the
                          financial aspect can be a crucial part of your
                          journey. That's why we've made financing your dream
                          home easy and stress-free.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-bgi-c3=""
                      className="col-lg-3 col-sm-6 col-md-6 p-0"
                      data-aos-delay="550"
                    >
                      <div _ngcontent-bgi-c3="" className="single-featured-box">
                        <div _ngcontent-bgi-c3="" className="icon">
                          <img src={img8} width="85" height="85" alt="" />
                        </div>
                        <h3 _ngcontent-bgi-c3="" className="mt-5">
                          24/7 support
                        </h3>
                        <p _ngcontent-bgi-c3="" style={{ fontWeight: "500" }}>
                          <i>Your Convenience is Our Priority</i>
                        </p>
                        <p _ngcontent-bgi-c3="">
                          At Divine Bricks, we understand that your schedule and
                          needs are unique. Our commitment to 24/7 support
                          reflects our dedication to ensuring that your
                          home-finding journey is not only successful but also
                          convenient and stress-free.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollAnimation>
          <section
            className="team"
            style={{ background: "url(" + homeBackground + ") 0 0 repeat" }}
          >
            <div className="container-fluid">
              <div
                className="section-title col-md-5"
                style={{ marginBottom: "0px" }}
              >
                <h3>Meet Our</h3>
                <h2>Team</h2>
              </div>
              <ScrollAnimation
                animateIn="fadeIn"
                transitionDuration={2000}
                animateOnce
              >
                <div
                  className="row team-all"
                  style={{
                    marginTop: "40px",
                    marginBottom: "0px",
                    justifyContent: "space-around",
                  }}
                >
                  {/* <div className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3" data-aos-delay="150">
                                        <div className="inner-box team-details">
                                            <div className="image team-head">
                                                <a href="#"><img src={devender} style={{maxHeight: '320px'}} alt="" /></a>
                                                <div className="team-hover">
                                                </div>
                                            </div>
                                            <div className="lower-box">
                                                <h3><a href="#">Devender Roy</a></h3>
                                                <div className="designation">Real Estate Agent</div>
                                            </div>
                                        </div>
                                    </div> */}
                  <div
                    className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3"
                    data-aos-delay="250"
                  >
                    <div className="inner-box team-details">
                      <div className="image team-head">
                        <a href="#">
                          <img
                            src={naveen}
                            style={{ maxHeight: "320px" }}
                            alt=""
                          />
                        </a>
                        <div className="team-hover"></div>
                      </div>
                      <div className="lower-box">
                        <h3>
                          <a href="#">Naveen Sood</a>
                        </h3>
                        <div className="designation">Relationship Manager</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3"
                    data-aos-delay="350"
                  >
                    <div className="inner-box team-details">
                      <div className="image team-head">
                        <a href="#">
                          <img
                            src={chander}
                            style={{ maxHeight: "320px", height: "320px" }}
                            alt=""
                          />
                        </a>
                        <div className="team-hover"></div>
                      </div>
                      <div className="lower-box">
                        <h3>
                          <a href="#">Chander Garg</a>
                        </h3>
                        <div className="designation">Relationship Manager</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="team-block col-sm-6 col-md-3 col-lg-3 col-xl-3 pb-none"
                    data-aos-delay="450"
                  >
                    <div className="inner-box team-details">
                      <div className="image team-head">
                        <a href="#">
                          <img
                            src={nitin}
                            style={{ maxHeight: "320px" }}
                            alt=""
                          />
                        </a>
                        <div className="team-hover"></div>
                      </div>
                      <div className="lower-box">
                        <h3>
                          <a href="#">Nitin Garg</a>
                        </h3>
                        <div className="designation">Relationship Manager</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </section>

          <Blogs />
          <Footer />
          <a data-scroll href="#wrapper" className="go-up">
            <i className="fa fa-angle-double-up" aria-hidden="true"></i>
          </a>
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="explore__form-checkbox-list full-filter">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 py-1 pr-30 ">
                        <div className="form-group beds">
                          <select
                            className="form-control"
                            value={bedroom}
                            onChange={(e) => setBedroom(e.target.value)}
                          >
                            <option selected disabled>
                              Select Bedrooms
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 py-1 pr-30 pl-0">
                        <div className="form-group bath">
                          <select
                            className="form-control"
                            value={bathroom}
                            onChange={(e) => setBathroom(e.target.value)}
                          >
                            <option selected disabled>
                              Select Bathroom
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12 col-sm-12 py-1 pr-30 mr-5 sld d-xl-flex">
                        <div className="main-search-field-2 w-100">
                          <div className="range-slider">
                            <label>Area Size</label>
                            <ReactSlider
                              className="horizontal-slider"
                              thumbClassName="example-thumb"
                              max={5000}
                              trackClassName="example-track"
                              onBeforeChange={(value, index) =>
                                console.log(
                                  `onBeforeChange: ${JSON.stringify({
                                    value,
                                    index,
                                  })}`
                                )
                              }
                              onChange={(value, index) =>
                                console.log(
                                  `onChange: ${JSON.stringify({
                                    value,
                                    index,
                                  })}`
                                )
                              }
                              onAfterChange={(value, index) => setSize(value)}
                              renderThumb={(props, state) => (
                                <div {...props}>{state.valueNow}</div>
                              )}
                            />

                            <div
                              className="d-flex mt-4"
                              style={{ justifyContent: "space-between" }}
                            >
                              <p>Min 0 Sq. Ft</p> <p>-</p>{" "}
                              <p>Max 5000 Sq. Ft</p>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          <br />
                          <div className="range-slider">
                            <label>Price Range</label>
                            <ReactSlider
                              className="horizontal-slider"
                              thumbClassName="example-thumb"
                              max={100000000}
                              min={950000}
                              trackClassName="example-track"
                              onBeforeChange={(value, index) =>
                                console.log(
                                  `onBeforeChange: ${JSON.stringify({
                                    value,
                                    index,
                                  })}`
                                )
                              }
                              onChange={(value, index) =>
                                console.log(
                                  `onChange: ${JSON.stringify({
                                    value,
                                    index,
                                  })}`
                                )
                              }
                              onAfterChange={(value, index) => setPrice(value)}
                              renderThumb={(props, state) => (
                                <div {...props}>{state.valueNow}</div>
                              )}
                            />
                            <div
                              className="d-flex mt-4"
                              style={{ justifyContent: "space-between" }}
                            >
                              <p>Min aed 950000</p> <p>-</p>{" "}
                              <p>Max aed 100000000</p>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 py-1 pr-30 d-xl-flex">
                        <div
                          className="checkboxes one-in-row margin-bottom-10 ch-2"
                          style={{ columns: "3" }}
                        >
                          {amenitiesList.current?.map((data) => {
                            return (
                              <>
                                <div>
                                  <input
                                    id={data}
                                    type="checkbox"
                                    name="check"
                                    value={data}
                                    onChange={handleToggle}
                                  />
                                  <label for={data}>{data}</label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={submitSearch}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade gallery-modal"
            id="exampleModal10"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide my-gallery-slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src={gallery1} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery2} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery3} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery4} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery5} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery6} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery7} alt="" style={{ width: "100%" }} />
                      </div>
                      <div className="carousel-item">
                        <img src={gallery8} alt="" style={{ width: "100%" }} />
                      </div>
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};
export default Home;

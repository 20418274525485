import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import axios from "../axiosInstance";
import UserHeader from "./UserHeader";
import { toast } from "react-toastify";
import $ from "jquery";
import { ColorRing } from "react-loader-spinner";
import EditIcon from "@mui/icons-material/Edit";

function Capitalise(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const TypeManager = () => {
  const columns = [
    {
      name: "Category ID",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.is_status == "inactive"
              ? "badge badge-danger p-2"
              : "badge badge-success p-2"
          }
        >
          {Capitalise(row.is_status)}
        </div>
      ),
      width: "auto",
    },

    {
      name: "Action",
      selector: (row) => (
        <>
          <button
            data-toggle="modal"
            data-target="#exampleModal1"
            onClick={() => {
              setCurrentRow(row);
              setUpdateName("");
              setStatus("");
            }}
            style={{ border: "none", background: "transparent" }}
          >
            <EditIcon />
          </button>
          <div
            class="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Edit Type
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="container my-3">
                    <div className="row">
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Name</label>
                        <input
                          className="form-control"
                          placeholder="Name"
                          type="text"
                          value={
                            updateName != "" ? updateName : currentRow.name
                          }
                          onChange={(e) => setUpdateName(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Status</label>
                        <select
                          className="form-control"
                          value={status != "" ? status : currentRow.is_status}
                          onChange={handleStatus}
                        >
                          <option selected disabled>
                            Select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => editType(row)}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      width: "auto",
    },
  ];

  const [data, setData] = useState();
  const [name, setName] = useState();
  const [updateName, setUpdateName] = useState();
  const [typeId, setTypeId] = useState();
  const [status, setStatus] = useState();
  const [currentRow, setCurrentRow] = useState();
  const [visible, setVisible] = useState(false);
  const token = sessionStorage.getItem("token");

  function handleStatus(e) {
    setStatus(e.target.value);
  }

  const addType = () => {
    setVisible(true);
    axios
      .post(
        `api/admins/add-type`,
        {
          name: name,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editType = (row) => {
    setVisible(true);
    axios
      .post(
        `api/admins/update-type`,
        {
          name: updateName,
          typeId: currentRow.id.toString(),
          is_status: status,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        setVisible(false);
        if (resp.data.success) {
          toast(resp.data.msg, { toastId: "succ4" });
          $("[data-dismiss=modal]").trigger({ type: "click" });
          fetchData();
        } else {
          toast.error(resp.data.msg, { toastId: "er4" });
        }
        // setData(resp.data.result.docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function fetchData() {
    const token = sessionStorage.getItem("token");
    axios
      .get(`api/admins/get-admin-type-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <UserHeader />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div
              className="card-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Category List
              </h4>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Add Category
              </button>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                />
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Add Type
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="container my-3">
                    <div className="row">
                      <div
                        className="col-md-12 mt-3"
                        style={{ textAlign: "left" }}
                      >
                        <label>Name</label>
                        <input
                          className="form-control"
                          placeholder="Name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={addType}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TypeManager;

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import UserHeader from "./UserHeader";
import axios from "../axiosInstance";
import EditIcon from "@mui/icons-material/Edit";
import $ from "jquery";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AgentsList = () => {
  const columns = [
    {
      name: <div style={{ fontWeight: "800" }}>User Name</div>,
      selector: (row) => row.name,
      width: "140px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Email</div>,
      selector: (row) => row.email,
      width: "200px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Mobile No.</div>,
      selector: (row) => row.mobile_no,
    },
    {
      name: <div style={{ fontWeight: "800" }}>Email Verified</div>,
      selector: (row) => (
        <div
          className={
            row.is_email_verify == false
              ? "badge badge-danger p-2"
              : "badge badge-success p-2"
          }
        >
          {row.is_email_verify == true ? "Verified" : "Pending"}
        </div>
      ),
      width: "100px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>KYC Status</div>,
      selector: (row) => (
        <div
          className={
            row.kyc_status == "pending"
              ? "badge badge-danger p-2"
              : "badge badge-success p-2"
          }
        >
          {Capitalise(row.kyc_status)}
        </div>
      ),
    },
    {
      name: <div style={{ fontWeight: "800" }}>KYC Detail</div>,
      selector: (row) => row.attachmentNo,
      width: "160px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>KYC Image</div>,
      selector: (row) => (
        <div>
          <a
            href={"https://api.divinebricks.com" + row.attachment}
            download="custom.jpg"
            target="_blank"
          >
            <img
              src={"https://api.divinebricks.com" + row.attachment}
              style={{ maxWidth: "200px", maxHeight: "100px" }}
            />
          </a>
        </div>
      ),
    },
    {
      name: <div style={{ fontWeight: "800" }}>Date</div>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      width: "120px",
    },
    {
      name: <div style={{ fontWeight: "800" }}>Status</div>,
      selector: (row) => (
        <div
          className={
            row.is_status == "inactive"
              ? "badge badge-danger p-2"
              : "badge badge-success p-2"
          }
        >
          {Capitalise(row.is_status)}
        </div>
      ),
    },
    {
      name: <div style={{ fontWeight: "800" }}>Action</div>,
      selector: (row) => (
        <div>
          <EditIcon
            style={{ width: "0.8em", cursor: "pointer" }}
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => {
              setAgentId(row.id);
              getUserInfo(row);
            }}
          />
        </div>
      ),
    },
  ];
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="container my-3">
        <div className="row">
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Wallet Amount</label>
            <input
              type="text"
              value={data.walletAmt}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Hold Amount</label>
            <input
              type="text"
              value={data.holdAmt}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Available Amount</label>
            <input
              type="text"
              value={data.availAmt}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Withdraw Amount</label>
            <input
              type="text"
              value={data.withAmt}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Address</label>
            <input
              type="text"
              value={data.address}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>City</label>
            <input
              type="text"
              value={data.city}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>District</label>
            <input
              type="text"
              value={data.district}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>State</label>
            <input
              type="text"
              value={data.state}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Country</label>
            <input
              type="text"
              value={data.country}
              className="form-control mb-3"
              readOnly
            />
          </div>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <label>Pincode</label>
            <input
              type="text"
              value={data.pincode}
              className="form-control mb-3"
              readOnly
            />
          </div>
        </div>
      </div>
    );
  };
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [agentId, setAgentId] = useState();
  const [name, setName] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [address, setAddress] = useState();
  const city = useRef();
  const [district, setDistrict] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [pincode, setPincode] = useState();
  const [isStatus, setIsStatus] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [userData, setUserData] = useState();

  const fetchData = () => {
    const token = sessionStorage.getItem("token");
    axios
      .get(`api/admins/get-agent-list`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setData(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function getUserInfo(row) {
    const token = sessionStorage.getItem("token");
    axios
      .get(`api/admins/get-user-info?userId=${row.id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((resp) => {
        setUserData(resp.data.result);
        setName(row.name);
        setMobileNo(row.mobile_no);
        setAddress(row.address);
        city.current = row.city;
        setDistrict(row.district);
        setState(row.state);
        setCountry(row.country);
        setPincode(row.pincode);
        setIsStatus(row.is_status);
        setKycStatus(row.kyc_status);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const updateProfile = () => {
    setVisible(true);
    const token = sessionStorage.getItem("token");
    axios
      .post(
        `api/admins/update-agent-profile`,
        {
          agentId: agentId.toString(),
          name: name,
          mobile_no: mobileNo,
          address: address,
          city: city.current,
          district: district,
          state: state,
          country: country,
          pincode: pincode,
          is_status: isStatus,
          kyc_status: kycStatus,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((resp) => {
        if (resp.data.success) {
          toast.success(resp.data.msg, { toastId: "su12" });
          fetchData();
          setName("");
          setMobileNo("");
          setAddress("");
          city.current = "";
          setDistrict("");
          setState("");
          setCountry("");
          setPincode("");
          setIsStatus("");
          setKycStatus("");
          $("[data-dismiss=modal]").trigger({ type: "click" });
        }
        if (!resp.data.success) {
          toast.error(resp.data.msg, { toastId: "er12" });
        }
        setVisible(false);
        setData(resp.data.result.rows);
      })
      .catch((err) => {
        console.log(err);
        setVisible(false);
      });
  };

  function Capitalise(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
  return (
    <>
      <UserHeader />
      <ColorRing
        visible={visible}
        ariaLabel="loading"
        wrapperClass="loader-spinner"
      />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ textAlign: "left" }}>
                Agents Report
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive export-button-table">
                <DataTable
                  pagination={true}
                  columns={columns}
                  data={data}
                  paginationServer={true}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Agent Profile
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body add-property-modal">
              <div className="row admin-property-add">
                <div className="col-md-4 mb-4">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>Mobile No</label>
                  <input
                    type="text"
                    className="form-control"
                    value={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    value={city.current}
                    onChange={(e) => (city.current = e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>Ditsrict</label>
                  <input
                    type="text"
                    className="form-control"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>Pincode</label>
                  <input
                    type="text"
                    className="form-control"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <label>Status</label>
                  <select
                    className="form-control"
                    value={isStatus}
                    onChange={(e) => setIsStatus(e.target.value)}
                  >
                    <option selected disabled>
                      Select Status
                    </option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="col-md-4 mb-4">
                  <label>Kyc Status</label>
                  <select
                    className="form-control"
                    value={kycStatus}
                    onChange={(e) => setKycStatus(e.target.value)}
                  >
                    <option selected disabled>
                      Select Status
                    </option>
                    <option value="pending">Pending</option>
                    <option value="approve">Approve</option>
                    <option value="reject">Reject</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateProfile}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AgentsList;

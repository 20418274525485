import React, { useEffect, useState } from 'react'
import axios from '../axiosInstance'
import "react-toastify/dist/ReactToastify.css";
import UserHeader from './UserHeader';
import DataTable from "react-data-table-component";
import moment from 'moment';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";


const columns = [
    {
        name: "Property ID",
        selector: (row) => row.propertyId !== null? row.propertyId : 'null',
    },
    {
        name: "Credit",
        selector: (row) => "$" + row.credit,
    },
    {
        name: "Debit",
        selector: (row) => "$" + row.debit,
    },
    {
        name: "Date",
        selector: (row) => moment(row.createdAt).format("DD-MM-YYYY  HH:mm:ss"),
    },
    {
        name: "Remarks",
        selector: (row) => (row.remark == null ? null : row.remark),
        width: 'auto'
    },
];

const Profile = () => {
    const [balance, setBalance] = useState()
    const [data, setData] = useState()
    const [address, setAddress] = useState()
    const [emiCycle, setEmiCycle] = useState()
    const [visible, setVisible] = useState(false)

    const [name, setName] = useState()
    const [password, setPassword] = useState()
    const [mobile_no, setMobileNo] = useState()
    const [city, setCity] = useState()
    const [district, setDistrict] = useState()
    const [state, setState] = useState()
    const [country, setCountry] = useState()
    const [pincode, setPincode] = useState()
    const [attachment, setAttachment] = useState()
    const [seeImg2, setSeeImg2] = useState()

    useEffect(() => {
        const token = sessionStorage.getItem("token")
        axios
            .get(
                `api/users/get-user-details`,
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setData(resp.data.result)
                setEmiCycle(resp.data.result?.emi_date)
                setName(resp.data.result?.name)
                setPassword(resp.data.result?.password)
                setMobileNo(resp.data.result?.mobile_no)
                setCity(resp.data.result?.city)
                setAddress(resp.data.result?.address)
                setDistrict(resp.data.result?.district)
                setState(resp.data.result?.state)
                setCountry(resp.data.result?.country)
                setPincode(resp.data.result?.pincode)
                setAttachment(resp.data.result?.attachment)
                setSeeImg2(resp.data.result?.attachment == null? 'hidden' : 'visible')
            })
            .catch((err) => {
                console.log(err)
            });
        axios
            .post(
                `api/users/reports`,
                {
                    "type": "3"
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setBalance(resp.data.result?.rows)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])

    const imageUpload2 = async (event) => {
        setSeeImg2('visible')
        var output1 = document.getElementById('output1');
        output1.src = URL.createObjectURL(event.target.files[0]);
        output1.onload = function () {
            URL.revokeObjectURL(output1.src) // free memory
        }
        await uploadFile2(event.target.files[0])
    }


    function uploadFile2(file) {
        setVisible(true)
        const formData = new FormData();
        formData.append('files', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(`api/users/upload-a-document`,
            formData,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        ).
            then((resp) => {
                setVisible(false)
                setAttachment(resp.data.result.image_url)
            }).catch((err) => { console.log('eerr', err) });
    }

    const handleChange = address => {
        setAddress(address);
    };

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };
    const token = sessionStorage.getItem("token")

    const update = (e) => {
        e.preventDefault()
        setVisible(true)
        axios
            .post(
                `api/users/update-profile`,
                {
                    name: name,
                    password: password,
                    mobile_no: mobile_no,
                    address: address,
                    city: city,
                    district: district,
                    state: state,
                    country: country,
                    pincode: pincode,
                    attachment: attachment,
                },
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            )
            .then((resp) => {
                setVisible(false)
                if (!resp.data.success) {
                    toast.error(resp.data.msg, { toastId: 'e1' })
                }
                if (resp.data.success) {
                    toast(resp.data.msg, { toastId: 's1' })
                    window.location.reload()
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }


    return (
        <>
            <ColorRing
                visible={visible}
                ariaLabel="loading"
                wrapperClass="loader-spinner"
            />
            <UserHeader />
            <div className="container">

                <div className='row mt-5 mb-5'>

                    <div className="col-md-12 mx-auto">
                        <div className="d-flex flex-column container">

                            <p className='mb-1' style={{ fontSize: '24px', color: '#1e5239' }}>User Profile</p>

                            <div className='row'>
                                <div className='col-md-4 text-left'>
                                    <label>Name</label>
                                    <input className='form-control mb-4' placeholder='Username' type='text' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>Password</label>
                                    <input className='form-control mb-4' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>Email</label>
                                    <input className='form-control mb-4' placeholder='Email' type='text' value={data?.email} readOnly />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>Mobile Number</label>
                                    <input className='form-control mb-4' type='text' value={mobile_no == null ? 'null' : mobile_no} onChange={(e) => setMobileNo(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>My Wallet Balance</label>
                                    <input className='form-control mb-4' type='text' value={'$ ' + data?.walletAmt} readOnly />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>My Available Balance</label>
                                    <input className='form-control mb-4' type='text' value={'$ ' + data?.availAmt} readOnly />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>My Hold Balance</label>
                                    <input className='form-control mb-4' type='text' value={'$ ' + data?.holdAmt} readOnly />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>My Withdraw Balance</label>
                                    <input className='form-control mb-4' type='text' value={'$ ' + data?.withAmt} readOnly />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>Emi Cycle</label>
                                    <input className='form-control mb-4' type='date' onChange={(e) => setEmiCycle(e.target.value)} value={emiCycle} />
                                </div>
                            </div>
                            <button className='btn btn-primary' style={{ textAlign: 'left', marginBottom: '20px', width: 'max-content', color: 'white', borderRadius: '4px' }}>Click for Autofill Address</button>
                            <div className='row'>
                                <div className='col-md-4 text-left'>
                                    <label>Address</label>
                                    <input className='form-control mb-4' type='text' value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>District</label>
                                    <input className='form-control mb-4' type='text' value={district} onChange={(e) => setDistrict(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>City</label>
                                    <input className='form-control mb-4' type='text' value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>State</label>
                                    <input className='form-control mb-4' type='text' value={state} onChange={(e) => setState(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>Country</label>
                                    <input className='form-control mb-4' type='text' value={country} onChange={(e) => setCountry(e.target.value)} />
                                </div>
                                <div className='col-md-4 text-left'>
                                    <label>Pincode</label>
                                    <input className='form-control mb-4' type='text' value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-left'>
                                    <label>Attach Passport</label>
                                    <input className='form-control' type="file" id="myfile" name="myfile" required onChange={imageUpload2} />
                                    <img id="output1" src={'https://api.divinebricks.com'+attachment} alt="your image" style={{ height: '100px', width: '100px', marginTop: '10px', objectFit: 'cover', visibility: seeImg2 }} />
                                    {/* <input className='form-control mb-4' type='file'/> */}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-left'>
                                    <label>Refferal Code</label>
                                    <input className='form-control mb-4' placeholder='Referral Code' type='text' value={"https://divinebricks.ae/register?referralcode=" + data?.referralCode} readOnly />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-left'>
                                    <button className='btn btn-danger' onClick={update}>Update Profile</button>
                                </div>
                            </div>
                        </div>
                        {/* <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete> */}
                    </div>

                </div>
            </div>
            <div className="row pt-0">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title" style={{ textAlign: 'left' }}>Wallet History</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive export-button-table">
                                <DataTable
                                    pagination={true}
                                    columns={columns}
                                    data={balance}
                                    paginationServer={true}
                                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile